import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './GspDedup.css'
import { JobCompany } from '../JobCompany/JobCompany'
import { CompanyMatch } from '../../../interface/CompanyMatch'
import CompanyMatchService from '../../../services/CompanyMatch/CompanyMatch'
import Spinner from '../../UI/Spinner/Spinner'
import BootstrapAlert from '../../UI/BootstrapAlert/BootstrapAlert'
import Jobs from '../../../services/Jobs/Jobs'
import useApi from '../../../hooks/useApi'
import useRouting from '../../../hooks/useRouting'
import { Job } from '../../../interface/Job'
import { Pagination } from '../../UI/Pagination/Pagination'
import BackdropSpinner from '../../UI/BackdropSpinner/BackdropSpinner'
import usePagination from '../../../hooks/usePagination'
import Icon from '../../UI/Icon/Icon'
import { TooltipHandle } from '../../UI/TooltipHandle/TooltipHandle'
import { Capability } from '../../../services/Auth/UserInfo'
import { UserContext } from '../../../contexts/UserContext'
import RestartMatchingMenu from '../../UI/RestartMatchingMenu/RestartMatchingMenu'

const NON_EDITABLE_STATES: string[] = [
  'ASSIGNMENT_DONE',
  'ERROR',
  'IMPORTING',
  'EXPIRED',
]

async function finalizeJob(jobId: number) {
  return await new Jobs().finalizeJob(jobId)
}

async function restartImportJob(jobId: number) {
  return await new Jobs().restartJob(jobId)
}

async function resetMatching(jobId: number, matchingConfigId?: number) {
  return await new Jobs().resetJob(jobId, matchingConfigId)
}

const fetchMatches = async (jobId: number, page: number) => {
  const service: CompanyMatchService = new CompanyMatchService()
  return await service.getCompanyMatch(jobId, page)
}

const expireImportJob = async (jobId: number) => {
  const service: Jobs = new Jobs()
  return await service.expireJob(jobId)
}

interface Props {
  job: Job
  updateJob: (job: Job) => void
}

const GspDedupJob: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { can, userName } = useContext(UserContext)
  const { reload, redirectTo } = useRouting()
  const { job, updateJob } = props

  const [jobId, setJobId] = useState(0)
  const [isEditable, setIsEditable] = useState(true)
  const [submitting, updateSubmitting] = useState(false)
  const [finalizeError, setFinalizeError] = useState<string | null>(null)
  const [finalizeSystemError, setFinalizeSystemError] = useState<string | null>(
    null
  )

  const callRestartJobApi = useCallback(() => restartImportJob(jobId), [jobId])
  const {
    isLoading: restartingJob,
    call: restartJob,
    error: restartJobError,
    response: restartJobResponse,
  } = useApi(t, 'ResetImportJobFailed', callRestartJobApi)

  const callRestartMatchingApi = useCallback(
    (matchingConfigId?: number) => resetMatching(jobId, matchingConfigId),
    [jobId]
  )
  const {
    isLoading: restartingMatching,
    call: restartMatching,
    error: restartMatchingError,
    response: restartMatchingResponse,
  } = useApi<unknown, number>(
    t,
    'RestartMatchingFailed',
    callRestartMatchingApi
  )

  const {
    isLoading: expiringJob,
    call: expireJob,
    error: expireError,
    response: expireResponse,
  } = useApi<Job, undefined>(
    t,
    'ExpireJobFailed',
    useCallback(() => expireImportJob(+jobId), [jobId])
  )

  const {
    error: matchesError,
    fetchItems,
    isLoading: matchesLoading,
    items: companyMatches,
    page,
    pagination,
  } = usePagination<CompanyMatch>()

  const handleFinalizeJob = useCallback(async () => {
    updateSubmitting(true)
    const response = await finalizeJob(jobId)
    if (response.success) {
      reload()
    } else {
      const serverMsg = response.message ?? null
      setFinalizeError(serverMsg || t('FinalizeImportJobFailed'))
      setFinalizeSystemError(response.system ?? null)
      updateSubmitting(false)
    }
  }, [jobId, reload, t])

  const handleRestartJob = useCallback(async () => {
    await restartJob()
  }, [restartJob])

  const handleExpireJob = useCallback(async () => {
    await expireJob()
  }, [expireJob])

  useEffect(() => {
    const status = restartJobResponse?.status || null
    if (status && status >= 200 && status < 300) {
      if (job.id) {
        redirectTo('/importjobs/' + encodeURIComponent(job.id))
      } else {
        reload()
      }
    }
  }, [restartJobResponse, job, reload, redirectTo])

  useEffect(() => {
    if (!job) {
      return
    }
    setJobId(job.id)
    setIsEditable((job && !NON_EDITABLE_STATES.includes(job.status)) || false)
  }, [job])

  useEffect(() => {
    if (!(Boolean(job) && Boolean(jobId) && job?.id === jobId)) {
      return
    }
    fetchItems(() => fetchMatches(jobId, page))
  }, [job, jobId, fetchItems, page])

  useEffect(() => {
    const status = restartMatchingResponse?.status || null
    if (status && status >= 200 && status < 300) {
      reload()
    }
  }, [restartMatchingResponse, reload])

  useEffect(() => {
    const status = expireResponse?.status || null
    if (status && status >= 200 && status < 300 && expireResponse?.data) {
      updateJob(expireResponse.data)
    }
  }, [expireResponse, reload, updateJob])

  const canWriteImportjobs = can(Capability.WriteImportjobs)
  const isAssigned = job?.assignee === userName

  const error = restartJobError || restartMatchingError || expireError
  const showError =
    (restartJobError && !restartingJob) ||
    (restartMatchingError && !restartingMatching) ||
    (expireError && !expiringJob)

  const showSpinner =
    restartingJob ||
    restartingMatching ||
    expiringJob ||
    job.status === 'PROCESSING'

  const jobIsEditable =
    job.status !== 'ASSIGNMENT_DONE' && job.status !== 'EXPIRED'

  const showExpireButton =
    isAssigned && canWriteImportjobs && job.status === 'READY'

  const showResetButton =
    isAssigned && canWriteImportjobs && isAssigned && job.external_id

  return (
    <div data-semantic-id="import-job" data-semantic-jobid={jobId}>
      {matchesError && (
        <BootstrapAlert
          type="danger"
          message={matchesError.message}
          status={matchesError.system}
        />
      )}
      {job && (
        <div
          data-semantic-id="import-job-menu"
          className="mb-3 d-flex align-items-center justify-content-end">
          {showSpinner ? (
            <span className="m-auto">
              <Spinner size={35} />
            </span>
          ) : (
            jobIsEditable && (
              <>
                {canWriteImportjobs && isAssigned && (
                  <RestartMatchingMenu onRestart={restartMatching} />
                )}
                {showExpireButton && (
                  <>
                    <button
                      data-semantic-id="import-job-expire"
                      onClick={handleExpireJob}
                      className="btn btn-secondary ml-3">
                      <Icon name="x-circle">{t('ExpireJob')}</Icon>
                    </button>
                    <TooltipHandle
                      data-semantic-id="setexpiredimportjobinfo"
                      className="btn btn-gray-custom"
                      name={'setexpiredimportjobinfo'}
                      label={<Icon name="info-circle" />}>
                      {t('ExpireJobTooltip')}
                    </TooltipHandle>
                  </>
                )}
                {showResetButton && (
                  <>
                    <button
                      data-semantic-id="import-job-reset"
                      onClick={handleRestartJob}
                      className="btn btn-secondary ml-3">
                      <Icon name="bootstrap-reboot">{t('ResetImportJob')}</Icon>
                    </button>
                    <TooltipHandle
                      data-semantic-id="resetimportjobinfo"
                      className="btn btn-gray-custom"
                      name={'resetimportjobinfo'}
                      label={<Icon name="info-circle" />}>
                      {t('RestartJobTooltip')}
                    </TooltipHandle>
                  </>
                )}
              </>
            )
          )}
        </div>
      )}
      {showError && (
        <div data-semantic-id="import-job-button-action-error" className="mb-3">
          <BootstrapAlert
            type="danger"
            message={error?.message}
            status={error?.system}
          />
        </div>
      )}
      <div className="row mb-4">
        <div className="col">
          <div
            data-semantic-id="paginated-data"
            className="card card-custom-table">
            <div className="card-body">
              <BackdropSpinner show={matchesLoading}>
                <div>
                  <table
                    data-semantic-id="jobtable"
                    className="table table-striped-custom table-borderless table-layout-fixed">
                    <colgroup>
                      <col style={{ width: '3rem' }} />
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '50%' }} />
                      <col style={{ width: '6rem' }} />
                      <col style={{ width: '10rem' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">{t('Company')}</th>
                        <th scope="col">{t('Location')}</th>
                        <th scope="col">{t('MatchProbability')}</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyMatches !== null &&
                        companyMatches.length === 0 && (
                          <tr data-semantic-id="empty-page">
                            <td colSpan={5}>
                              <BootstrapAlert
                                className="m-2"
                                type="info"
                                message={t('NoJobsFound')}
                              />
                            </td>
                          </tr>
                        )}
                      {companyMatches?.map(match => (
                        <JobCompany
                          key={match.job_company.id}
                          data={{ ...match }}
                          jobId={jobId}
                          jobOrigin={job.data_origin}
                          editable={isEditable}
                          isAssigned={isAssigned}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </BackdropSpinner>
            </div>
          </div>
        </div>
      </div>
      <Pagination {...pagination} />
      {canWriteImportjobs && isAssigned && isEditable && (
        <button
          data-semantic-id="finalize-job"
          data-semantic-context="importjobs"
          className="btn btn-secondary btn-block mt-4"
          disabled={submitting}
          onClick={handleFinalizeJob}>
          {t('finalize_job')}
        </button>
      )}
      {finalizeError && (
        <BootstrapAlert
          className="mt-4"
          type="danger"
          message={finalizeError}
          status={finalizeSystemError}
        />
      )}
    </div>
  )
}

export default GspDedupJob
