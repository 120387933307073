import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'

import styles from './TooltipHandle.module.scss'

const MARKER = `${String(Math.random()).slice(1).replace(/\D/g, '')}`

interface Props {
  'data-semantic-id'?: string
  'data-semantic-context'?: string
  className?: string
  disabled?: boolean
  name: string
  label: ReactNode
  trigger?: 'focus' | 'hover'
  isButton?: boolean
  children: ReactNode
  align?: 'left' | 'center'
  xShiftTooltip?: number
}

export const TooltipHandle: React.FC<Props> = (props: Props) => {
  const id = `tooltip-${props.name}-${MARKER}`
  const trigger = props.trigger ?? 'focus'
  const isButton = props.isButton ?? true
  const align = props.align ?? 'center'

  const [isOpen, setIsOpen] = useState(false)
  const handleMouseDown = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault()
      ev.stopPropagation()
      // Simulate focus/blur since Firefox on Mac doesn't focus clicked elements
      // https://bugzilla.mozilla.org/show_bug.cgi?id=756028
      if (trigger === 'focus') {
        if (isOpen) {
          ev.currentTarget.blur()
        } else {
          ev.currentTarget.focus()
        }
      }
    },
    [trigger, isOpen]
  )
  const handleClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      if (isButton) {
        ev.preventDefault()
        ev.stopPropagation()
      }
    },
    [isButton]
  )
  const handleFocus = useCallback(() => {
    if (trigger === 'focus') {
      setIsOpen(true)
    }
  }, [trigger])
  const handleBlur = useCallback(() => {
    setIsOpen(false)
  }, [])
  const handleHover = useCallback(() => {
    if (trigger === 'hover') {
      setIsOpen(true)
    }
  }, [trigger])
  const handleLeave = useCallback(() => {
    if (trigger === 'hover') {
      setIsOpen(false)
    }
  }, [trigger])

  const portalRef = useRef<HTMLElement | null>(null)
  const [handleRect, setHandleRect] = useState<DOMRect | null>(null)
  const [tooltipRect, setTooltipRect] = useState<DOMRect | null>(null)
  const [arrowRect, setArrowRect] = useState<DOMRect | null>(null)

  const tooltipHeight = tooltipRect?.height ?? 0
  const tooltipWidth = tooltipRect?.width ?? 0
  const arrowOffsetLeft =
    tooltipWidth / 2 - (arrowRect?.width ?? 0) / 2 - (props?.xShiftTooltip ?? 0)
  const innerOffsetLeft = -tooltipWidth / 2 + (props?.xShiftTooltip ?? 0)
  const left =
    window.scrollX +
    (align === 'center' ? (handleRect?.width ?? 0) / 2 : 0) +
    (align === 'left' ? (arrowRect?.width ?? 0) * 4 : 0) +
    (handleRect?.left ?? 0) +
    innerOffsetLeft
  const top = window.scrollY + ((handleRect?.top ?? 0) - tooltipHeight)

  useEffect(() => {
    if (portalRef.current) {
      return
    }
    portalRef.current = document.querySelector('#tooltip-portal')
  }, [])

  // Note that we always want the current handle position to be up-to-date so we
  // add the `isOpen` as a dependency, even though it's not strictly necessary

  const measureHandleRef = useCallback(
    (node: HTMLButtonElement) => {
      if (node !== null) {
        const rect = node.getBoundingClientRect()
        if (!rect.toJSON) {
          return
        }
        setHandleRect(rect.toJSON())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  )
  const measureTooltipRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        const rect = node.getBoundingClientRect()
        if (rect.toJSON) {
          setTooltipRect(rect.toJSON())
        }
        const arrow = node.querySelector('.arrow')
        if (arrow) {
          const arrowRect = arrow.getBoundingClientRect()
          if (arrowRect.toJSON) {
            setArrowRect(arrowRect.toJSON())
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  )

  const className = props.className
    ? props.className
    : `btn btn-link p-0 m-0 border-0`

  return (
    <>
      {isButton ? (
        <button
          data-semantic-id={props['data-semantic-id']}
          data-semantic-context={props['data-semantic-context']}
          data-testid={props['data-semantic-id']}
          data-toggle="tooltip"
          id={id}
          ref={measureHandleRef}
          className={className}
          onBlur={handleBlur}
          onClick={handleClick}
          onFocus={handleFocus}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
          disabled={props.disabled}
          type="button">
          {props.label}
        </button>
      ) : (
        <span
          data-semantic-id={props['data-semantic-id']}
          data-semantic-context={props['data-semantic-context']}
          data-toggle="tooltip"
          id={id}
          ref={measureHandleRef}
          className={className}
          onBlur={handleBlur}
          onClick={handleClick}
          onFocus={handleFocus}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
          style={{ cursor: 'inherit', fontWeight: 'inherit' }}
          role="button">
          {props.label}
        </span>
      )}
      {portalRef.current &&
        ReactDOM.createPortal(
          <div
            ref={measureTooltipRef}
            className={`tooltip bs-tooltip-top ${
              isOpen
                ? isButton
                  ? styles.open
                  : styles.openDelayed
                : styles.closed
            } ${styles.content}`}
            aria-describedby={id}
            role="tooltip"
            aria-hidden={isOpen ? 'false' : 'true'}
            style={{
              left: `${left}px`,
              top: `${top}px`,
              display: `${isOpen ? '' : 'none'}`,
            }}>
            <div
              className="arrow"
              style={{ marginLeft: `${arrowOffsetLeft}px` }}></div>
            <div className="tooltip-inner">{props.children}</div>
          </div>,
          portalRef.current
        )}
    </>
  )
}
