import ApiService, { CancellationPolicy, RetryPolicy } from '../ApiService'
import { ClusterJob, ClusterJobStatus } from '../../interface/ClusterJob'
import { Cluster } from '../../interface/Cluster'
import { ClusterUpdate } from '../../interface/ClusterUpdate'
import { ClusterCompanyNames } from '../../interface/ClusterCompany'
import { MasterDataCompany } from '../../interface/MasterDataCompany'
import { ResponseObject } from '../../interface/ResponseObject'

export type ClusterJobSorting = 'created_at:ASC' | 'created_at:DESC'

interface ClusterJobsParams {
  status?: ClusterJobStatus[]
  company_name?: string
  city?: string
  assignee?: string
  createdBy?: string
  sort?: ClusterJobSorting
  size?: number
}

export const AddCompanyToClusterStatusTypes = {
  STATUS_COMPANY_IS_LEADING_COMPANY_OF_ANOTHER_CLUSTER:
    'STATUS_COMPANY_IS_LEADING_COMPANY_OF_ANOTHER_CLUSTER',
  STATUS_COMPANY_PART_OF_ANOTHER_CLUSTER:
    'STATUS_COMPANY_PART_OF_ANOTHER_CLUSTER',
  STATUS_COMPANY_PART_OF_FINALIZED_CLUSTER:
    'STATUS_COMPANY_PART_OF_FINALIZED_CLUSTER',
} as const
export type AddCompanyToClusterType =
  (typeof AddCompanyToClusterStatusTypes)[keyof typeof AddCompanyToClusterStatusTypes]

class ClusterJobs extends ApiService {
  getClusterJob(
    jobId: number,
    retryPolicy?: RetryPolicy<ClusterJob>,
    cancellationPolicy?: CancellationPolicy
  ) {
    return this.get<ClusterJob>(
      `/clusterjobs/${jobId}`,
      undefined,
      retryPolicy,
      cancellationPolicy
    )
  }

  getClusterJobs(page = 0, params: ClusterJobsParams = {}) {
    const { status, company_name, city, assignee, createdBy, size, sort } =
      params
    const paramList = [
      page >= 0 ? `page=${encodeURIComponent(page)}` : '',
      status?.length
        ? status.map(status => `status=${encodeURIComponent(status)}`).join('&')
        : '',
      company_name ? `company_name=${encodeURIComponent(company_name)}` : null,
      city ? `city=${encodeURIComponent(city)}` : '',
      assignee ? `assignee=${encodeURIComponent(assignee)}` : '',
      createdBy ? `created_by=${encodeURIComponent(createdBy)}` : '',
      sort ? `sort=${encodeURIComponent(sort)}` : '',
      size ? `size=${encodeURIComponent(size)}` : '',
    ].filter(Boolean)
    const paramFragment = paramList.length ? `?${paramList.join('&')}` : ''
    return this.get<ClusterJob[]>(`/clusterjobs${paramFragment}`)
  }

  createClusterJob(
    company_name: string,
    city: string,
    street: string,
    country: string,
    fields_as_regex: boolean,
    matching_configuration_id?: string
  ) {
    return this.post<ClusterJob>('/clusterjobs', {
      company_name,
      city,
      street,
      country,
      fields_as_regex,
      matching_configuration_id,
    })
  }

  getClusters(jobId: number, page = 0, size = 15, search: string) {
    const params = [
      page >= 0 ? `page=${encodeURIComponent(page)}` : '',
      size >= 0 ? `size=${encodeURIComponent(size)}` : '',
      search ? `search=${encodeURIComponent(search)}` : '',
    ].filter(Boolean)
    const paramFragment = params.length ? `?${params.join('&')}` : ''
    return this.get<Cluster[]>(`/clusterjobs/${jobId}/clusters${paramFragment}`)
  }

  getAdditionalClusters(
    jobId: number,
    clusterId: number,
    regexSearch: boolean
  ) {
    const params = [`asterisk=${encodeURIComponent(regexSearch)}`].filter(
      Boolean
    )
    const paramFragment = params.length ? `?${params.join('&')}` : ''
    return this.post<MasterDataCompany[]>(
      `/clusterjobs/${jobId}/clusters/${clusterId}/additionalsearch${paramFragment}`
    )
  }

  getCluster(jobId: number, clusterId: number) {
    return this.get<Cluster>(`/clusterjobs/${jobId}/clusters/${clusterId}`)
  }

  updateCluster(
    jobId: number,
    clusterId: number,
    clusterUpdate?: ClusterUpdate
  ) {
    return this.patch<Cluster>(
      `/clusterjobs/${jobId}/clusters/${clusterId}`,
      clusterUpdate
    )
  }

  async finalizeJob(jobId: number, includeUnassignedCompanies: boolean) {
    const response = await this.getClusterJob(jobId)
    if (!response.success || !response.data) {
      return response
    }
    const upToDateJob = response.data
    if (!upToDateJob.finalizable) {
      response.success = false
      response.message = 'ClusterJobNotFinalizable'
      delete response.data
      return response
    }
    const params = [
      includeUnassignedCompanies !== undefined &&
      includeUnassignedCompanies !== null
        ? `create_importjob=${encodeURIComponent(includeUnassignedCompanies)}`
        : '',
    ].filter(Boolean)
    const paramFragment = params.length ? `?${params.join('&')}` : ''
    return this.post<ClusterJob>(
      `/clusterjobs/${jobId}/finalize${paramFragment}`
    )
  }

  async finalizeCluster(jobId: number, clusterId: number) {
    const response = await this.getCluster(jobId, clusterId)
    if (!response.success || !response.data) {
      return response
    }
    if (response.data.finalized) {
      response.success = false
      response.message = 'FinalizedCluster'
      delete response.data
      return response
    }

    const jobResponse = await this.getClusterJob(jobId)
    if (!jobResponse.success || !jobResponse.data) {
      return response
    }

    if (
      jobResponse.data.status === 'RESULT_FINALIZED' ||
      jobResponse.data.status === 'EXPIRED'
    ) {
      response.success = false
      response.message = 'FinalizedCluster'
      delete response.data
      return response
    }

    return this.post<Cluster>(
      `/clusterjobs/${jobId}/clusters/${clusterId}/finalize`
    )
  }

  updateJobCompanyAddress(
    jobId: number,
    clusterId: number,
    companyId: string,
    addressId: number,
    address: any
  ) {
    return this.put(
      [
        '/clusterjobs',
        jobId,
        'clusters',
        clusterId,
        'companies',
        companyId,
        'addresses',
        addressId,
      ].join('/'),
      address
    )
  }

  updateJobCompanyNames(
    jobId: number,
    clusterId: number,
    companyId: string,
    companyNames: ClusterCompanyNames
  ) {
    return this.put(
      `/clusterjobs/${jobId}/clusters/${clusterId}/companies/${companyId}`,
      companyNames
    )
  }

  async unsaveCluster(jobId: number, clusterId: number) {
    const clusterResponse = await this.getCluster(jobId, clusterId)
    if (!clusterResponse.success || !clusterResponse.data) {
      return clusterResponse
    }
    if (clusterResponse.data.finalized) {
      clusterResponse.success = false
      clusterResponse.message = 'FinalizedCluster'
      delete clusterResponse.data
      return clusterResponse
    }

    const jobResponse = await this.getClusterJob(jobId)
    if (!jobResponse.success || !jobResponse.data) {
      return clusterResponse
    }

    if (
      jobResponse.data.status === 'RESULT_FINALIZED' ||
      jobResponse.data.status === 'EXPIRED'
    ) {
      clusterResponse.success = false
      clusterResponse.message = 'FinalizedCluster'
      delete clusterResponse.data
      return clusterResponse
    }

    return this.post<Cluster>(
      `/clusterjobs/${jobId}/clusters/${clusterId}/unsave`
    )
  }

  async addCompanyToCluster(
    jobId: number,
    clusterId: number,
    companyId: number,
    allowMove?: boolean
  ): Promise<ResponseObject<Cluster | AddCompanyToClusterType>> {
    const clusterResponse = await this.getCluster(jobId, clusterId)
    if (!clusterResponse.success || !clusterResponse.data) {
      return clusterResponse
    }
    if (clusterResponse.data.finalized) {
      clusterResponse.success = false
      clusterResponse.message = 'FinalizedCluster'
      delete clusterResponse.data
      return clusterResponse
    }

    const jobResponse = await this.getClusterJob(jobId)
    if (!jobResponse.success || !jobResponse.data) {
      return clusterResponse
    }

    if (
      jobResponse.data.status === 'RESULT_FINALIZED' ||
      jobResponse.data.status === 'EXPIRED'
    ) {
      clusterResponse.success = false
      clusterResponse.message = 'FinalizedCluster'
      delete clusterResponse.data
      return clusterResponse
    }

    return this.post<Cluster>(
      `/clusterjobs/${jobId}/clusters/${clusterId}/add`,
      allowMove
        ? {
            company_id: companyId,
            allow_move: allowMove,
          }
        : {
            company_id: companyId,
          }
    )
  }

  async addAssigneeToClusterJob(jobId: number, assignee: string) {
    const jobResponse = await this.getClusterJob(jobId)
    if (!jobResponse.success || !jobResponse.data) {
      return jobResponse
    }
    if (
      jobResponse.data.status === 'RESULT_FINALIZED' ||
      jobResponse.data.status === 'EXPIRED'
    ) {
      jobResponse.success = false
      jobResponse.message = 'FinalizedClusterJob'
      delete jobResponse.data
      return jobResponse
    }
    return this.patch<ClusterJob>(`/clusterjobs/${jobId}`, {
      assignee: assignee,
    })
  }

  async cancelJob(jobId: number) {
    return this.post<ClusterJob>(`/clusterjobs/${jobId}/cancel`)
  }

  async addLocationToLeadingCompany(
    jobId: number,
    clusterId: number,
    locationId: number
  ) {
    const clusterResponse = await this.getCluster(jobId, clusterId)
    if (!clusterResponse.success || !clusterResponse.data) {
      return clusterResponse
    }
    if (clusterResponse.data.finalized) {
      clusterResponse.success = false
      clusterResponse.message = 'FinalizedCluster'
      delete clusterResponse.data
      return clusterResponse
    }

    const jobResponse = await this.getClusterJob(jobId)
    if (!jobResponse.success || !jobResponse.data) {
      return clusterResponse
    }

    if (
      jobResponse.data.status === 'RESULT_FINALIZED' ||
      jobResponse.data.status === 'EXPIRED'
    ) {
      clusterResponse.success = false
      clusterResponse.message = 'FinalizedCluster'
      delete clusterResponse.data
      return clusterResponse
    }

    return this.post<Cluster>(
      `/clusterjobs/${jobId}/clusters/${clusterId}/add_location`,
      {
        location_id: locationId,
      }
    )
  }
}

export default ClusterJobs
