import { Address } from '../../../interface/Address'
import { ClusterUpdate } from '../../../interface/ClusterUpdate'
import { ClusterCompany } from '../../../interface/ClusterCompany'
import { Cluster } from '../../../interface/Cluster'

const initialState: ClusterState = {
  _unassignedCompanyIds: new Set<string>(),
  _unassignedAddressKeys: new Set<string>(),
  _leadingCompanyId: '',
  _addressChangeMapping: {},
}

export const createAddressId = (
  companyId: number | string,
  addressId: number | string
) => {
  return `co${companyId}-a${addressId}`
}

export const extractAddressId = (key: string) => {
  return key.replace(/co[^-]+-a(.+)/i, (_, addressId) => addressId)
}

export default class ClusterState {
  _unassignedCompanyIds: Set<string> = new Set<string>()
  _unassignedAddressKeys: Set<string> = new Set<string>()
  _leadingCompanyId = ''
  _addressChangeMapping: Record<string, string> = {}

  static setUnassignedCompanies(
    companies: ClusterCompany[],
    state: ClusterState | null
  ) {
    state = state ?? initialState
    const unassignedCompanyIds = new Set<string>()

    companies.forEach(company => {
      unassignedCompanyIds.add(company.id)
    })
    return {
      ...state,
      _unassignedCompanyIds: unassignedCompanyIds,
    }
  }

  static setUnassignedAddresses(
    companies: ClusterCompany[],
    state: ClusterState | null
  ) {
    state = state ?? initialState
    const unassignedAddressKeys = new Set(state._unassignedAddressKeys)

    companies.forEach(company => {
      if (company.address?.obsolete)
        unassignedAddressKeys.add(
          createAddressId(company.id, company.address.id)
        )
      company.additional_addresses?.forEach(address => {
        if (address.obsolete)
          unassignedAddressKeys.add(createAddressId(company.id, address.id))
      })
    })

    return {
      ...state,
      _unassignedAddressKeys: unassignedAddressKeys,
    }
  }

  static setLeadingCompany(
    company: ClusterCompany,
    state: ClusterState | null
  ) {
    state = state ?? initialState

    return {
      ...state,
      _leadingCompanyId: company.id,
      _addressChangeMapping: {},
    }
  }

  static setInitialAddressChangeMapping(
    leadingCompany: ClusterCompany,
    companies: ClusterCompany[],
    initialAddressChangeMapping: Record<string, string>,
    state: ClusterState | null
  ) {
    state = state ?? initialState
    const currentAddressChangeMapping = { ...state._addressChangeMapping }
    const currentStateEmpty =
      Object.keys(currentAddressChangeMapping).length === 0
    if (currentStateEmpty) {
      companies.forEach(company => {
        const value = leadingCompany?.address?.id.toString()
        if (value) {
          const addresses = [
            ...(company.address ? [company.address] : []),
            ...(company.additional_addresses
              ? [...company.additional_addresses]
              : []),
          ]
          addresses.forEach(address => {
            const addressId = createAddressId(company.id, address.id)
            if (initialAddressChangeMapping?.[address.id]) {
              currentAddressChangeMapping[addressId] =
                initialAddressChangeMapping?.[address.id]?.toString()
            } else {
              currentAddressChangeMapping[addressId] = value
            }
          })
        }
      })
    }
    return {
      ...state,
      _addressChangeMapping: currentAddressChangeMapping,
    }
  }

  static setAddressChangeMapping(
    mergeIds: string[],
    targetId: string,
    state: ClusterState | null
  ) {
    state = state ?? initialState
    const mergeTargets = state._addressChangeMapping
    mergeIds.forEach(id => {
      mergeTargets[id] = targetId
    })
    return {
      ...state,
      _addressesMergeTargets: mergeTargets,
    }
  }

  static isAddressAssigned(
    company: ClusterCompany,
    address: Address,
    state: ClusterState | null
  ) {
    state = state ?? initialState
    return !state._unassignedAddressKeys.has(
      createAddressId(company.id, address.id)
    )
  }

  static isCompanyAssigned(
    company: ClusterCompany,
    state: ClusterState | null
  ) {
    return !state?._unassignedCompanyIds.has(company.id)
  }

  static toggleAddress(
    company: ClusterCompany,
    address: Address,
    state: ClusterState | null
  ) {
    state = state ?? initialState
    const key = createAddressId(company.id, address.id)
    const unassignedAddressKeys = new Set(state._unassignedAddressKeys)

    if (unassignedAddressKeys.has(key)) {
      unassignedAddressKeys.delete(key)
    } else {
      unassignedAddressKeys.add(key)
    }

    return {
      ...state,
      _unassignedAddressKeys: unassignedAddressKeys,
    }
  }

  static toggleCompany(company: ClusterCompany, state: ClusterState | null) {
    state = state ?? initialState
    const unassignedCompanyIds = new Set(state._unassignedCompanyIds)

    if (unassignedCompanyIds.has(company.id)) {
      unassignedCompanyIds.delete(company.id)
    } else {
      unassignedCompanyIds.add(company.id)
    }

    return {
      ...state,
      _unassignedCompanyIds: unassignedCompanyIds,
    }
  }

  static toggleCompanies(
    cluster: Cluster,
    state: ClusterState | null,
    assignAll: boolean
  ) {
    state = state ?? initialState

    const companies = [
      ...(cluster?.companies ?? []),
      ...(cluster?.unassigned_companies ?? []),
    ]

    let unassignedCompanyIds = new Set(state._unassignedCompanyIds)

    if (assignAll) {
      unassignedCompanyIds = new Set<string>()
    } else {
      companies.forEach(company => unassignedCompanyIds.add(company.id))
    }

    return {
      ...state,
      _unassignedCompanyIds: unassignedCompanyIds,
    }
  }

  static buildClusterUpdate(
    state: ClusterState | null,
    leadingCompany?: ClusterCompany
  ): ClusterUpdate {
    state = state ?? initialState

    const obsolete_addresses: number[] = []
    state._unassignedAddressKeys.forEach(key => {
      const id = extractAddressId(key)
      obsolete_addresses.push(parseFloat(id))
    })

    const unassigned_companies: number[] = []
    state._unassignedCompanyIds.forEach(id => {
      unassigned_companies.push(parseFloat(id))
    })

    const leading_company = state._leadingCompanyId

    const address_change_mapping: Record<string, string> = {}
    Object.entries(state._addressChangeMapping).forEach(([key, value]) => {
      const mergeAddressId = extractAddressId(key)
      if (value !== leadingCompany?.address?.id.toString()) {
        address_change_mapping[mergeAddressId] = value
      }
    })

    return {
      obsolete_addresses,
      unassigned_companies,
      leading_company,
      address_change_mapping,
    }
  }
}
