import React, { useEffect, useRef, useState } from 'react'
import Auth from './services/Auth/Auth'
import { defaultUserInfo } from './services/Auth/UserInfo'
import config from './config'
import Layout from './components/UI/Layout/Layout'
import useRouting from './hooks/useRouting'
import { UserContext } from './contexts/UserContext'

const isCallback = () => {
  const path = window.location.pathname
  return path.startsWith(config.CALLBACK_ROUTE)
}

const App: React.FC = () => {
  const [authInitialised, setAuth] = useState(false)
  const userInfo = useRef(defaultUserInfo)
  const { replaceState } = useRouting()

  useEffect(() => {
    document.title = config.APPLICATION_NAME
  }, [])

  useEffect(() => {
    if (isCallback() && !authInitialised) {
      const service = new Auth(window.location.hash)
      const success = service.isTokenValid()
      setAuth(success)
      if (success) {
        userInfo.current = service.createUserInfo()
      }

      // replace state only allows same origin urls as of SPEC
      replaceState(null, '', window.sessionStorage.getItem('gsp_dedup_fe_path'))
    } else if (!authInitialised) {
      const path = (window.location.href + '').replace(
        window.location.origin,
        ''
      )
      window.sessionStorage.setItem('gsp_dedup_fe_path', path)
      const service = new Auth()
      service.authorize()
    }
  }, [authInitialised, replaceState])

  if (!authInitialised) return null

  return (
    <UserContext.Provider value={userInfo.current}>
      <Layout />
    </UserContext.Provider>
  )
}

export default App
