import { Base64 } from 'js-base64'

import config from '../../config'
import { JWTPayload, JWTUserInfo, UserInfo } from './UserInfo'

// Only store JWT in-memory for better security
// See: https://auth0.com/docs/tokens/token-storage#browser-in-memory-scenarios
let notPersistedSessionToken: string | null = null

interface UnpackedHash {
  accessToken: string | null
  idToken: string | null
  payload: JWTPayload | null
  state: string | null
}

class Auth {
  private _token: string | null = this._getSessionToken()

  private _AuthUrl: string = config.AUTH_LOGIN_URL
  private _ClientId: string = config.CLIENT_ID
  private _BaseUrl: string = config.BASE_URL
  private _CallbackRoute: string = config.CALLBACK_ROUTE
  private _TokenApiScope: string = config.TOKEN_API_SCOPE

  private readonly _unpacked: UnpackedHash

  constructor(hash?: string) {
    this._unpacked = this._unpackHash(hash)
  }

  authorize() {
    this._getNewSessionToken()
  }

  // redirects to auth provider
  _getNewSessionToken() {
    const nonce = this._generateRandomString(32)
    this._setNonce(nonce)

    const state = this._generateRandomString(32)
    this._setState(state)

    const url = this._getAuthUrl(nonce, state)
    window.location.href = url
  }

  _getAuthUrl(nonce: string, state: string) {
    const params = [
      ['client_id', this._ClientId],
      ['redirect_uri', this._BaseUrl + this._CallbackRoute],
      ['state', state],
      ['nonce', nonce],
      [
        'scope',
        'email openid profile offline_access' + ' ' + this._TokenApiScope,
      ],
      ['response_type', 'token id_token'],
      ['response_mode', 'fragment'],
    ]
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    return `${this._AuthUrl}?${params}`
  }

  isTokenValid(): boolean {
    const { accessToken, idToken, state } = this._unpacked
    if (
      accessToken &&
      idToken &&
      state &&
      this._verifyState(state) &&
      this._verifyIdToken()
    ) {
      this._setToken(accessToken)
      return true
    }

    return false
  }

  _unpackHash(hash?: string): UnpackedHash {
    if (!hash) {
      return {
        accessToken: null,
        idToken: null,
        payload: null,
        state: null,
      }
    }

    // delete first #
    const unhashed = hash.substr(1)
    // split by & to get parameter=value elements
    const params = unhashed.split('&')
    // then split by = to get tuples [parameter, value]
    const paramPairs = params.map(x => x.split('='))

    const accessToken: string[] | undefined = paramPairs.find(
      x => x[0] === 'access_token'
    )
    const idToken: string[] | undefined = paramPairs.find(
      x => x[0] === 'id_token'
    )

    const state: string[] | undefined = paramPairs.find(x => x[0] === 'state')
    const unpacked: UnpackedHash = {
      accessToken: accessToken ? accessToken[1] : null,
      idToken: idToken ? idToken[1] : null,
      payload: null,
      state: state ? state[1] : null,
    }
    if (unpacked.idToken) {
      unpacked.payload = this._extractPayload(unpacked.idToken)
    }
    return unpacked
  }

  _verifyState(state: string) {
    const expectedState = this._getState()

    if (!expectedState) {
      return false
    }

    return state === expectedState
  }

  _verifyIdToken() {
    const expectedNonce = this._getNonce()

    if (!expectedNonce) {
      return false
    }

    const { payload } = this._unpacked
    return payload?.nonce === expectedNonce && payload?.aud === this._ClientId
  }

  private _getSessionToken(): string {
    const token: string | null = notPersistedSessionToken
    return token || ''
  }

  _setToken(token: string) {
    this._token = token
    notPersistedSessionToken = token.toString()
  }

  getToken(): string {
    return this._token || ''
  }

  private _setNonce(nonce: string) {
    sessionStorage.setItem('nonce', nonce)
  }

  private _setState(state: string) {
    sessionStorage.setItem('state', state)
  }

  private _getNonce(): string | null {
    return sessionStorage.getItem('nonce')
  }

  private _getState(): string | null {
    return sessionStorage.getItem('state')
  }

  _generateRandomString(length: number) {
    const characters =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._'

    const arr = new Uint8Array(length)

    window.crypto.getRandomValues(arr)

    let s = ''

    arr.forEach(x => (s += characters.charAt(x % characters.length)))

    return s
  }

  _extractPayload(token: string): JWTPayload {
    // split by . for key and signature
    // jwt = base64(header) + "." + base64(payload) + "." + base64(hash)
    // values[1] = payload (contains claims)
    const values = token.split('.')

    const payloadString = Base64.decode(values[1])
    return JSON.parse(payloadString)
  }

  clear() {
    this._setToken('')
    this._setNonce('')
    this._setState('')
  }

  createUserInfo(): UserInfo {
    const { payload } = this._unpacked
    return new JWTUserInfo(payload)
  }
}

export default Auth
