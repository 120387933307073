import React, { ReactNode, useCallback, useMemo } from 'react'

import styles from './SelectBox.module.scss'

import { useDropdown } from '../../../hooks/useDropdown'
import Checkbox from '../Checkbox/Checkbox'
import { isSelected } from '../../../utils/JobUtils'

export interface Option<T = string> {
  label: string | JSX.Element
  value: T
}

export const NO_SELECTION: Option<'-'> = {
  label: '-',
  value: '-',
}

interface SelectBoxProps {
  disabled?: boolean
  id?: string
  label?: ReactNode
  options: Option[]
  onChange: (selected: Option) => void
  values: string | string[]
  isCheckbox?: boolean
  includeNoSelect?: boolean
  isMultiSelect?: boolean
}
export const SelectBox: React.FC<SelectBoxProps> = (props: SelectBoxProps) => {
  const [dropdownRef, dropdownOpen, setDropdownOpen] =
    useDropdown<HTMLDivElement>()

  const {
    disabled,
    id,
    label,
    onChange,
    options,
    values,
    isCheckbox,
    includeNoSelect,
    isMultiSelect,
  } = props

  const selectedOptions = useMemo(() => {
    const returnList: Option[] = []
    if (Array.isArray(values)) {
      values.forEach(value => {
        const selected = options.find(x => x.value === value)
        if (selected) returnList.push(selected)
      })
    } else {
      const selectedOption = options.find(x => x.value === values)
      if (selectedOption) returnList.push(selectedOption)
    }

    if (returnList.length === 0 && includeNoSelect) {
      returnList.push({
        ...NO_SELECTION,
        label: '',
      })
    }

    return returnList
  }, [includeNoSelect, options, values])

  const handleIsSelected = useCallback(
    (values: string | string[], option: Option) => {
      return isSelected(values, option)
    },
    []
  )

  const handleSelect = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>, option?: string) => {
      const selectedValue =
        e?.currentTarget.getAttribute('data-option') ?? option
      const opt = options.find(x => x.value === selectedValue)
      onChange(opt ?? NO_SELECTION)
      if (isMultiSelect) setDropdownOpen(true)
    },
    [isMultiSelect, onChange, options, setDropdownOpen]
  )

  const handleToggleDropdown = useCallback(() => {
    setDropdownOpen(before => !before)
  }, [setDropdownOpen])

  return (
    <div ref={dropdownRef} className="d-block btn-group position-relative">
      <button
        id={id}
        type="button"
        className={`form-control-select-custom dropdown-toggle ${
          label ? 'pb-0' : ''
        }`}
        data-toggle="dropdown"
        aria-haspopup="true"
        onClick={handleToggleDropdown}
        aria-expanded={dropdownOpen ? 'true' : 'false'}>
        <div className={`${styles.truncated}`}>
          {selectedOptions.map(option => option.label).join(', ')}
        </div>
      </button>
      {label && (
        <label
          htmlFor={id}
          className={`${styles.label} ${
            selectedOptions.map(option => option.label).length
              ? styles.focused
              : ''
          }`}>
          {label}
        </label>
      )}
      <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
        {isCheckbox ? (
          <>
            {includeNoSelect && (
              <Checkbox
                data-semantic-id="select-box"
                inputKey={NO_SELECTION.value}
                key={`opt:${NO_SELECTION.value}`}
                checked={handleIsSelected(values, NO_SELECTION)}
                labelClasses={['dropdown-item', 'ml-1']}
                onChange={() => handleSelect(undefined, NO_SELECTION.value)}>
                {NO_SELECTION.label}
              </Checkbox>
            )}
            {options.map(opt => (
              <div key={`opt:${opt.value}`} className={'dropdown-item px-0'}>
                <Checkbox
                  data-semantic-id="select-box"
                  inputKey={opt.value}
                  key={`opt:${opt.value}`}
                  checked={handleIsSelected(values, opt)}
                  inputClasses={['position-relative', 'ml-3']}
                  labelClasses={['pr-2']}
                  onChange={() => handleSelect(undefined, opt.value)}>
                  {opt.label}
                </Checkbox>
              </div>
            ))}
          </>
        ) : (
          <>
            {includeNoSelect && (
              <button
                className={`dropdown-item ${
                  handleIsSelected(values, NO_SELECTION) ? '' : ''
                }`}
                onClick={handleSelect}
                disabled={disabled}
                data-option={NO_SELECTION.value}
                aria-selected={
                  handleIsSelected(values, NO_SELECTION) ? 'false' : 'false'
                }>
                {NO_SELECTION.label}
              </button>
            )}
            {options.map(opt => (
              <button
                key={`opt:${opt.value}`}
                className={`dropdown-item ${
                  handleIsSelected(values, opt) ? styles.selected : ''
                }`}
                onClick={handleSelect}
                data-option={opt.value}
                aria-selected={
                  handleIsSelected(values, opt) ? 'true' : 'false'
                }>
                {opt.label}
              </button>
            ))}
          </>
        )}
      </div>
    </div>
  )
}
