import { useEffect, useRef, useState } from 'react'

export function useDropdown<T extends HTMLElement>(): [
  React.RefObject<T>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<T>(null)

  useEffect(() => {
    const handleDocumentClick = (ev: MouseEvent) => {
      if (!isOpen) {
        return
      }
      if (!ref.current) {
        return
      }
      const target: HTMLElement = (ev as any).target
      if (target && target.classList.contains('dropdown-item')) {
        setIsOpen(false)
        return
      }
      if (ref.current.contains(target)) {
        return
      }
      setIsOpen(false)
    }

    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isOpen])

  return [ref, isOpen, setIsOpen]
}
