import React, { useCallback, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Address } from '../../../../interface/Address'
import CompanyAddressForm from '../../../UI/CompanyAddressForm/CompanyAddressForm'
import AddressInline from '../../../UI/AddressInline/AddressInline'
import Icon from '../../../UI/Icon/Icon'
import ClusterContext from '../../ClusterContext'
import ClusterState, { createAddressId } from '../../ClusterState/ClusterState'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import { ResponseObject } from '../../../../interface/ResponseObject'
import ClusterJobs from '../../../../services/ClusterJobs/ClusterJobs'
import { ClusterCompany } from '../../../../interface/ClusterCompany'
import AddressInfo from '../../../UI/AddressInfo/AddressInfo'
import { MergeComment } from '../../../UI/MergeComment/MergeComment'
import StatusRenderer from '../../../UI/StatusRenderer/StatusRenderer'
import useModal from '../../../../hooks/useModal'
import { MergeAddressesModal } from '../../../ChangeMergeAddressesModal/MergeAddressesModal'
import { Modal } from '../../../UI/Modal/Modal'

interface Props {
  companyAssigned?: boolean
  jobId: number
  clusterId: number
  company: ClusterCompany
  address: Address
  editable: boolean
  selectable?: boolean
  mergeable?: boolean
  jobIsPermittedAndAssigned: boolean
  onAssignmentChanged?: (address: Address) => void
  onUpdate: (address: Address) => void
  containerClasses?: string
  headerClasses?: string
  bodyClasses?: string
  showExternalNumbers?: boolean
  showMergeComments?: boolean
  modalTitle?: string
  showMergeAddress?: boolean
  leadingCompanyAddresses?: Address[]
  onSetAddressChangeMapping?: (mergeIds: string[], targetId: string) => void
  showMergeTargetButton?: boolean
}

const ClusterCompanyAddress: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const state = useContext(ClusterContext)

  const {
    jobId,
    clusterId,
    company,
    address,
    editable,
    selectable = true,
    mergeable = true,
    companyAssigned = true,
    jobIsPermittedAndAssigned,
    onAssignmentChanged,
    onUpdate,
    containerClasses = 'my-0 pl-4',
    headerClasses = 'px-4',
    bodyClasses = '',
    showExternalNumbers,
    showMergeComments,
    modalTitle,
    leadingCompanyAddresses,
    onSetAddressChangeMapping,
    showMergeAddress = false,
    showMergeTargetButton = false,
  } = props
  const addressAssigned = ClusterState.isAddressAssigned(
    company,
    address,
    state
  )
  const inputKey = `clustercompany-included-j${jobId}-cl${clusterId}-co${company.id}-a${address.id}`
  const addressId = createAddressId(company.id, address.id)

  const [isEditing, setIsEditing] = useState(false)
  const isFormVisible = addressAssigned && isEditing

  const toggleEditor = useCallback(() => {
    setIsEditing(prev => !prev)
  }, [])

  const handleAddressUpdate = useCallback(
    (updatedAddress: Address) => {
      setIsEditing(false)
      onUpdate(updatedAddress)
    },
    [onUpdate]
  )

  const handleIncludedChanged = useCallback(() => {
    if (onAssignmentChanged) onAssignmentChanged(address)
  }, [address, onAssignmentChanged])

  const handleAddressSubmit = useCallback(
    async (values: Address) => {
      const service = new ClusterJobs()
      const response: ResponseObject<any> =
        await service.updateJobCompanyAddress(
          jobId,
          clusterId,
          company.id,
          address.id,
          values
        )
      return response
    },
    [jobId, clusterId, company, address]
  )

  const addressChangeMapping = state?._addressChangeMapping ?? {}
  const mergeTargetId = +addressChangeMapping[addressId]
  const { isOpen: mergeModalOpen, toggle: toggleMergeModal } = useModal()

  return (
    <div
      data-semantic-id="cluster-company-address"
      className={`${containerClasses}`}>
      <div className={`d-flex align-items-baseline ${headerClasses}`}>
        {jobIsPermittedAndAssigned && selectable ? (
          <Checkbox
            data-semantic-id="cluster-company-address-toggle"
            isSwitch
            inputKey={inputKey}
            checked={addressAssigned}
            labelClasses={[addressAssigned ? '' : 'is-unassigned']}
            onChange={handleIncludedChanged}
            disabled={
              !mergeable ||
              company.status?.toUpperCase() === 'UNQUALIFIED' ||
              !companyAssigned
            }>
            <AddressInline
              address={address}
              showExternalNumbers={showExternalNumbers}
            />
          </Checkbox>
        ) : (
          <div>
            <AddressInline
              address={address}
              showExternalNumbers={showExternalNumbers}
            />
          </div>
        )}
        <StatusRenderer
          id={company.id}
          status={address.status}
          size={14}
          className={'pl-1'}
        />
        <AddressInfo inputKey={inputKey} address={address} />

        {jobIsPermittedAndAssigned && companyAssigned && (
          <button
            data-semantic-id="cluster-company-address-edit"
            disabled={!addressAssigned || !editable}
            onClick={toggleEditor}
            className={`btn btn-sm p-0 border-0 ${isEditing ? 'active' : ''} ${
              !addressAssigned || !editable ? 'disabled opacity-25' : ''
            }`}
            title={isEditing ? t('Cancel') : t('Edit')}>
            <Icon name="pencil" />
          </button>
        )}
        {showMergeTargetButton &&
          jobIsPermittedAndAssigned &&
          companyAssigned && (
            <button
              data-semantic-id="cluster-company-address-change-merge-target"
              disabled={!mergeable || !editable}
              onClick={toggleMergeModal}
              className={`btn btn-sm m-1 p-0 border-0 ${
                isEditing ? ' active' : ''
              } ${!mergeable || !editable ? 'disabled opacity-25' : ''}`}
              title="">
              <Icon name="intersect" className="ml-1" />
            </button>
          )}
        {showMergeComments && companyAssigned && (
          <MergeComment
            data-semantic-id="cluster-company-address-merge-comment"
            company={company}
            isAssigned={addressAssigned}
            showMergeTarget={showMergeAddress}
            mergeTargetId={mergeTargetId}
            type={'Address'}
          />
        )}
        {leadingCompanyAddresses && onSetAddressChangeMapping && (
          <MergeAddressesModal
            addressId={addressId}
            title={t('MergeAddressesModalAddressLevelTitle')}
            isOpen={mergeModalOpen}
            toggle={toggleMergeModal}
            leadingCompanyAddresses={leadingCompanyAddresses}
            mergeIds={[addressId]}
            addressChangeMapping={addressChangeMapping}
            handleSetAddressChangeMappings={onSetAddressChangeMapping}
          />
        )}
      </div>
      <Modal
        title={modalTitle ?? ''}
        isOpen={isFormVisible}
        toggle={toggleEditor}
        data-semantic-id="cluster-company-address-modal"
        contentClasses={['p-0']}>
        {isFormVisible && (
          <div className={`card-body companies edit-form ${bodyClasses}`}>
            <CompanyAddressForm
              jobId={jobId}
              companyId={company.id}
              address={address}
              address_errors={company.address_errors}
              onUpdate={handleAddressUpdate}
              onCancel={toggleEditor}
              onSubmit={handleAddressSubmit}
              jobIsPermittedAndAssigned={jobIsPermittedAndAssigned}
            />
          </div>
        )}
      </Modal>
    </div>
  )
}

export default ClusterCompanyAddress
