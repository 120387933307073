/* eslint-env node */

const envOr = (key, defaultValue) => {
  const env = window?._env_ ?? {}
  const value = env[key]
  const isValid = value && String(value).length > 0
  return isValid ? value : defaultValue
}

const config = {
  AUTH_LOGIN_URL: envOr('LOGIN_URL', 'http://localhost:3003/login'),
  AUTH_LOGOUT_URL: envOr('LOGOUT_URL', null),
  API_URL: envOr('API_URL', 'http://localhost:3003'),
  API_TIMEOUT: envOr('API_TIMEOUT', 5000),
  API_MAX_RETRIES: envOr('API_MAX_RETRIES', 10),
  API_BACKOFF_RETRY_FACTOR: envOr('API_BACKOFF_RETRY_FACTOR', 250),
  CLIENT_ID: envOr('CLIENT_ID', 'clientId'),
  BASE_URL: envOr('BASE_URL', 'http://localhost:3000'),
  CALLBACK_ROUTE: '/callback',
  TOKEN_API_SCOPE: envOr('TOKEN_API_SCOPE', ''),
  UI_TESTING:
    process.env.NODE_ENV !== 'production' &&
    typeof window.Cypress !== 'undefined',
  IMPORT_STATUS_RETRY_INTERVAL: envOr('IMPORT_STATUS_RETRY_INTERVAL', 5000),
  /** Long running requests that are purposely left alive get a 2h time frame to complete */
  LONG_RUNNING_TIMEOUT: envOr('LONG_RUNNING_TIMEOUT', 2 * 60 * 60 * 1000),
  SUBSEQUENT_FAILURE_THRESHOLD: envOr('SUBSEQUENT_FAILURE_THRESHOLD', 10),
  CUSTOMER_LOGO: envOr('CUSTOMER_LOGO', null),
  CUSTOMER_CLAIM: envOr('CUSTOMER_CLAIM', null),
  CUSTOMER_CLAIM_TEXT: envOr('CUSTOMER_CLAIM_TEXT', 'Ingenuity for life'),
  APPLICATION_NAME: envOr('APPLICATION_NAME', 'SIBase Pre-Processing'),
  HELP_URL: envOr('HELP_URL', null),
  TECHNICAL_HELP_URL: envOr('TECHNICAL_HELP_URL', null),
  GSP_URL_PREFIX: envOr('GSP_URL_PREFIX', 'http://localhost:3003/gsp-prefix/'),
}

if (config.UI_TESTING) {
  config.API_MAX_RETRIES = 5
  config.API_BACKOFF_RETRY_FACTOR = 0
  config.IMPORT_STATUS_RETRY_INTERVAL = 100
  config.LONG_RUNNING_TIMEOUT = 1500
  config.SUBSEQUENT_FAILURE_THRESHOLD = 5

  // Exposing a readonly version of our final app config for UI tests
  window.APPCONFIG = Object.freeze(Object.assign({}, config))
}

export default config
