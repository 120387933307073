import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import config from '../../config'
import styles from './GspDashboard.module.scss'
import { CircleSectionChart } from './CircleSectionChart/CircleSectionChart'
import Icon from '../UI/Icon/Icon'
import DashboardService from '../../services/Dashboard/Dashboard'
import { SystemStatus } from '../../interface/SystemStatus'
import BackdropSpinner from '../UI/BackdropSpinner/BackdropSpinner'
import { DashboardContent } from '../../interface/DashboardContent'
import { useDropdown } from '../../hooks/useDropdown'
import { JobStatus } from '../../interface/Job'
import { ClusterJobStatus } from '../../interface/ClusterJob'
import { FormattedDate } from '../UI/FormattedDate/FormattedDate'
import { TooltipHandle } from '../UI/TooltipHandle/TooltipHandle'

interface CardInfoProps {
  name: string
  children?: ReactNode
}

const CardInfo: React.FC<CardInfoProps> = (props: CardInfoProps) => (
  <TooltipHandle
    data-semantic-id="cardinfo"
    data-semantic-context={props.name}
    name={props.name}
    label={<Icon name="info-circle" />}>
    {props.children}
  </TooltipHandle>
)

const CardSubtitle = (props: { children?: ReactNode }) => (
  <small className="card-subtitle d-flex mb-2 text-muted">
    {props.children ?? <span data-empty>&nbsp;</span>}
  </small>
)

const BigPill = (props: { children?: ReactNode }) => (
  <div className="position-relative d-flex justify-content-center">
    <div
      className={`rounded-circle bg-primary d-flex justify-content-center align-items-center ${styles.bigpill}`}>
      {props.children}
    </div>
  </div>
)

interface SeeAllJobsProps {
  status?: JobStatus & ClusterJobStatus
  clusterStatus?: ClusterJobStatus
  importStatus?: JobStatus
}

const SeeAllJobs: React.FC<SeeAllJobsProps> = (props: SeeAllJobsProps) => {
  const { t } = useTranslation()
  const [ref, open, setOpen] = useDropdown<HTMLDivElement>()

  const encodeStatus = (status: any) =>
    status ? `status=${encodeURIComponent(status)}` : ''

  const status = encodeStatus(props.status)
  const clusterStatus = encodeStatus(props.clusterStatus)
  const importStatus = encodeStatus(props.importStatus)

  const clusterStatusQuery = `?${[clusterStatus, status]
    .filter(Boolean)
    .join('&')}`

  const importStatusQuery = `?${[importStatus, status]
    .filter(Boolean)
    .join('&')}`

  const handleToggleDropdown = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      ev.preventDefault()
      setOpen(before => !before)
    },
    [setOpen]
  )

  return (
    <div
      data-semantic-id="seealljobs"
      ref={ref}
      className={`dropup ${open ? 'show' : ''}`}>
      <a
        href="#"
        id="seeAllJobs"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        onClick={handleToggleDropdown}
        aria-expanded={open ? 'true' : 'false'}>
        <Icon name="chevron-right">{t('SeeAllJobsLinkLabel')}</Icon>
      </a>
      <div
        className={`dropdown-menu ${open ? 'show' : ''}`}
        aria-labelledby="seeAllJobs">
        <Link to={`/importjobs${importStatusQuery}`} className="dropdown-item">
          {t('ImportJobsLinkLabel')}
        </Link>
        <Link
          to={`/clusterjobs${clusterStatusQuery}`}
          className="dropdown-item">
          {t('ClusterJobsLinkLabel')}
        </Link>
      </div>
    </div>
  )
}

const getDashboardContent = () => {
  const service = new DashboardService()
  return service.getDashboardContent()
}

const createJobLink = (job: {
  id: string
  type: 'IMPORT_JOB' | 'CLUSTER_JOB'
}) =>
  job.type === 'IMPORT_JOB' ? `/importjobs/${job.id}` : `/clusterjobs/${job.id}`

export default function GspDashboard() {
  const { t } = useTranslation()
  const [showMessage, setShowMessage] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [systemStatus, setSystemStatus] = useState<SystemStatus | null>(null)
  const [serverData, setServerData] = useState<DashboardContent | null>(null)
  const [processingPercentage, setProcessingPercentage] = useState(0)
  const [queuedPercentage, setQueuedPercentage] = useState(0)

  const hideMessage = useCallback(() => {
    setShowMessage(false)
  }, [])

  useEffect(() => {
    const fetchContent = async () => {
      setIsLoading(true)
      try {
        const res = await getDashboardContent()
        if (!res.success || !res.data) {
          return
        }
        const data = res.data
        setSystemStatus({
          available: data.system_status === 'OK',
          timestamp: data.modified_at ?? new Date().toISOString(),
        })
        setServerData(data)
        setProcessingPercentage(
          ((data.total_jobs - data.processing_jobs) / data.total_jobs) * 100
        )
        setQueuedPercentage(
          ((data.total_jobs - data.queued_jobs) / data.total_jobs) * 100
        )
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        setIsLoading(false)
      }
    }
    fetchContent()
  }, [])

  return (
    <div data-semantic-id="dashboard">
      <h1>{config.APPLICATION_NAME}</h1>
      <p className="mt-4">{t('DashboardDescription')}</p>

      {showMessage && serverData?.alert && (
        <div className={`alert alert-inquisitive ${styles.mt8}`}>
          <button
            type="button"
            className={`close ${styles.alertClose}`}
            onClick={hideMessage}
            data-dismiss="alert"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="d-flex">
            <div className="mr-2">
              <Icon name="info-circle" className="p-0" />
            </div>
            <div>
              <h4 className="alert-heading">{serverData?.alert?.title}</h4>
              <div className="alert-body">{serverData?.alert?.description}</div>
            </div>
          </div>
        </div>
      )}

      <div className={`row row-cols-1 row-cols-md-2 ${styles.mt8}`}>
        <div className="col mt-4 mb-4">
          <div
            data-semantic-id="importjobs-card"
            className="card border-light h-100 rounded-shadow-custom">
            <BigPill>
              <Icon name="chevron-bar-down" />
            </BigPill>
            <div className="card-body">
              <h4 className="card-title text-primary text-center font-weight-normal">
                {t('ImportJobsCardHeader')}
              </h4>
              <div className="card-text">{t('ImportJobsCardDescription')}</div>
            </div>
            <div className="card-footer border-0 bg-white">
              <div className="d-flex justify-content-center mb-2">
                <Link to="/importjobs" className="btn btn-secondary">
                  {t('ImportJobsLinkLabel')}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col mt-4 mb-4">
          <div
            data-semantic-id="clusterjobs-card"
            className="card border-light h-100 rounded-shadow-custom">
            <BigPill>
              <Icon name="grid-3x3-gap-fill" />
            </BigPill>
            <div className="card-body">
              <h4 className="card-title text-primary text-center font-weight-normal">
                {t('ClusterJobsCardHeader')}
              </h4>
              <div className="card-text">{t('ClusterJobsCardDescription')}</div>
            </div>
            <div className="card-footer border-0 bg-white">
              <div className="d-flex justify-content-center mb-2">
                <Link to="/clusterjobs" className="btn btn-secondary">
                  {t('ClusterJobsLinkLabel')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-md-4">
        <div className="col mb-4">
          <div
            data-semantic-id="systemstatus-card"
            className="card border-light h-100 rounded-shadow-custom">
            <div className="card-body">
              <h5 className="card-title mb-1 d-flex justify-content-between">
                {t('SystemStatusTitle')}
                <CardInfo name="systemstatus">{t('SystemStatusInfo')}</CardInfo>
              </h5>
              <BackdropSpinner show={isLoading}>
                <div>
                  <CardSubtitle>
                    {t('SystemStatusUpdatedAt')}
                    <span>&nbsp;</span>
                    <FormattedDate
                      date={systemStatus?.timestamp}
                      format="datetime"
                    />
                  </CardSubtitle>
                  {systemStatus?.available ? (
                    <div
                      className={'justify-content-center m-auto'}
                      style={{ width: '50%' }}>
                      <CircleSectionChart percentage={100}>
                        <p className="lead mb-0">
                          {t('SystemStatusAvailable')}
                        </p>
                      </CircleSectionChart>
                    </div>
                  ) : (
                    <div
                      className={'justify-content-center m-auto'}
                      style={{ width: '50%' }}>
                      <CircleSectionChart stroke="#f00">
                        <p className="lead mb-0">
                          {t('SystemStatusUnavailable')}
                        </p>
                      </CircleSectionChart>
                    </div>
                  )}
                </div>
              </BackdropSpinner>
            </div>
          </div>
        </div>

        <div className="col mb-4">
          <div
            data-semantic-id="lastjobs-card"
            className="card border-light h-100 rounded-shadow-custom">
            <div className="card-body pb-0">
              <h5 className="card-title d-flex justify-content-between">
                {t('LastJobsTitle')}
                <CardInfo name="lastjobs">{t('LastJobsInfo')}</CardInfo>
              </h5>
              <BackdropSpinner show={isLoading}>
                <ol
                  className="list-unstyled m-0"
                  data-semantic-id="lastjobs-list">
                  {serverData?.latest_ready_jobs?.map((job, i) => {
                    const route = createJobLink(job)
                    return (
                      <li
                        key={`${route}-${i}`}
                        className="d-flex mb-2"
                        data-lastjobs-item>
                        <div className={styles.joblistCol1}>
                          <Link to={route}>{job.id}</Link>
                        </div>
                        <div className="flex-grow">
                          {job.created_by}
                          <small className="d-block text-muted">
                            <FormattedDate
                              date={job.created_at}
                              format="date"
                            />
                            {' / '}
                            <span className="d-inline-block nowrap">
                              {t(job.type)}
                            </span>
                            {' / '}
                            <span className="d-inline-block nowrap">
                              {job.type === 'CLUSTER_JOB'
                                ? t(`ClusterJobStatus_${job.status}`)
                                : job.type === 'IMPORT_JOB'
                                ? t(`ImportJobStatus_${job.status}`)
                                : job.status}
                            </span>
                          </small>
                        </div>
                      </li>
                    )
                  })}
                </ol>
              </BackdropSpinner>
            </div>
          </div>
          <SeeAllJobs importStatus="READY" clusterStatus="RESULT_FETCHED" />
        </div>

        <div className="col mb-4">
          <div
            data-semantic-id="processingjobs-card"
            className="card border-light h-100 rounded-shadow-custom">
            <div className="card-body">
              <h5 className="card-title d-flex justify-content-between">
                {t('ProcessingJobsTitle')}
                <CardInfo name="processingjobs">
                  {t('ProcessingJobsInfo')}
                </CardInfo>
              </h5>
              <CardSubtitle />
              <BackdropSpinner show={isLoading}>
                <div
                  className={'justify-content-center m-auto'}
                  style={{ width: '50%' }}>
                  <CircleSectionChart percentage={processingPercentage}>
                    <p className="lead m-0">
                      {t('ProcessingJobsProcessingLabel')}:{' '}
                      {serverData?.processing_jobs}
                    </p>
                    <div className={styles.total}>
                      {t('ProcessingJobsTotalLabel')}: {serverData?.total_jobs}
                    </div>
                  </CircleSectionChart>
                </div>
              </BackdropSpinner>
            </div>
          </div>
          <SeeAllJobs
            status="PROCESSING"
            clusterStatus="CREATED"
            importStatus="IMPORTING"
          />
        </div>

        <div className="col mb-4">
          <div
            data-semantic-id="waitingjobs-card"
            className="card border-light h-100 rounded-shadow-custom">
            <div className="card-body">
              <h5 className="card-title d-flex justify-content-between">
                {t('WaitingForPipelineTitle')}
                <CardInfo name="waitingjobs">
                  {t('WaitingForPipelineInfo')}
                </CardInfo>
              </h5>
              <CardSubtitle />
              <BackdropSpinner show={isLoading}>
                <div
                  className={' justify-content-center m-auto'}
                  style={{ width: '50%' }}>
                  <CircleSectionChart percentage={queuedPercentage}>
                    <p className="lead m-0">
                      {t('WaitingForPipelineWaitingLabel')}:{' '}
                      {serverData?.queued_jobs}
                    </p>
                    <div className={styles.total}>
                      {t('ProcessingJobsTotalLabel')}: {serverData?.total_jobs}
                    </div>
                  </CircleSectionChart>
                </div>
              </BackdropSpinner>
            </div>
          </div>
          <SeeAllJobs status="QUEUED" />
        </div>
      </div>
    </div>
  )
}
