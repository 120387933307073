import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../Modal/Modal'
import useModal from '../../../hooks/useModal'
import ClusterJobs from '../../../services/ClusterJobs/ClusterJobs'
import Spinner from '../Spinner/Spinner'
import { Option } from '../SelectBox/SelectBox'
import { isSelected } from '../../../utils/JobUtils'
import Checkbox from '../Checkbox/Checkbox'
import { MasterDataCompany } from '../../../interface/MasterDataCompany'
import useApi from '../../../hooks/useApi'
import BootstrapAlert from '../BootstrapAlert/BootstrapAlert'
import { CMDSearchSplitButton } from './CMDSearchSplitButton'
import CompanyName from '../CompanyName/CompanyName'
import AddressInline from '../AddressInline/AddressInline'
import { Company } from '../../../interface/Company'

const fetchAdditionalClusters = async (
  jobId: number,
  clusterId: number,
  regexSearch: boolean
) => {
  const service: ClusterJobs = new ClusterJobs()
  return await service.getAdditionalClusters(jobId, clusterId, regexSearch)
}

const CMDResultLabel = (props: { company: Company }) => {
  const { company } = props
  return (
    <>
      <CompanyName company={company} showId={false} showSource={false} />
      {company.address && (
        <>
          &#044; &nbsp;
          <AddressInline address={company.address} />
        </>
      )}
      {company.address?.external_numbers?.[0] &&
        company.address?.external_numbers?.[0].number != '' && (
          <>
            &#044; &nbsp;
            {company.address.external_numbers?.[0].number}
            &#044; &nbsp;
            {company.address.external_numbers?.[0].type}
          </>
        )}
      {company.address?.external_numbers?.[1] &&
        company.address?.external_numbers?.[1].number != '' && (
          <>
            &#044; &nbsp;
            {company.address.external_numbers?.[1].number}
            &#044; &nbsp;
            {company.address.external_numbers?.[1].type}
          </>
        )}
    </>
  )
}

interface Props {
  jobId: number
  clusterId: number
  onUpdate: (companyId: string) => void
  className?: string
}

const CMDSearch: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const { jobId, clusterId, onUpdate, className } = props
  const cssClasses = []

  if (className && className.length) {
    cssClasses.push(className)
  }

  const { isOpen: modalOpen, toggle: toggleModal } = useModal()
  const [companyId, setCompanyId] = useState<string>('')
  const [searchResults, setSearchResults] = useState<MasterDataCompany[]>([])

  const {
    call: cmdSearch,
    error,
    isLoading,
    response: cmdSearchResponse,
  } = useApi<MasterDataCompany[], boolean>(
    t,
    'ErrorOccurred',
    useCallback(
      (regexSearch: boolean) =>
        fetchAdditionalClusters(jobId, clusterId, regexSearch),
      [jobId, clusterId]
    )
  )

  useEffect(() => {
    if (cmdSearchResponse?.success && cmdSearchResponse.data) {
      setSearchResults(cmdSearchResponse.data)
    }
  }, [cmdSearchResponse])

  const handleAdditionalSearch = useCallback(
    async (regexSearch?: boolean) => {
      toggleModal()
      await cmdSearch(regexSearch)
    },
    [cmdSearch, toggleModal]
  )

  const handleUpdateCompany = useCallback(() => {
    onUpdate(companyId)
    toggleModal()
  }, [onUpdate, companyId, toggleModal])

  const handleIsSelected = useCallback(
    (values: string | string[], option: Option) => {
      return isSelected(values, option)
    },
    []
  )

  const handleChange = useCallback((value: string, checked: boolean) => {
    setCompanyId(checked ? '' : value)
  }, [])

  return (
    <>
      <CMDSearchSplitButton
        onSearch={handleAdditionalSearch}
        tooltip="CMDSearchTooltip"
      />

      <Modal
        data-semantic-id="cmd-search-result-modal"
        isOpen={modalOpen}
        toggle={toggleModal}>
        {isLoading && <Spinner />}
        {!isLoading && error?.message && (
          <div data-semantic-id="cmd-search-result-error">
            <BootstrapAlert
              type="danger"
              message={error?.message}
              status={error?.system}
            />
          </div>
        )}
        {!isLoading && searchResults && (
          <div data-semantic-id="cmd-search-results">
            {searchResults
              .map(company => ({
                label: <CMDResultLabel company={company} />,
                value: company.id,
              }))
              .map(opt => (
                <div
                  data-semantic-id="cmd-search-result"
                  key={`opt:${opt.value}`}
                  className={'dropdown-item px-0'}>
                  <Checkbox
                    data-semantic-id="select-box"
                    inputKey={opt.value}
                    key={`opt:${opt.value}`}
                    inputClasses={['position-relative', 'ml-3']}
                    labelClasses={['pr-2']}
                    checked={handleIsSelected(companyId, opt)}
                    onChange={() =>
                      handleChange(opt.value, handleIsSelected(companyId, opt))
                    }>
                    {opt.label}
                  </Checkbox>
                </div>
              ))}
          </div>
        )}
        <button
          className={'btn btn-primary m-1 p-1'}
          data-semantic-id={'cmd-search-cancel'}
          onClick={toggleModal}>
          {`${t('Cancel')}`}
        </button>
        <button
          className={'btn btn-secondary m-1 p-1'}
          data-semantic-id={'cmd-search-save'}
          onClick={handleUpdateCompany}
          disabled={!companyId}>
          {`${t('Save')}`}
        </button>
      </Modal>
    </>
  )
}
export default CMDSearch
