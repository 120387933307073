import { ResponseObject } from '../interface/ResponseObject'
import Auth from './Auth/Auth'
import config from '../config'
import i18n from '../i18n/i18n'

export enum RetryCommand {
  Accept,
  Reject,
  Retry,
  RetryKeepAlive,
}

export enum CancellationCommand {
  Cancel,
  KeepAlive,
}

export interface RetryPolicy<T> {
  (response: ResponseObject<T>, attempts: number): RetryCommand
}

export interface CancellationPolicy {
  (attempts: number): CancellationCommand
}

interface Options {
  headers?: { [key: string]: any }
  redirect?: 'follow' | 'error' | 'manual'
}

type Method = 'POST' | 'PUT' | 'GET' | 'DELETE' | 'PATCH'

/** We decided to use 408 to setup a server side timeout to keep numeric statuscodes in header. **/
type BackendError = null | 400 | 403 | 404 | 422 | 408 // use 409, 423, 424 for manual testing of mock be /add

export const sleep = (ms?: number) => {
  if (!ms) {
    return Promise.resolve()
  }
  return new Promise<void>(resolve => {
    setTimeout(() => resolve(), ms)
  })
}

abstract class ApiService {
  Timeout: number = config.API_TIMEOUT
  BaseUrl: string = config.API_URL
  MaxRetries: number = config.API_MAX_RETRIES
  BackOffRetryFactor: number = config.API_BACKOFF_RETRY_FACTOR
  LongRunningTimeout = config.LONG_RUNNING_TIMEOUT
  SubsequentFailureThreshold = config.SUBSEQUENT_FAILURE_THRESHOLD

  /** Additional parameter for mock backend to return a special status code */
  expectStatusCode: BackendError = null
  AuthProvider = new Auth()

  protected _dbRequest<T>(
    method: Method,
    apiPath: string,
    body?: any,
    options?: Options,
    retryPolicy?: RetryPolicy<T>,
    cancellationPolicy?: CancellationPolicy
  ): Promise<ResponseObject<T>> {
    options = options ?? {
      headers: {},
    }
    const jwt = this.AuthProvider.getToken()
    const config: any = {
      method: method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      ...options,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwt,
        ...options.headers,
      },
    }

    if (this.expectStatusCode) {
      /** fetch does always convert the headers to lowercase  */
      config.headers['expect-status-code'] = this.expectStatusCode
    }

    if (body) {
      config.body = JSON.stringify(body)
    }

    return this._doRequestWithTimeoutAndRetry(
      this.BaseUrl + apiPath,
      config,
      retryPolicy,
      cancellationPolicy
    )
  }

  protected _determineNextRetryIntervalMs(attempts: number) {
    return (attempts - 1) * this.BackOffRetryFactor
  }

  private async _doRequestWithTimeoutAndRetry<T>(
    url: string,
    options: any,
    retryPolicy?: RetryPolicy<T>,
    cancellationPolicy?: CancellationPolicy
  ) {
    let alive = true
    const killHandle = setTimeout(() => {
      alive = false
    }, this.LongRunningTimeout)

    const states: number[] = []

    loop: do {
      const attempts = states.length
      let response: ResponseObject<T>

      try {
        await sleep(this._determineNextRetryIntervalMs(attempts))
        if (cancellationPolicy) {
          switch (cancellationPolicy(attempts)) {
            case CancellationCommand.Cancel:
              throw new Error('Cancelled by policy')
            case CancellationCommand.KeepAlive:
              break
          }
        }
        const rawResponse = await this._fetchWithTimeout(url, options)
        response = await this._handleResponse(rawResponse)
      } catch (e) {
        response = this._handleResponseError(e as any)
      }

      if (!retryPolicy) {
        clearTimeout(killHandle)
        return response
      }

      const cmd = retryPolicy(response, attempts + 1)

      if (cmd === RetryCommand.Retry && attempts >= this.MaxRetries - 1) {
        break
      }

      switch (cmd) {
        case RetryCommand.Accept:
          clearTimeout(killHandle)
          return response
        case RetryCommand.Reject:
          clearTimeout(killHandle)
          response.success = false
          return response
        case RetryCommand.Retry:
        case RetryCommand.RetryKeepAlive: {
          states.push(response.status ?? 0)
          const max = this.SubsequentFailureThreshold
          const canHaveTooManyFailuresInARow = states.length >= max
          if (!canHaveTooManyFailuresInARow) {
            continue
          }
          let hasTooManyFailureStatesInARow = true
          for (let i = 0, len = states.length; i < max; i += 1) {
            const state = states[len - 1 - i]
            if (state >= 200 && state < 400) {
              hasTooManyFailureStatesInARow = false
              break
            }
          }
          if (hasTooManyFailureStatesInARow) {
            break loop
          }
          continue
        }
      }
    } while (alive)

    clearTimeout(killHandle)
    return { success: false, message: i18n.t('MaxRetriesExceeded') }
  }

  protected get<T>(
    apiPath: string,
    options?: Options,
    retryPolicy?: RetryPolicy<T>,
    cancellationPolicy?: CancellationPolicy
  ) {
    return this._dbRequest<T>(
      'GET',
      apiPath,
      undefined,
      options,
      retryPolicy,
      cancellationPolicy
    )
  }

  protected patch<T>(apiPath: string, body?: any, options?: Options) {
    return this._dbRequest<T>('PATCH', apiPath, body, options)
  }

  protected post<T>(apiPath: string, body?: any, options?: Options) {
    return this._dbRequest<T>('POST', apiPath, body, options)
  }

  protected put<T>(apiPath: string, body?: any, options?: Options) {
    return this._dbRequest<T>('PUT', apiPath, body, options)
  }

  protected delete<T>(apiPath: string, options?: Options) {
    return this._dbRequest<T>('DELETE', apiPath, undefined, options)
  }

  protected _fetch(url: string, options: any) {
    return fetch(url, options)
  }

  private _fetchWithTimeout(url: string, options: any) {
    return new Promise<any>((resolve, reject) => {
      const timer = setTimeout(
        () => reject(new Error(i18n.t('ServerTimeout'))),
        this.Timeout
      )
      this._fetch(url, options)
        .then(response => resolve(response))
        .catch(error => reject(error))
        .finally(() => clearTimeout(timer))
    })
  }

  protected async _handleResponse<T>(backendResponse: Response) {
    const result: ResponseObject<T> = {
      success: true,
      status: backendResponse.status,
    }

    /** Redirect if not authenticated and URL is set */
    if (backendResponse.status === 401) {
      this.AuthProvider.authorize()
    }
    /** try to parse JSON, if it fails, statusText */
    try {
      const body = await backendResponse.json()
      if (backendResponse.status >= 400 && backendResponse.status < 600) {
        // We expect an `exception` object as the body
        // { reference: number, error: string }
        result.success = false
        result.message = body.error
        result.system = body.system ?? null
      }
      if (body.data) {
        /** handle pagination */
        const { data, ...pagination } = body
        result.data = data
        result.pagination = pagination
      } else {
        if (typeof body === 'object') {
          result.data = body
        } else {
          result.message = body ? body : ''
          result.system = body.system ?? null
        }
      }
    } catch {
      result.internalError = backendResponse.statusText
    }

    if (backendResponse.status < 200 || backendResponse.status > 299) {
      result.success = false
      result.internalError = result.internalError || backendResponse.statusText
    }
    if (backendResponse.status >= 300 && backendResponse.status < 400) {
      result.success = true
    }
    return result
  }

  protected _handleResponseError<T>(error: Error) {
    const ErrorResponse: ResponseObject<T> = {
      success: false,
      internalError: error.message,
    }
    return ErrorResponse
  }
}

export default ApiService
