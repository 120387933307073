import React from 'react'
import './BackdropSpinner.css'
import Spinner from '../Spinner/Spinner'

interface Props {
  show?: boolean
  children: JSX.Element | React.ReactElement
}

const BackdropSpinner: React.FC<Props> = props => {
  const { children } = props
  if (React.isValidElement(children)) {
    return (
      <children.type
        {...children.props}
        className={`${children.props.className || ''} withBackdropSpinner ${
          props.show ? 'loading' : ''
        }`}>
        {children.props.children}
        {props.show ? (
          <div className="BackdropSpinner">
            <Spinner size={80} />
          </div>
        ) : null}
      </children.type>
    )
  } else {
    return null
  }
}
export default BackdropSpinner
