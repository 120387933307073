import ApiService from '../ApiService'
import { CompanyMatch } from '../../interface/CompanyMatch'

class Assignment extends ApiService {
  postAssignment<T>(jobId: number, assignment: T) {
    return this.post<CompanyMatch>(
      '/jobs/' + jobId + '/assignments',
      assignment
    )
  }
  selfAssignment(jobId: number, jobCompanyId: string) {
    return this.post<CompanyMatch>(
      '/jobs/' + jobId + '/company_match/' + jobCompanyId + '/selfassign'
    )
  }
}

export default Assignment
