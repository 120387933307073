import i18n from 'i18next'
import translations from './translations.json'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import config from '../config'

i18n
  .use(
    new LanguageDetector(null, { lookupLocalStorage: 'translation_language' })
  )
  .use(initReactI18next)
  .init({
    resources: {
      // When running UI tests we want the keys to be
      // rendered instead of an actual translation
      ...(config.UI_TESTING ? {} : translations),
    },
    fallbackLng: 'en',
    detection: {
      caches: ['localStorage'],
    },
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  })

export default i18n
