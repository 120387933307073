import ApiService from '../ApiService'
import { MatchingConfig } from '../../interface/MatchingConfig'
import { ResponseObject } from '../../interface/ResponseObject'

export type MatchingConfigSorting = 'created_at:ASC' | 'created_at:DESC'

class MatchingConfigs extends ApiService {
  createConfig(config: MatchingConfig) {
    return this.post<MatchingConfig>(`/matchingconfigs`, config)
  }
  emptyConfig() {
    return Promise.resolve<ResponseObject<MatchingConfig>>({
      success: true,
      data: { name: '', value: '' } as any,
    })
  }
  async emptyConfigFromDefault() {
    const res = await this.getDefaultConfig()
    if (!res.success) {
      return res
    }
    if (!res.data) {
      return res
    }
    res.data.id = undefined
    res.data.current = false
    return res
  }
  getConfig(configId: string) {
    return this.get<MatchingConfig>(`/matchingconfigs/${configId}`)
  }
  getDefaultConfig() {
    return this.get<MatchingConfig>(`/matchingconfigs/default`)
  }
  getConfigs(
    params: { name?: string; sort?: MatchingConfigSorting; size?: number } = {},
    page = 0
  ) {
    const { name, sort, size } = params
    const paramList = [
      page >= 0 ? `page=${encodeURIComponent(page)}` : '',
      name ? `name=${encodeURIComponent(name)}` : '',
      sort ? `sort=${encodeURIComponent(sort)}` : '',
      size ? `size=${encodeURIComponent(size)}` : '',
    ].filter(Boolean)
    const paramFragment = paramList.length ? `?${paramList.join('&')}` : ''
    return this.get<MatchingConfig[]>(`/matchingconfigs${paramFragment}`)
  }
  saveConfig(config: MatchingConfig) {
    if (!config.id) {
      return this.createConfig(config)
    }
    return this.put<MatchingConfig>(`/matchingconfigs/${config.id}`, config)
  }
}

export default MatchingConfigs
