import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import UseTransition from '../../../UI/UseTransition/UseTransition'
import ClusterCompanyAddress from '../ClusterCompanyAddress/ClusterCompanyAddress'
import ClusterCompanyNameForm from '../../ClusterCompanyNameForm/ClusterCompanyNameForm'
import Icon from '../../../UI/Icon/Icon'
import { Modal } from '../../../UI/Modal/Modal'
import {
  ClusterCompany,
  ClusterCompanyNames,
} from '../../../../interface/ClusterCompany'
import { useClusterJobCompanyState } from '../../../../hooks/useClusterJobCompanyState'
import { Address } from '../../../../interface/Address'
import Input from '../../../UI/Input/Input'
import { TooltipHandle } from '../../../UI/TooltipHandle/TooltipHandle'
import useApi from '../../../../hooks/useApi'
import { Cluster } from '../../../../interface/Cluster'
import ClusterJobs from '../../../../services/ClusterJobs/ClusterJobs'
import { ResponseObject } from '../../../../interface/ResponseObject'
import { IS_FEATURE_MOVING_LOCATIONS_BETWEEN_LEADING_COMPANIES_ENABLED } from '../../../../config/features'

const addLocationToLeadingCompany = async (
  jobId: number,
  clusterId: number,
  locationId: number
) => {
  return await new ClusterJobs().addLocationToLeadingCompany(
    jobId,
    clusterId,
    locationId
  )
}

interface Props {
  jobId: number
  clusterId: number
  company: ClusterCompany
  editable: boolean
  showCompanyNameForm: boolean
  jobIsPermittedAndAssigned: boolean
  onAssignmentChanged: (company: ClusterCompany, address?: Address) => void
  onUpdate: (company: ClusterCompany) => void
  unUpdateCluster: (
    cluster: Cluster,
    companyId?: number,
    addressId?: number,
    merge?: boolean
  ) => void
  className: string
  handleShowCompanyNameForm: () => void
}

const LeadingClusterJobCompany: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const {
    jobId,
    clusterId,
    company,
    editable,
    className = '',
    jobIsPermittedAndAssigned,
    handleShowCompanyNameForm,
    showCompanyNameForm = false,
    unUpdateCluster,
  } = props
  const {
    companyAssigned,
    handleAddressSelection,
    handleAddressUpdate,
    handleCompanyNameUpdate,
  } = useClusterJobCompanyState(props)

  const [isExpanded, setIsExpanded] = useState(true)
  const [locationId, setLocationId] = useState<string>('')
  const [error, setError] = useState<ResponseObject<Cluster> | null>(null)

  const toggleContentVisible = useCallback((ev: React.SyntheticEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    setIsExpanded(prev => !prev)
  }, [])

  const handleUpdate = useCallback(
    (companyNames: ClusterCompanyNames) => {
      handleCompanyNameUpdate(companyNames)
      handleShowCompanyNameForm()
    },
    [handleCompanyNameUpdate, handleShowCompanyNameForm]
  )

  const {
    call: addLocation,
    error: addLocationError,
    isLoading: addingLocation,
    response: addedLocationResponse,
  } = useApi<Cluster, number>(
    t,
    'ErrorOccurred',
    useCallback(
      (locationId: number) =>
        addLocationToLeadingCompany(jobId, clusterId, locationId),
      [jobId, clusterId]
    )
  )

  const handleAddLocationId = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      if (ev.currentTarget) {
        if (!isNaN(Number(ev.currentTarget.value))) {
          setLocationId(ev.currentTarget.value)
          setError(null)
        }
      }
    },
    []
  )

  const handleAddLocation = useCallback(() => {
    if (locationId.length) {
      addLocation(+locationId)
    }
  }, [addLocation, locationId])

  const prevUpdateRef = useRef<ResponseObject<Cluster>>()
  useEffect(() => {
    if (
      addedLocationResponse?.success &&
      addedLocationResponse.data &&
      prevUpdateRef.current !== addedLocationResponse
    ) {
      unUpdateCluster(addedLocationResponse.data, undefined, +locationId)
      setLocationId('')
      prevUpdateRef.current = addedLocationResponse
    }
  }, [addedLocationResponse, locationId, unUpdateCluster])

  useEffect(() => {
    if (addLocationError) setError(addLocationError ?? null)
  }, [addLocationError])

  return (
    <div
      data-semantic-id="cluster-leading-company"
      data-semantic-status={companyAssigned ? 'assigned' : 'unassigned'}
      className={`cluster-company primary ${className}`}>
      <Modal
        title={t('LeadingClusterJobCompanyNameModalTitle', {
          companyName: company.name,
        })}
        isOpen={showCompanyNameForm}
        toggle={handleShowCompanyNameForm}
        data-semantic-id={'cluster-leading-company-form-modal'}
        contentClasses={['p-0']}>
        <div
          className="card-body edit-form"
          style={{ minWidth: '800px', minHeight: '400px' }}>
          <ClusterCompanyNameForm
            jobId={jobId}
            clusterId={clusterId}
            company={company}
            onCancel={handleShowCompanyNameForm}
            onUpdate={handleUpdate}
          />
        </div>
      </Modal>

      <TransitionGroup>
        <UseTransition
          name="ClusterCompanyAddresses"
          type="dropdown"
          in={true}
          timeout={300}>
          <div className={'px-5 pt-4 mb-1'}>
            <button
              data-semantic-id="cluster-company-toggle-current-locations"
              className="btn btn-sm"
              onClick={toggleContentVisible}>
              <div className={'d-flex'}>
                <h5 className={'my-0 mr-1'}>{t('FurtherLocations')}</h5>
                {isExpanded ? (
                  <Icon name="chevron-up" className={'my-0 pt-1'} />
                ) : (
                  <Icon name="chevron-down" className={'my-0 pt-1'} />
                )}
              </div>
            </button>
          </div>
        </UseTransition>
        {companyAssigned && isExpanded && (
          <UseTransition
            name="ClusterCompanyAddresses"
            type="dropdown"
            in={true}
            timeout={300}>
            <div
              data-semantic-id="cluster-company-addresses"
              className={'cluster-company-addresses'}>
              {[...(company.additional_addresses ?? [])].map((address, i) => (
                <div
                  className={`${i > 0 ? 'border-top' : ''}`}
                  key={address.id}>
                  <ClusterCompanyAddress
                    jobId={jobId}
                    clusterId={clusterId}
                    company={company}
                    address={address}
                    editable={editable}
                    selectable={false}
                    jobIsPermittedAndAssigned={jobIsPermittedAndAssigned}
                    onAssignmentChanged={handleAddressSelection}
                    onUpdate={handleAddressUpdate}
                    containerClasses={'my-0 pl-4 pr-0'}
                    bodyClasses="pl-4 pr-4"
                    headerClasses={'pl-4 pr-3'}
                    showExternalNumbers
                    modalTitle={t(
                      'LeadingClusterJobCompanyFurtherAddressModalTitle',
                      { companyName: company.name }
                    )}
                  />
                </div>
              ))}
              {IS_FEATURE_MOVING_LOCATIONS_BETWEEN_LEADING_COMPANIES_ENABLED &&
                jobIsPermittedAndAssigned && (
                  <div className={'row no-gutters text-nowrap pl-5 pt-3 w-25'}>
                    <div className="col-9 pl-2">
                      <Input
                        data-semantic-id="cluster-leading-company-insert-location-id"
                        id="addlocationid"
                        label={t('LocationId')}
                        cssClass={'m-0'}
                        slotRight={
                          !error ? (
                            <TooltipHandle
                              data-semantic-id="cluster-leading-company-insert-location-id-tooltip"
                              data-semantic-context={`${clusterId}`}
                              name={`${clusterId}`}
                              label={<Icon name={'info-circle'} />}
                              className={''}>
                              {t(`AddLocationToLeadingCompany`)}
                            </TooltipHandle>
                          ) : (
                            ''
                          )
                        }
                        onChange={handleAddLocationId}
                        value={locationId}
                        disabled={addingLocation || !editable}
                        error={error?.message}
                      />
                    </div>
                    <div className="col-3 mt-1">
                      <button
                        data-semantic-id="cluster-leading-company-add-location"
                        disabled={addingLocation || !locationId || !editable}
                        type="button"
                        onClick={handleAddLocation}
                        className="btn btn-sm btn-secondary ml-3">
                        {t('AddLocation')}
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </UseTransition>
        )}
      </TransitionGroup>
    </div>
  )
}

export default LeadingClusterJobCompany
