import React, { ReactNode, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styles from './Modal.module.scss'

interface ModalProps {
  title?: string
  children?: ReactNode
  isOpen: boolean
  toggle: (ev: React.SyntheticEvent) => void
  'data-semantic-id'?: string
  contentClasses?: string[]
}

export const Modal = (props: ModalProps) => {
  const { title, children, isOpen, toggle, contentClasses } = props
  const portalRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (portalRef.current) {
      return
    }
    portalRef.current = document.querySelector('#modal-portal')
  }, [isOpen])

  useEffect(() => {
    const body = document.body
    if (isOpen) {
      body.classList.add(`${styles.modalOpen}`)
    }
    return () => {
      body.classList.remove(`${styles.modalOpen}`)
    }
  }, [isOpen])

  const customContentClasses = contentClasses?.filter(Boolean).join(' ') || ''

  return isOpen && portalRef.current && children
    ? ReactDOM.createPortal(
        <div data-semantic-id={props['data-semantic-id']}>
          <div className={`${styles.backdrop}`} onClick={toggle} />
          <div
            className={`${styles.container}`}
            aria-modal
            aria-labelledby={title}
            tabIndex={-1}
            role="dialog">
            <div className={`${styles.modal}`}>
              <div className={`${styles.header}`}>
                <div className={`${styles.title}`}>{title}</div>
                <button
                  type="button"
                  className={`${styles.closeButton}`}
                  onClick={toggle}>
                  &times;
                </button>
              </div>
              <div className={`${styles.content} ${customContentClasses}`}>
                {children}
              </div>
            </div>
          </div>
        </div>,
        portalRef.current
      )
    : null
}
