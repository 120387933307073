import React from 'react'
import styles from '../ClusterJobCluster/ClusterJobCluster.module.scss'
import CompanyName from '../../../UI/CompanyName/CompanyName'
import IdRenderer from '../../../UI/IdRenderer/IdRenderer'
import {
  ClusterCompany,
  ClusterCompanyNames,
} from '../../../../interface/ClusterCompany'
import { Cluster } from '../../../../interface/Cluster'
import ClusterJobButtons from '../ClusterJobButtons/ClusterJobButtons'
import StatusRenderer from '../../../UI/StatusRenderer/StatusRenderer'
import ClusterCompanyAddress from '../ClusterCompanyAddress/ClusterCompanyAddress'

import { Address } from '../../../../interface/Address'
import CopyableField from '../../../UI/CopyableField/CopyableField'
import { useClusterJobCompanyState } from '../../../../hooks/useClusterJobCompanyState'
import { useTranslation } from 'react-i18next'

interface Props {
  inputKey: string
  companyCount: number
  leadingCompany: ClusterCompany
  cluster: Cluster
  jobId: number
  isExpanded: boolean
  contentVisible: boolean
  editable: boolean
  isSaving: boolean
  isUnsaving: boolean
  isFinalizing: boolean
  isChecked: boolean
  jobIsPermittedAndAssigned: boolean
  showCompanyNameForm: boolean
  handleSave: () => void
  handleUnsave: () => void
  handleFinalize: () => void
  handleShowCompanyNameForm: () => void
  handleAssignmentChanged: (company: ClusterCompany, address?: Address) => void
  handleLeadingCompanyNameUpdate: (companyNames: ClusterCompanyNames) => void
  updateShowCompanyNameForm: (status: boolean) => void
  toggleContentVisible: (ev: React.SyntheticEvent) => void
}

const ClusterJobStickyHeader: React.FC<Props> = (props: Props) => {
  const {
    inputKey,
    companyCount,
    leadingCompany,
    cluster,
    jobId,
    isExpanded,
    contentVisible,
    editable,
    isSaving,
    isUnsaving,
    isFinalizing,
    isChecked,
    jobIsPermittedAndAssigned,
    showCompanyNameForm,
    handleSave,
    handleUnsave,
    handleFinalize,
    handleShowCompanyNameForm,
    handleAssignmentChanged,
    handleLeadingCompanyNameUpdate,
    toggleContentVisible,
  } = props
  const { t } = useTranslation()

  const { handleAddressSelection, handleAddressUpdate } =
    useClusterJobCompanyState({
      company: cluster.leading_company,
      showCompanyNameForm: showCompanyNameForm,
      onAssignmentChanged: handleAssignmentChanged,
      onUpdate: handleLeadingCompanyNameUpdate,
    })

  if (!isExpanded) return null
  return (
    isExpanded && (
      <div className={`${styles.expandedStickyHead}`}>
        <div
          className={`${styles.expandedHeadMain}`}
          onClick={toggleContentVisible}>
          <span className="badge badge-pill badge-primary badge-wide-custom">
            {companyCount}
          </span>
          <span className="overflow-ellipsis text-nowrap ml-3">
            <CopyableField>
              <CompanyName company={leadingCompany} showId={false} />
            </CopyableField>
          </span>
          <span className="overflow-ellipsis text-nowrap ml-5">
            <CopyableField>
              <IdRenderer id={leadingCompany.id} />
            </CopyableField>
          </span>
          <span className="ml-5">
            <StatusRenderer
              id={leadingCompany.id}
              status={leadingCompany.status}
              size={20}
              yShift={-0.06}
            />
          </span>
          <div
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
            }}
            data-semantic-id="cluster-job-buttons"
            data-editable={editable ? 'true' : undefined}
            className={`ml-auto text-nowrap`}>
            <ClusterJobButtons
              isExpanded={isExpanded}
              companyCount={companyCount}
              leadingCompany={leadingCompany}
              contentVisible={contentVisible}
              editable={editable}
              isSaving={isSaving}
              isUnsaving={isUnsaving}
              isFinalizing={isFinalizing}
              isChecked={isChecked}
              jobIsPermittedAndAssigned={jobIsPermittedAndAssigned}
              showCompanyNameForm={showCompanyNameForm}
              cluster={cluster}
              handleSave={handleSave}
              handleUnsave={handleUnsave}
              handleFinalize={handleFinalize}
              handleShowCompanyNameForm={handleShowCompanyNameForm}
              inputKey={inputKey}
              toggleContentVisible={toggleContentVisible}
            />
          </div>
        </div>
        <div data-semantic-id="cluster-company-main-addresses">
          {cluster.leading_company?.address && (
            <ClusterCompanyAddress
              jobId={jobId}
              clusterId={cluster.id}
              company={cluster.leading_company}
              address={cluster.leading_company.address}
              editable={
                editable &&
                !cluster.readonly &&
                !cluster.finalized &&
                !cluster.leading_company.readonly
              }
              selectable={false}
              jobIsPermittedAndAssigned={jobIsPermittedAndAssigned}
              onAssignmentChanged={handleAddressSelection}
              onUpdate={handleAddressUpdate}
              showExternalNumbers
              headerClasses={'pl-4 pr-3'}
              modalTitle={t('LeadingClusterJobCompanyAddressModalTitle', {
                companyName: cluster.leading_company.name,
              })}
            />
          )}
        </div>
      </div>
    )
  )
}

export default ClusterJobStickyHeader
