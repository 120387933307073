import React, { useCallback } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { useTranslation } from 'react-i18next'

import UseTransition from '../../../UI/UseTransition/UseTransition'
import ClusterCompanyAddress from '../ClusterCompanyAddress/ClusterCompanyAddress'
import AddressInline from '../../../UI/AddressInline/AddressInline'
import { Address } from '../../../../interface/Address'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import {
  ClusterCompany,
  ClusterCompanyNames,
} from '../../../../interface/ClusterCompany'
import CompanyName from '../../../UI/CompanyName/CompanyName'
import Icon from '../../../UI/Icon/Icon'
import ClusterCompanyNameForm from '../../ClusterCompanyNameForm/ClusterCompanyNameForm'
import CompanyInfo from '../../../UI/CompanyInfo/CompanyInfo'
import { TooltipHandle } from '../../../UI/TooltipHandle/TooltipHandle'
import CompanyError from '../../../UI/CompanyError/CompanyError'
import { MergeComment } from '../../../UI/MergeComment/MergeComment'
import StatusRenderer from '../../../UI/StatusRenderer/StatusRenderer'
import { useClusterJobCompanyState } from '../../../../hooks/useClusterJobCompanyState'
import { Modal } from '../../../UI/Modal/Modal'
import { Company } from '../../../../interface/Company'
import useModal from '../../../../hooks/useModal'
import { MergeAddressesModal } from '../../../ChangeMergeAddressesModal/MergeAddressesModal'
import { createAddressId } from '../../ClusterState/ClusterState'

interface Props {
  jobId: number
  clusterId: number
  company: ClusterCompany
  leadingCompany: Company
  editable: boolean
  hideAddress?: boolean
  showCompanyNameForm: boolean
  onAssignmentChanged: (company: ClusterCompany, address?: Address) => void
  onLeadingCompanyChanged: (company: ClusterCompany) => void
  onUpdate: (company: ClusterCompany) => void
  onSetAddressChangeMapping: (mergeIds: string[], targetId: string) => void
  jobIsPermittedAndAssigned: boolean
  selectable: boolean
  mergeable: boolean
  isSaving: boolean
  leadingCompanyChangeable: boolean
  className: string
  showMergeTargetButton?: boolean
}

const ClusterJobCompany: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const {
    jobId,
    clusterId,
    company,
    leadingCompany,
    editable,
    selectable = true,
    mergeable = true,
    jobIsPermittedAndAssigned,
    leadingCompanyChangeable,
    hideAddress,
    className = '',
    isSaving,
    showMergeTargetButton = false,
  } = props

  const {
    companyAssigned,
    handleAddressSelection,
    handleAddressUpdate,
    handleCompanyNameUpdate,
    handleCompanySelection,
    handleChangeLeadingCompany,
    handleSetAddressChangeMapping,
    addressChangeMapping,
  } = useClusterJobCompanyState(props)

  const { isOpen: mergeModalOpen, toggle: toggleMergeModal } = useModal()
  const { isOpen: companyNameModalOpen, toggle: toggleCompanyNameModal } =
    useModal()

  const inputKey = `clustercompany-included-j${jobId}-cl${clusterId}-co${company.id}`

  const leadingCompanyAddresses = [
    ...(leadingCompany.address ? [leadingCompany.address] : []),
    ...(leadingCompany.additional_addresses ?? []),
  ]

  const companyAddresses = [
    ...(company.address ? [company.address] : []),
    ...(company.additional_addresses ?? []),
  ]

  const companyAddressIds = companyAddresses.map(address => {
    return createAddressId(company.id, address.id)
  })

  const mergeTargetIds = companyAddressIds.map(id => {
    return +addressChangeMapping[id]
  })

  const showMergeTarget =
    Math.min(...mergeTargetIds) === Math.max(...mergeTargetIds)
  const mergeTargetId = mergeTargetIds[0]

  const handleSubmitCompanyNameUpdate = useCallback(
    (companyNames: ClusterCompanyNames) => {
      handleCompanyNameUpdate(companyNames)
      toggleCompanyNameModal()
    },
    [handleCompanyNameUpdate, toggleCompanyNameModal]
  )

  return (
    <div
      data-semantic-id="cluster-company"
      data-semantic-status={companyAssigned ? 'assigned' : 'unassigned'}
      className={`cluster-company ${className}`}>
      <div
        data-semantic-id="cluster-company-header"
        className={`d-flex align-items-center`}>
        {jobIsPermittedAndAssigned ? (
          leadingCompanyChangeable ? (
            <button
              data-semantic-id="change-leading-company"
              className={`btn btn-sm py-2 ${
                isSaving || !editable ? 'disabled opacity-25' : ''
              }`}
              onClick={handleChangeLeadingCompany}
              disabled={isSaving || !editable}
              type={'button'}>
              <Icon name={'chevron-bar-up'} title={t('ChangeLeadingCompany')} />
            </button>
          ) : (
            <TooltipHandle
              data-semantic-id="change-leading-company-tooltip"
              data-semantic-context={company.name}
              name={company.name}
              label={
                <Icon
                  name={'chevron-bar-up'}
                  title={t('ChangeLeadingCompany')}
                />
              }
              xShiftTooltip={40}
              className={'btn btn-sm py-2 disabled opacity-25'}>
              {t(`ChangeLeadingCompanyForbidden`)}
            </TooltipHandle>
          )
        ) : (
          <div className={'p-2'} />
        )}

        {jobIsPermittedAndAssigned && selectable ? (
          <Checkbox
            data-semantic-id="cluster-company-toggle"
            inputKey={inputKey}
            onChange={handleCompanySelection}
            checked={companyAssigned}
            disabled={!mergeable}
            inputClasses={['position-relative', 'ml-1']}>
            <CompanyName company={company} showSource showId />
          </Checkbox>
        ) : (
          <CompanyName company={company} showSource showId />
        )}
        <StatusRenderer
          id={company.id}
          status={company.status}
          className={'pl-1'}
          yShift={-0.02}
        />
        <CompanyInfo
          inputKey={inputKey}
          company={company}
          className={'ml-2 m-1 p-0 border-0'}
        />
        <CompanyError
          inputKey={inputKey}
          company={company}
          className={'m-1 p-0 border-0'}
        />
        {jobIsPermittedAndAssigned ? (
          <button
            data-semantic-id="cluster-company-address-edit"
            disabled={!companyAssigned || !editable}
            style={{ visibility: companyAssigned ? 'visible' : 'hidden' }}
            onClick={toggleCompanyNameModal}
            className={`btn btn-sm m-1 p-0 border-0 ${
              !companyAssigned || !editable ? 'disabled opacity-25' : ''
            }`}>
            <Icon name="pencil" />
          </button>
        ) : (
          <div className={'m-1 p-0 border-0'} />
        )}
        {showMergeTargetButton &&
          jobIsPermittedAndAssigned &&
          companyAssigned && (
            <button
              data-semantic-id="cluster-company-change-merge-target"
              disabled={!mergeable || !editable}
              onClick={toggleMergeModal}
              className={`btn btn-sm m-1 p-0 border-0 ${
                !mergeable || !editable ? 'disabled opacity-25' : ''
              }`}
              title="">
              <Icon name="intersect" className="ml-1" />
            </button>
          )}
        {!hideAddress && company.address && (
          <div className="tag">
            <AddressInline
              address={company.address}
              errors={company.address_errors}
            />
          </div>
        )}
        {companyAssigned && (
          <MergeComment
            data-semantic-id="cluster-company-merge-comment"
            company={company}
            isAssigned
            showMergeTarget={showMergeTarget}
            mergeTargetId={mergeTargetId}
            type={'Company'}
            className={'m-1 p-0 border-0'}
          />
        )}
        <MergeAddressesModal
          title={t('MergeAddressesModalCompanyLevelTitle')}
          isOpen={mergeModalOpen}
          toggle={toggleMergeModal}
          leadingCompanyAddresses={leadingCompanyAddresses}
          mergeIds={companyAddressIds}
          addressChangeMapping={addressChangeMapping}
          handleSetAddressChangeMappings={handleSetAddressChangeMapping}
        />
      </div>
      <Modal
        title={t('ClusterJobCompanyNameModalTitle', {
          companyName: company.name,
        })}
        isOpen={companyNameModalOpen}
        toggle={toggleCompanyNameModal}
        data-semantic-id={'cluster-job-company-address-modal'}
        contentClasses={['p-0']}>
        {companyNameModalOpen && (
          <div
            className="card-body edit-form pl-5"
            style={{ minWidth: '820px', minHeight: '400px' }}>
            <ClusterCompanyNameForm
              jobId={jobId}
              clusterId={clusterId}
              company={company}
              onCancel={toggleCompanyNameModal}
              onUpdate={handleSubmitCompanyNameUpdate}
            />
          </div>
        )}
      </Modal>
      <TransitionGroup>
        <UseTransition
          name="ClusterCompanyAddresses"
          type="dropdown"
          in={true}
          timeout={300}>
          <div
            data-semantic-id="cluster-company-addresses"
            className="cluster-company-addresses">
            {companyAddresses.map(address => (
              <div key={address.id}>
                <ClusterCompanyAddress
                  jobId={jobId}
                  clusterId={clusterId}
                  company={company}
                  address={address}
                  editable={editable}
                  mergeable={mergeable}
                  selectable={true}
                  companyAssigned={companyAssigned}
                  jobIsPermittedAndAssigned={jobIsPermittedAndAssigned}
                  onAssignmentChanged={handleAddressSelection}
                  onUpdate={handleAddressUpdate}
                  headerClasses="pl-5 pr-4"
                  bodyClasses="pl-4 pr-4"
                  showMergeComments
                  modalTitle={t('ClusterJobCompanyAddressModalTitle', {
                    companyName: company.name,
                  })}
                  showMergeAddress={!showMergeTarget}
                  leadingCompanyAddresses={leadingCompanyAddresses}
                  onSetAddressChangeMapping={handleSetAddressChangeMapping}
                  showMergeTargetButton
                />
              </div>
            ))}
          </div>
        </UseTransition>
      </TransitionGroup>
    </div>
  )
}

export default ClusterJobCompany
