import React from 'react'
import { Company } from '../../../interface/Company'

interface Props {
  company: Company
  className?: string
}

const CompanySource: React.FC<Props> = (props: Props) => {
  const { company, className } = props
  const { source, database } = company
  if (!source && !database) return null

  const displayedSource: string =
    database === 'CMD' && source
      ? source
      : database && source
      ? `${database}:${source}`
      : database && !source
      ? database
      : ''

  const cssClasses: string[] = []

  if (className && className) {
    cssClasses.push(className)
  }

  if (!displayedSource) return null
  return (
    <span
      className={`badge badge-pill badge-company-source
      ${cssClasses.join(' ')}`}>
      {displayedSource}
    </span>
  )
}

export default CompanySource
