import { UserPermission } from './UserPermission'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assertUnreachable(_: never): never {
  throw new Error('Boom!')
}

export interface JWTPayload {
  nonce: string
  aud: string
  roles: UserPermission[]
  preferred_username?: string
  preferred_given_name?: string
  preferred_family_name?: string
  sub: string
}

export enum Capability {
  AccessAdminMenu,
  AccessApp,
  DeleteClusterjobs,
  DeleteImportjobs,
  ReadAppConfig,
  ReadMatchingConfig,
  WriteAppConfig,
  WriteMatchingConfig,
  WriteClusterjobs,
  WriteImportjobs,
}

export interface UserCapabilityProvider {
  can(capability: Capability): boolean
}

export interface UserNameProvider {
  userName: string
}

export interface UserInfo extends UserCapabilityProvider, UserNameProvider {}

export const defaultUserInfo: UserInfo = {
  get userName(): string {
    return ''
  },
  can(): boolean {
    return false
  },
}

export class JWTUserInfo implements UserInfo {
  readonly _permissionLookup: Set<UserPermission>
  readonly _preferred_name: string

  constructor(payload: JWTPayload | null) {
    this._permissionLookup = new Set(payload?.roles ?? [])

    let fullName: string | null = null

    if (payload?.preferred_family_name && payload?.preferred_given_name) {
      fullName =
        payload?.preferred_given_name + ' ' + payload?.preferred_family_name
    }

    this._preferred_name =
      fullName ?? payload?.preferred_username ?? payload?.sub ?? ''
  }

  get userName() {
    return this._preferred_name
  }

  can = (capability: Capability) => {
    switch (capability) {
      case Capability.AccessAdminMenu:
        return (
          this._permissionLookup.has('service:si4ce:appconfig:read') ||
          this._permissionLookup.has('service:si4ce:matchingconfig:read')
        )

      case Capability.AccessApp:
        return this._permissionLookup.has('service:si4ce:access')

      case Capability.ReadAppConfig:
        return this._permissionLookup.has('service:si4ce:appconfig:read')

      case Capability.ReadMatchingConfig:
        return this._permissionLookup.has('service:si4ce:matchingconfig:read')

      case Capability.WriteAppConfig:
        return this._permissionLookup.has('service:si4ce:appconfig:write')

      case Capability.WriteMatchingConfig:
        return this._permissionLookup.has('service:si4ce:matchingconfig:write')

      case Capability.WriteClusterjobs:
        return this._permissionLookup.has(
          'service:si4ce:clusterjobs:read:write'
        )

      case Capability.WriteImportjobs:
        return this._permissionLookup.has('service:si4ce:importjobs:read:write')

      case Capability.DeleteClusterjobs:
        return this._permissionLookup.has('service:si4ce:clusterjobs:delete')

      case Capability.DeleteImportjobs:
        return this._permissionLookup.has('service:si4ce:importjobs:delete')

      default:
        assertUnreachable(capability)
    }
  }
}
