import React, { ReactNode } from 'react'

interface Props {
  children?: ReactNode
  percentage?: number
  stroke?: string
}

// Inspired by https://sparkbox.com/foundry/how_to_code_an_SVG_pie_chart
export const CircleSectionChart: React.FC<Props> = (props: Props) => {
  const percentage = props.percentage ?? 100

  // Calculations in code since Firefox doesn't support calc() on
  // number-valued properties.
  // See https://bugzilla.mozilla.org/show_bug.cgi?id=594933
  const magicNumber = 31.42
  const internalStrokeDash = (percentage * magicNumber) / 100
  return (
    <div
      data-semantic-id="progresschart"
      className="position-relative"
      data-percentage={percentage}
      data-stroke={props.stroke}>
      <svg viewBox="0 0 20 20">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#00e6dc' }} />
            <stop offset="100%" style={{ stopColor: '#00ffb9' }} />
          </linearGradient>
        </defs>
        <circle
          r="10"
          cx="10"
          cy="10"
          fill="#333353"
          stroke="white"
          strokeWidth="0.1"
        />
        <circle
          data-semantic-id="progresschart-indicator"
          r="5"
          cx="10"
          cy="10"
          fill="black"
          stroke={props.stroke ? props.stroke : 'url(#gradient)'}
          strokeWidth="10"
          strokeDasharray={`${internalStrokeDash} ${magicNumber}`}
          transform="rotate(-90) translate(-20)"
        />
        <circle r="9" cx="10" cy="10" fill="white" />
      </svg>
      {props.children && (
        <div
          className="position-absolute d-flex align-items-center justify-content-center"
          style={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <div className="text-center">{props.children}</div>
        </div>
      )}
    </div>
  )
}
