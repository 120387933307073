import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Address,
  AddressError,
  ExternalNumber,
} from '../../../interface/Address'
import IdRenderer from '../IdRenderer/IdRenderer'
import Icon from '../Icon/Icon'
import StatusRenderer from '../StatusRenderer/StatusRenderer'

interface Props {
  address: Address
  errors?: AddressError[] | null | undefined
  showId?: boolean
  idPrefix?: string
  showExternalNumbers?: boolean
  fallbackIcon?: ReactNode
  showStatusIcon?: boolean
}

interface WrapperProps {
  title?: string
  key?: string
  ['data-semantic-role']?: string
  className?: string
}

const AddressInline = (props: Props) => {
  const {
    address,
    errors,
    fallbackIcon,
    idPrefix = '',
    showExternalNumbers,
    showId = true,
    showStatusIcon = false,
  } = props
  const { t } = useTranslation()
  const hasIcon = address.primary || fallbackIcon
  const show = (
    key: keyof Address,
    delimiter?: string,
    localKey?: keyof Address
  ): '' | 0 | false | JSX.Element => {
    const keyHasError = (errors ?? []).find(field => field.field_name === key)

    const internationalValue = address[key] ?? ''
    const nationalValue = localKey ? address[localKey] : false
    const value = internationalValue
      ? internationalValue
      : nationalValue
      ? nationalValue
      : ''
    const wrapperProps: WrapperProps = {}

    if (nationalValue && localKey) {
      wrapperProps.className = 'local'
      wrapperProps.title = `${t('LocalName')}: ${nationalValue}`
    }
    if (keyHasError) {
      wrapperProps.key = `text${key}`
      wrapperProps['data-semantic-role'] = 'error'
      wrapperProps.className = 'text-danger'
    }
    return (
      value && (
        <>
          <span {...wrapperProps}>{value as any}</span>
          {delimiter}
          {delimiter ? ' ' : ''}
        </>
      )
    )
  }
  return (
    <>
      {address.primary ? (
        <Icon
          name="house-door"
          title={t('PrimaryAddress')}
          className="pr-2 opacity-50"
        />
      ) : (
        fallbackIcon
      )}
      {show('street', ',', 'street_nat')}
      {show('zip', ',')}
      {show('city', '', 'city_nat')}
      {(address.state || address.country) && (
        <>
          {' '}
          {'('}
          {show('state', ',')}
          {show('country')}
          {')'}
        </>
      )}{' '}
      {showId && <IdRenderer id={address.id} prefix={idPrefix} />}
      {showStatusIcon && (
        <StatusRenderer
          id={address.id}
          status={address.status}
          yShift={-0.14}
          className={'pl-1'}
        />
      )}
      {showExternalNumbers && address.external_numbers && (
        <div className={`addressIds ${hasIcon ? 'ml-4' : ''}`}>
          {address.external_numbers.map((item: ExternalNumber) => (
            <span
              className="badge badge-pill badge-primary-accent px-3 py-1 mr-1"
              key={item.id}>
              {item.type} {item.number} {item.system}
            </span>
          ))}
        </div>
      )}
    </>
  )
}

export default AddressInline
