import React, { useContext } from 'react'
import CompanyInfo from '../../UI/CompanyInfo/CompanyInfo'
import CompanyError from '../../UI/CompanyError/CompanyError'
import { Capability } from '../../../services/Auth/UserInfo'
import Icon from '../../UI/Icon/Icon'
import { Link } from 'react-router-dom'
import { JobOrigin } from '../../../interface/Job'
import { useTranslation } from 'react-i18next'
import { Company } from '../../../interface/Company'
import { UserContext } from '../../../contexts/UserContext'

const joinCompanyParameters = (company: Company): string => {
  const { name, address, additional_addresses } = company
  const country = address?.country ?? additional_addresses?.[0]?.country
  const street = address?.street ?? additional_addresses?.[0]?.street
  const city = address?.city ?? additional_addresses?.[0]?.city
  const paramList = [
    name ? `company_name=${encodeURIComponent(name)}` : null,
    street ? `street=${encodeURIComponent(street)}` : null,
    city ? `city=${encodeURIComponent(city)}` : null,
    country ? `country=${encodeURIComponent(country)}` : null,
  ].filter(Boolean)
  return paramList.length ? `?${paramList.join('&')}` : ''
}

interface Props {
  jobOrigin: JobOrigin
  editable: boolean
  isAssigned: boolean
  jobCompany: Company
  dataSetEdit: boolean
  dataSetExpanded: boolean
  toggleEdit: (
    event: React.MouseEvent<
      HTMLButtonElement | HTMLTableDataCellElement,
      MouseEvent
    >
  ) => void
  toggleExpanded: (
    event: React.MouseEvent<
      HTMLButtonElement | HTMLTableDataCellElement,
      MouseEvent
    >
  ) => void
  'data-semantic-id'?: string
  cssClass?: string
}

const JobCompanyHeaderButtons: React.FC<Props> = (props: Props) => {
  const {
    editable,
    isAssigned,
    jobCompany,
    dataSetEdit,
    dataSetExpanded,
    toggleEdit,
    toggleExpanded,
    cssClass,
  } = props

  const { t } = useTranslation()
  const { can } = useContext(UserContext)
  const inputKey = `jch-${jobCompany.id}`

  return (
    <div
      data-semantic-id={props['data-semantic-id']}
      className={cssClass}
      data-editable={editable ? 'true' : undefined}>
      <CompanyInfo
        inputKey={inputKey}
        company={jobCompany}
        className={'mx-1 my-0 p-0 border-0'}
      />
      <CompanyError
        inputKey={inputKey}
        company={jobCompany}
        className={'mx-1 my-0 p-0 border-0'}
      />
      {can(Capability.WriteImportjobs) && isAssigned && editable ? (
        <button
          data-semantic-id="import-job-edit"
          className={`btn btn-sm mx-1 my-0 p-0 border-0 ${
            dataSetEdit ? 'active' : ''
          } ${dataSetExpanded || dataSetEdit ? 'btn-link-primary-custom' : ''}`}
          onClick={event => toggleEdit(event)}
          title={t('Edit')}>
          <Icon name="pencil" />
        </button>
      ) : (
        <div className={'btn btn-sm mx-1 my-0 p-0 border-0'} />
      )}
      {can(Capability.WriteClusterjobs) && isAssigned ? (
        <Link
          data-semantic-id="create-cluster-job"
          className={`mx-1 my-0 p-0 border-0`}
          style={{ verticalAlign: 'middle', textAlign: 'center' }}
          to={'/clusterjobs/new' + joinCompanyParameters(jobCompany)}>
          <Icon
            name="bounding-box-circles"
            title={t('CreateClusterJobTooltip')}
          />
        </Link>
      ) : (
        <a className={'mx-1 my-0 p-0 border-0'} />
      )}
      {/*
        ToDo: implementation of deletion of import jobs and authorization check
        service:si4ce:importjobs:delete
      */}
      <button
        data-semantic-id="import-job-toggle"
        className={`btn btn-sm mx-1 my-0 p-0 border-0 ${
          dataSetExpanded || dataSetEdit ? 'btn-link-primary-custom' : ''
        }`}
        onClick={event => toggleExpanded(event)}>
        {dataSetExpanded ? (
          <Icon name="chevron-up" />
        ) : (
          <Icon name="chevron-down" />
        )}
      </button>
    </div>
  )
}

export default JobCompanyHeaderButtons
