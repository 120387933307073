import React from 'react'
import styles from '../JobCompany/JobCompany.module.scss'
import CompanyName from '../../UI/CompanyName/CompanyName'
import { CompanyLocation } from '../../UI/CompanyLocation/CompanyLocation'
import Icon from '../../UI/Icon/Icon'
import { CompanyMatch } from '../../../interface/CompanyMatch'
import { JobOrigin } from '../../../interface/Job'
import { Company } from '../../../interface/Company'
import { MasterDataCompany } from '../../../interface/MasterDataCompany'
import { ResponseObject } from '../../../interface/ResponseObject'
import JobCompanyHeaderButtons from '../GspDedupJobButtons/JobCompanyHeaderButtons'
import CopyableField from '../../UI/CopyableField/CopyableField'
import IdRenderer from '../../UI/IdRenderer/IdRenderer'

interface Props {
  jobOrigin: JobOrigin
  editable: boolean
  isAssigned: boolean
  jobCompany: Company
  matches: MasterDataCompany[] | null
  dataSetEdit: boolean
  dataSetExpanded: boolean
  assignedCompany: MasterDataCompany | null
  loading?: boolean
  error?: ResponseObject<CompanyMatch>
  toggleEdit: (
    event: React.MouseEvent<
      HTMLButtonElement | HTMLTableDataCellElement,
      MouseEvent
    >
  ) => void
  toggleExpanded: (
    event: React.MouseEvent<
      HTMLButtonElement | HTMLTableDataCellElement | HTMLDivElement,
      MouseEvent
    >
  ) => void
}

const GspDedupJobStickyHeader: React.FC<Props> = (props: Props) => {
  const {
    jobCompany,
    assignedCompany,
    matches,
    toggleExpanded,
    dataSetExpanded,
    dataSetEdit,
    jobOrigin,
    editable,
    isAssigned,
    toggleEdit,
  } = props

  const idPrefix = jobOrigin === 'IMPORT_WIZARD' ? 'IW_' : ''
  const isExpanded = dataSetExpanded || dataSetEdit
  const assignedId = assignedCompany ? assignedCompany.id : null
  const selectedMatch = matches?.find(m => m.id === assignedId)
  const selectedMatchPercentage = selectedMatch?.match_percentage ?? 0
  const selectedMatchCss = []

  if (selectedMatchPercentage >= 90) {
    selectedMatchCss.push('text-success')
  } else if (selectedMatchPercentage >= 80) {
    selectedMatchCss.push('text-warning')
  } else if (selectedMatchPercentage > 0) {
    selectedMatchCss.push('text-danger')
  }

  if (!isExpanded) return null
  return (
    isExpanded && (
      <div className={`${styles.expandedStickyHead} `}>
        <div className={`${styles.expandedHeadMain}`} onClick={toggleExpanded}>
          <div style={{ width: '3rem' }}>
            <span className="badge badge-pill badge-primary badge-wide-custom">
              {matches?.length}
            </span>
          </div>

          <div
            className="overflow-ellipsis text-nowrap ml-4"
            style={{ width: '20%', paddingLeft: '20px' }}>
            <CopyableField>
              <CompanyName
                company={jobCompany}
                idPrefix={idPrefix}
                showId={false}
              />
            </CopyableField>
          </div>
          <div
            className="d-flex overflow-ellipsis text-nowrap ml-3 pl-2 pr-3"
            style={{ width: '50%' }}>
            <CopyableField>
              <CompanyLocation
                idPrefix={idPrefix}
                company={jobCompany}
                assignedCompany={assignedCompany}
                showAddress
                showId={false}
                showTooltip
                overflow={true}
              />
              {jobCompany?.address !== null && (
                <>
                  <Icon name="chevron-right" className="px-2" />
                  <IdRenderer id={jobCompany.id} prefix={idPrefix} />
                </>
              )}
            </CopyableField>
          </div>
          {selectedMatch && (
            <div
              className={`ml-1 ${selectedMatchCss.join(' ')}`}
              style={{ width: '6rem' }}>
              <Icon name="circle-fill">
                {`${selectedMatch.match_percentage?.toFixed(1)}%`}
              </Icon>
            </div>
          )}
          <div className={'ml-auto'}>
            <JobCompanyHeaderButtons
              data-semantic-id={'job-company-header-buttons-sticky'}
              cssClass={`text-nowrap align-items-center ${
                dataSetExpanded || dataSetEdit ? '' : 'opacity-50'
              }`}
              jobOrigin={jobOrigin}
              editable={editable}
              isAssigned={isAssigned}
              jobCompany={jobCompany}
              dataSetEdit={dataSetEdit}
              dataSetExpanded={dataSetExpanded}
              toggleEdit={toggleEdit}
              toggleExpanded={toggleExpanded}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default GspDedupJobStickyHeader
