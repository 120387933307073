import ApiService, { CancellationPolicy, RetryPolicy } from '../ApiService'
import config from '../../config'
import { Job, JobStatus } from '../../interface/Job'

export type ImportJobSorting = 'created_at:ASC' | 'created_at:DESC'

interface ImportJobsParams {
  status?: JobStatus[]
  external_id?: string
  sort?: ImportJobSorting
  size?: number
}

export const createGspLink = (id: string) => `${config.GSP_URL_PREFIX}${id}`

class Jobs extends ApiService {
  _determineNextRetryIntervalMs(attempt: number) {
    if (attempt === 0) {
      return 0
    }
    return config.IMPORT_STATUS_RETRY_INTERVAL
  }

  restartJob(jobId: number) {
    return this.post<Job>(`/jobs/${jobId}/restart`)
  }

  getJobs(page = 0, params: ImportJobsParams = {}) {
    const { status, external_id, size, sort } = params
    const paramList = [
      page >= 0 ? `page=${encodeURIComponent(page)}` : '',
      status?.length
        ? status.map(status => `status=${encodeURIComponent(status)}`).join('&')
        : null,
      external_id ? `external_id=IW-${external_id}` : null,
      sort ? `sort=${encodeURIComponent(sort)}` : null,
      size ? `size=${encodeURIComponent(size)}` : null,
    ].filter(Boolean)
    const paramFragment = paramList.length ? `?${paramList.join('&')}` : ''
    return this.get<Job[]>(`/jobs${paramFragment}`)
  }

  getJob(
    jobId: string | number,
    retryPolicy?: RetryPolicy<Job>,
    cancellationPolicy?: CancellationPolicy
  ) {
    return this.get<Job>(
      `/jobs/${jobId}`,
      undefined,
      retryPolicy,
      cancellationPolicy
    )
  }
  resetJob(jobId: number, matchingConfigId?: number) {
    return this.post(`/jobs/${jobId}/reset_matching`, {
      matching_configuration_id: matchingConfigId,
    })
  }

  async finalizeJob(jobId: number) {
    const response = await this.getJob(jobId)
    if (!response.success || !response.data) {
      return response
    }
    const upToDateJob = response.data
    if (!upToDateJob.finalizable) {
      response.success = false
      response.message = 'ImportJobNotFinalizable'
      delete response.data
      return response
    }
    return this.post(`/jobs/${jobId}/finalize`)
  }

  async addAssigneeToJob(jobId: number, assignee: string) {
    const jobResponse = await this.getJob(jobId)
    if (!jobResponse.success || !jobResponse.data) {
      return jobResponse
    }

    if (
      jobResponse.data.status === 'ASSIGNMENT_DONE' ||
      jobResponse.data.status === 'EXPIRED'
    ) {
      jobResponse.success = false
      jobResponse.message =
        jobResponse.data.status === 'ASSIGNMENT_DONE'
          ? 'FinalizedJob'
          : 'ExpiredJob'
      delete jobResponse.data
      return jobResponse
    }

    return this.patch<Job>(`/jobs/${jobId}`, {
      assignee: assignee,
    })
  }

  async cancelJob(jobId: number) {
    return this.post<Job>(`/jobs/${jobId}/cancel`)
  }

  async expireJob(jobId: number) {
    return this.post<Job>(`/jobs/${jobId}/expire`)
  }
}

export default Jobs
