import React from 'react'
import { useTranslation } from 'react-i18next'
import { MasterDataCompany } from '../../../../interface/MasterDataCompany'
import { Address } from '../../../../interface/Address'
import { MasterDataCompanyRow } from './MasterDataCompanyRow/MasterDataCompanyRow'

interface Props {
  data: MasterDataCompany[] | null
  JobCompanyId: string
  assignedId?: string | undefined | null
  defaultAddress?: Address | null
  onSelectCompany: (id: string) => void
  selectable: boolean
  jobIsPermittedAndAssigned: boolean
}

const MasterDataCompanies: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  if (!props.data) return null

  const sortedData = props.data
    .filter(item => props.assignedId && props.assignedId === item.id)
    .concat(
      props.data.filter(
        item => !(props.assignedId && props.assignedId === item.id)
      )
    )

  if (!sortedData.length) return null

  return (
    <table className="table table-striped-custom m-0 bg-white">
      <thead>
        <tr>
          {/* If number of cols is changed, adapt colSpan={7} colSpan={4} within MasterDataCompanyRow accordingly */}
          <th className="text-nowrap" style={{ width: '36px' }}></th>
          <th className="w-10 text-nowrap pl-1">{t('MatchProbability')}</th>
          <th className="w-10 text-nowrap">{t('Source')}</th>
          <th className="text-nowrap">{t('Company')}</th>
          <th className="text-nowrap">{t('CompanyId')}</th>
          <th className="text-nowrap" />
          <th className="text-nowrap" />
          <th className="text-nowrap">{t('Location')}</th>
          <th className="w-10 text-nowrap">{t('LocationId')}</th>
          <th className="text-nowrap" />
          <th className="text-nowrap" style={{ width: '36px' }}></th>
        </tr>
      </thead>
      <tbody>
        {sortedData.map((item, index) => (
          <MasterDataCompanyRow
            key={index}
            index={index}
            company={item}
            JobCompanyId={props.JobCompanyId}
            selected={
              props.assignedId && props.assignedId === item.id ? true : false
            }
            selectable={props.selectable}
            defaultAddress={props.defaultAddress}
            onSelect={props.onSelectCompany}
            jobIsPermittedAndAssigned={props.jobIsPermittedAndAssigned}
          />
        ))}
      </tbody>
    </table>
  )
}

export default MasterDataCompanies
