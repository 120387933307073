import React from 'react'
import config from '../../../config'
import './Logo.scss'

const Logo: React.FC = () => {
  if (!config.CUSTOMER_LOGO && !config.CUSTOMER_CLAIM) return null
  return (
    <div className="logo d-flex mr-2 flex-column align-items-start">
      {config.CUSTOMER_LOGO && (
        <img
          src={config.CUSTOMER_LOGO}
          referrerPolicy="no-referrer"
          alt="Siemens"
        />
      )}
      {config.CUSTOMER_CLAIM && (
        <img
          src={config.CUSTOMER_CLAIM}
          referrerPolicy="no-referrer"
          alt={config.CUSTOMER_CLAIM_TEXT}
        />
      )}
    </div>
  )
}
export default Logo
