import React, { Component, MouseEvent, SyntheticEvent } from 'react'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'
import { Company } from '../../../interface/Company'
import { MasterDataCompany } from '../../../interface/MasterDataCompany'
import { ResponseObject } from '../../../interface/ResponseObject'
import { CompanyMatch } from '../../../interface/CompanyMatch'
import { JobOrigin } from '../../../interface/Job'
import CompanyAddressService, {
  AddressDto,
} from '../../../services/CompanyAddress/CompanyAddressService'
import AssignmentService from '../../../services/Assignment/Assignment'
import AdditionalSearchService from '../../../services/AdditionalSearch/AdditionalSearch'
import CompanyName from '../../UI/CompanyName/CompanyName'
import { CompanyLocation } from '../../UI/CompanyLocation/CompanyLocation'
import Icon from '../../UI/Icon/Icon'
import JobCompanyHeaderButtons from '../GspDedupJobButtons/JobCompanyHeaderButtons'
import styles from './JobCompany.module.scss'
import GspDedupJobStickyHeader from '../GspDedupJobStickyHeader/GspDedupJobStickyHeader'
import { TransitionGroup } from 'react-transition-group'
import UseTransition from '../../UI/UseTransition/UseTransition'
import CompanyAddressForm from '../../UI/CompanyAddressForm/CompanyAddressForm'
import { Capability, UserInfo } from '../../../services/Auth/UserInfo'
import BootstrapAlert from '../../UI/BootstrapAlert/BootstrapAlert'
import BackdropSpinner from '../../UI/BackdropSpinner/BackdropSpinner'
import MasterDataCompanies from './MasterDataCompanies/MasterDataCompanies'
import { UserContext } from '../../../contexts/UserContext'
import { TooltipHandle } from '../../UI/TooltipHandle/TooltipHandle'
import Input from '../../UI/Input/Input'
import CopyableField from '../../UI/CopyableField/CopyableField'
import IdRenderer from '../../UI/IdRenderer/IdRenderer'
import { CMDSearchSplitButton } from '../../UI/CMDSearch/CMDSearchSplitButton'
import { Modal } from '../../UI/Modal/Modal'

interface State {
  jobCompany: Company | null
  matches: MasterDataCompany[] | null
  assignedCompany: MasterDataCompany | null
  dataSetEdit: boolean
  dataSetExpanded: boolean
  loading?: boolean
  error?: ResponseObject<CompanyMatch>
  locationId?: string
  locationIdError?: string
}

interface Props {
  data: CompanyMatch | null
  jobId: number
  jobOrigin: JobOrigin
  editable: boolean
  isAssigned: boolean
}

export class JobCompany extends Component<Props, State> {
  static contextType: React.Context<UserInfo> = UserContext

  state: State = {
    jobCompany: null,
    matches: null,
    dataSetEdit: false,
    dataSetExpanded: false,
    assignedCompany: null,
    locationId: '',
    locationIdError: '',
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState(this.createState(this.props.data))
    }
  }

  createState = (match: CompanyMatch) => {
    const newState: any = {
      jobCompany: { ...match.job_company },
      matches: match.master_data_companies
        ? [...match.master_data_companies]
        : [],
    }

    if (
      match &&
      match.assigned_master_data_company_id &&
      match.master_data_companies
    ) {
      const assignedCompany: MasterDataCompany | undefined =
        match.master_data_companies.find(
          (company: MasterDataCompany) =>
            company.id === match.assigned_master_data_company_id
        )
      if (assignedCompany) newState.assignedCompany = assignedCompany
    }
    return newState
  }

  toggleExpanded = (ev: MouseEvent): void => {
    ev.preventDefault()
    ev.stopPropagation()
    this.setState({ dataSetExpanded: !this.state.dataSetExpanded })
  }

  toggleEdit = (ev: SyntheticEvent<Element, Event>) => {
    ev.preventDefault()
    ev.stopPropagation()
    this.setState({ dataSetEdit: !this.state.dataSetEdit })
  }

  handleAddressUpdate = (formData: AddressDto) => {
    if (this.state.jobCompany) {
      const jobCompany = {
        ...this.state.jobCompany,
        address: {
          ...this.state.jobCompany.address,
          ...formData,
        },
        address_errors: null,
      }
      this.setState({
        jobCompany: jobCompany,
        dataSetEdit: false,
      })
    }
  }

  handleCreateMasterDataCompany = async () => {
    if (this.state.jobCompany && !this.state.loading) {
      this.setState({ loading: true })
      const response = await new AssignmentService().selfAssignment(
        this.props.jobId,
        this.state.jobCompany.id
      )
      if (response && response.success && response.data) {
        this.setState(this.createState(response.data))
        this.setState({ loading: false })
      } else {
        this.setState({ error: response, loading: false })
      }
    }
  }

  handleCompanySelect = async (id: string) => {
    if (this.state.matches) {
      const assignedCompany: MasterDataCompany | undefined =
        this.state.matches.find(
          (company: MasterDataCompany) => company.id === id
        )
      if (this.state.jobCompany && assignedCompany && !this.state.loading) {
        this.setState({ loading: true })
        const response = await new AssignmentService().postAssignment(
          this.props.jobId,
          {
            job_company_id: this.state.jobCompany.id,
            master_data_company_id: assignedCompany.id,
          }
        )
        if (response && response.success && response.data) {
          const newState = this.createState(response.data)
          newState.loading = false
          this.setState(newState)
          this.setState({ dataSetExpanded: !this.state.dataSetExpanded })
        } else {
          this.setState({ error: response, loading: false })
        }
      }
    }
  }

  handleAdditionalSearch = async (regexSearch: boolean) => {
    if (this.state.jobCompany && !this.state.loading) {
      this.setState({ loading: true })
      const service = new AdditionalSearchService()
      const response = await service.postAdditionalSearch(
        this.props.jobId,
        this.state.jobCompany.id,
        regexSearch
      )

      if (
        response &&
        response.success &&
        response.data &&
        response.data.master_data_companies
      ) {
        this.setState({
          matches: response.data.master_data_companies,
          loading: false,
        })
      } else {
        this.setState({ error: response, loading: false })
      }
    }
  }

  handleAddressSubmit = async (values: AddressDto) => {
    if (this.state.jobCompany) {
      const service = new CompanyAddressService()
      return await service.putCompanyAddress(
        this.props.jobId,
        this.state.jobCompany.id,
        values
      )
    }
    return Promise.reject()
  }

  handleAddCompanyId = (ev: React.FormEvent<HTMLInputElement>) => {
    if (ev.currentTarget) {
      let locationId = ev.currentTarget.value
      if (ev.currentTarget.value.startsWith('LOC-')) {
        locationId = locationId.replace('LOC-', '')
      }
      if (!isNaN(Number(locationId))) {
        this.setState({ locationId: locationId })
      }
    }
  }

  handleAddCompany = async () => {
    if (this.state.jobCompany && this.state.locationId && !this.state.loading) {
      this.setState({ loading: true })
      const response = await new AssignmentService().postAssignment(
        this.props.jobId,
        {
          job_company_id: `${this.state.jobCompany.id}`,
          master_data_company_id: `${this.state.locationId}`,
        }
      )
      if (response && response.success && response.data) {
        const newState = this.createState(response.data)
        newState.loading = false
        this.setState(newState)
        this.setState({
          locationId: '',
          locationIdError: '',
        })
      } else {
        this.setState({
          locationIdError: response.message || t('ErrorOccurred'),
          loading: false,
        })
      }
    }
  }

  render() {
    if (!this.state.jobCompany) return null
    const { can } = this.context
    const { editable, jobId, jobOrigin, isAssigned } = this.props
    const {
      error,
      loading,
      jobCompany,
      assignedCompany,
      matches,
      dataSetEdit,
      dataSetExpanded,
    } = this.state

    const idPrefix = jobOrigin === 'IMPORT_WIZARD' ? 'IW_' : ''
    const isExpanded = dataSetExpanded || dataSetEdit
    const assignedId = assignedCompany ? assignedCompany.id : null
    const selectedMatch = matches?.find(m => m.id === assignedId)
    const selectedMatchPercentage = selectedMatch?.match_percentage ?? 0
    const selectedMatchCss = []

    if (selectedMatchPercentage >= 90) {
      selectedMatchCss.push('text-success')
    } else if (selectedMatchPercentage >= 80) {
      selectedMatchCss.push('text-warning')
    } else if (selectedMatchPercentage > 0) {
      selectedMatchCss.push('text-danger')
    }
    return (
      <>
        <tr
          data-semantic-id="job-company"
          data-semantic-jobid={jobId}
          data-expanded={isExpanded ? 'true' : 'false'}
          data-main-row
          data-id={jobCompany.id}
          role="button"
          className={isExpanded ? 'd-none' : ''}
          onClick={this.toggleExpanded}>
          <td className={'align-center'}>
            <span className="badge badge-pill badge-primary badge-wide-custom">
              {matches?.length}
            </span>
          </td>
          <td className={'align-center'}>
            <CopyableField>
              <CompanyName
                company={jobCompany}
                idPrefix={idPrefix}
                showId={false}
              />
            </CopyableField>
          </td>
          <td className={'align-center'}>
            <CopyableField>
              <CompanyLocation
                idPrefix={idPrefix}
                company={jobCompany}
                assignedCompany={assignedCompany}
                showAddress
                showId={false}
                showTooltip
                overflow={true}
              />
              <>
                <Icon name="chevron-right" className="px-2" />
                <IdRenderer id={jobCompany.id} prefix={idPrefix} />
              </>
            </CopyableField>
          </td>
          <td className={'align-center'}>
            {selectedMatch && (
              <div className={selectedMatchCss.join(' ')}>
                <Icon name="circle-fill">
                  {`${selectedMatch.match_percentage?.toFixed(1)}%`}
                </Icon>
              </div>
            )}
          </td>
          <td className={'align-center'}>
            <JobCompanyHeaderButtons
              data-semantic-id={'job-company-header-buttons'}
              cssClass={`text-right text-nowrap align-items-center ${
                dataSetExpanded || dataSetEdit ? '' : 'opacity-50'
              }`}
              jobOrigin={jobOrigin}
              editable={editable}
              isAssigned={isAssigned}
              jobCompany={jobCompany}
              dataSetEdit={dataSetEdit}
              dataSetExpanded={dataSetExpanded}
              toggleEdit={this.toggleEdit}
              toggleExpanded={this.toggleExpanded}
            />
          </td>
        </tr>
        <tr
          data-semantic-id="job-company-details"
          data-expanded={isExpanded ? 'true' : undefined}
          className={isExpanded ? styles.expandedBody : ''}>
          <td colSpan={5} className="p-0">
            {/* The following component contains similar content as row #1 "job-company" of this component
            (JobCompany). If row #1 is clicked, it will be hidden and the entire content from row #2
            "job-company-details" will be displayed.  The following component is additionally displayed as a
            sticky element at the top of row #2 and shows reduced information from (the then hidden) row #1.
            */}
            <GspDedupJobStickyHeader
              jobOrigin={jobOrigin}
              editable={editable}
              isAssigned={isAssigned}
              jobCompany={jobCompany}
              matches={matches}
              dataSetEdit={dataSetEdit}
              dataSetExpanded={dataSetExpanded}
              assignedCompany={assignedCompany}
              toggleExpanded={this.toggleExpanded}
              toggleEdit={this.toggleEdit}
            />
            <Modal
              title={t('JobCompanyAddressModalTitle', {
                companyName: jobCompany?.name,
              })}
              isOpen={dataSetEdit}
              toggle={this.toggleEdit}
              data-semantic-id="job-company-address-modal"
              contentClasses={['p-0']}>
              {dataSetEdit && (
                <div className={`card-body companies edit-form`}>
                  <CompanyAddressForm
                    jobId={jobId}
                    companyId={jobCompany.id}
                    address={jobCompany.address}
                    address_errors={jobCompany.address_errors}
                    onUpdate={this.handleAddressUpdate}
                    onCancel={this.toggleEdit}
                    onSubmit={this.handleAddressSubmit}
                    jobIsPermittedAndAssigned={
                      can(Capability.WriteImportjobs) && isAssigned
                    }
                  />
                </div>
              )}
            </Modal>
            <TransitionGroup>
              {dataSetExpanded && (
                <UseTransition
                  name="MasterDataCompanies"
                  type="dropdown"
                  in={true}
                  timeout={300}>
                  {error ? (
                    <BootstrapAlert
                      type="danger"
                      message={error.message || t('ErrorOccurred')}
                      status={error.system}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                  <BackdropSpinner show={loading}>
                    <div>
                      {can(Capability.WriteImportjobs) && isAssigned && (
                        <div className="card-body bg-white d-flex justify-content-end">
                          {editable && (
                            <button
                              data-semantic-id="job-company-create-new-from-job"
                              className="btn btn-primary"
                              onClick={this.handleCreateMasterDataCompany}>
                              <Icon name="plus">
                                {`${t('createNewFromJobCompany')}`}
                              </Icon>
                            </button>
                          )}
                          {editable && dataSetExpanded && (
                            <CMDSearchSplitButton
                              data-semantic-id="job-company-fetch-more-results"
                              onSearch={this.handleAdditionalSearch}
                              className={'ml-3'}
                            />
                          )}
                        </div>
                      )}
                      <MasterDataCompanies
                        JobCompanyId={jobCompany.id}
                        data={matches}
                        selectable={editable}
                        assignedId={assignedId}
                        defaultAddress={jobCompany.address}
                        onSelectCompany={this.handleCompanySelect}
                        jobIsPermittedAndAssigned={
                          can(Capability.WriteImportjobs) && isAssigned
                        }
                      />
                      {can(Capability.WriteImportjobs) && isAssigned && (
                        <div className={'row no-gutters m-2 pl-5'}>
                          <Input
                            data-semantic-id="job-company-insert-company-id"
                            id="addjobcompany"
                            label={t('LocationId')}
                            cssClass={'col-2 m-0'}
                            slotRight={
                              !this.state.locationIdError ? (
                                <TooltipHandle
                                  data-semantic-id="job-company-insert-company-id-tooltip"
                                  data-semantic-context={`${this.state.jobCompany.id}`}
                                  name={`${this.state.jobCompany.id}`}
                                  label={<Icon name={'info-circle'} />}
                                  className={''}>
                                  {t(`AddCompanyToJobCompany`)}
                                </TooltipHandle>
                              ) : (
                                ''
                              )
                            }
                            onChange={this.handleAddCompanyId}
                            value={this.state.locationId}
                            disabled={loading}
                            error={this.state.locationIdError}
                          />
                          <div className="col-3 ml-3 mt-1">
                            <button
                              data-semantic-id="job-company-assign-company"
                              disabled={!this.state.locationId || loading}
                              type="button"
                              onClick={() => this.handleAddCompany()}
                              className="btn btn-sm btn-secondary">
                              {t('AssignCompany')}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </BackdropSpinner>
                </UseTransition>
              )}
            </TransitionGroup>
          </td>
        </tr>
      </>
    )
  }
}

export default withTranslation()(JobCompany)
