import React from 'react'

import { Company } from '../../../interface/Company'
import Icon from '../Icon/Icon'
import { TooltipHandle } from '../TooltipHandle/TooltipHandle'

interface Props {
  inputKey: string
  company: Company
  className?: string
}

export default function CompanyError(props: Props): JSX.Element {
  const { inputKey, company, className } = props
  const key = `company-error-${inputKey}`
  const errors = company.errors
    ? Object.keys(company.errors).length
      ? Object.values(company.errors)
      : []
    : []
  const hasErrors = errors.length > 0

  const cssClasses = ['btn', 'btn-sm']
  if (className && className.length) {
    cssClasses.push(className)
  }

  if (!hasErrors) {
    return (
      <button className={`${cssClasses.join(' ')} opacity-25`} disabled>
        <Icon name="bug" />
      </button>
    )
  }

  return (
    <TooltipHandle
      name={key}
      className={`${cssClasses.join(' ')}`}
      label={<Icon name="bug" />}>
      <div className={'text-left'}>
        <ul className={'pl-2 py-0 my-0'}>
          {errors.map((error, index) => (
            <li key={`${error}_${index}`} className={'pl-0'}>
              {error}
            </li>
          ))}
        </ul>
      </div>
    </TooltipHandle>
  )
}
