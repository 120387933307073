/**  `useApi2` is probably a preplacement for useApi with a more streamlined API */

import { useState, useCallback } from 'react'
import { ResponseObject } from '../interface/ResponseObject'
import { useTranslation } from 'react-i18next'

export default function useApi2<T>(
  errorMsgKey: string,
  fetchData: () => Promise<ResponseObject<T>>
): [
  () => void,
  boolean,
  ResponseObject<T> | null,
  ResponseObject<T> | null,
  (res: T) => void,
] {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ResponseObject<T> | null>(null)
  const [response, setResponse] = useState<ResponseObject<T> | null>(null)

  const call = useCallback(async () => {
    try {
      setIsLoading(true)
      setError(null)
      const fetched = await fetchData()
      if (fetched.success) {
        setError(null)
        setResponse(fetched)
      } else {
        fetched.message = fetched.message ?? t(errorMsgKey)
        setError(fetched)
        setResponse(null)
      }
    } catch (e) {
      console.error(e)
      setError({
        success: false,
        message: (e as any).message,
      })
    } finally {
      setIsLoading(false)
    }
  }, [errorMsgKey, fetchData, t])

  const update = useCallback(
    (updated: T) => {
      if (!response) {
        return
      }
      setResponse({
        ...response,
        data: updated,
      })
    },
    [response]
  )

  return [call, isLoading, response, error, update]
}
