import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import config from '../../../config'
import Icon from '../Icon/Icon'

interface Props {
  children?: ReactNode
  className?: string
}

export const Breadcrumb: React.FC<Props> = (props: Props) => (
  <nav aria-label="breadcrumb" className={props.className}>
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to="/">
          <Icon name="house-door">{config.APPLICATION_NAME}</Icon>
        </Link>
      </li>
      {props.children}
    </ol>
  </nav>
)
