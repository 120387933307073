import React, { useCallback, useState } from 'react'
import { useFormState } from 'react-use-form-state'
import { useTranslation } from 'react-i18next'

import Input from '../../UI/Input/Input'
import { ResponseObject } from '../../../interface/ResponseObject'
import BootstrapAlert from '../../UI/BootstrapAlert/BootstrapAlert'
import BackdropSpinner from '../../UI/BackdropSpinner/BackdropSpinner'
import {
  ClusterCompany,
  ClusterCompanyNames,
} from '../../../interface/ClusterCompany'
import ClusterJobs from '../../../services/ClusterJobs/ClusterJobs'
import { Autocomplete } from '../../UI/Autocomplete/Autocomplete'
import DefaultCompanyRoles from '../../../services/Roles/Roles'

interface Props {
  jobId: number
  clusterId: number
  company?: ClusterCompany | null
  onUpdate: (companyNames: ClusterCompanyNames) => void
  onCancel: (ev: React.SyntheticEvent) => void
}

export default function ClusterCompanyNameForm(props: Props): JSX.Element {
  const [formState, { text }] = useFormState<ClusterCompany>(
    props.company ? { ...props.company } : {}
  )
  const [errorMessage, updateErrorMessage] = useState<string | null>(null)
  const [systemStatus, updateSystemStatus] = useState<string | null>(null)
  const [submitting, updateSubmitting] = useState(false)
  const { t: translate } = useTranslation()

  async function handleSubmit(Event: React.FormEvent<HTMLFormElement>) {
    updateSubmitting(true)
    Event.preventDefault()
    const service = new ClusterJobs()
    const {
      name,
      name_nat,
      id,
      vat_id,
      local_siemens_area_id,
      vertical_id,
      national_trade_register_id,
      default_company_role,
      calculated_service_potential,
      service_potential_category,
    } = formState.values
    const companyNames: ClusterCompanyNames = {
      name,
      name_nat,
      vat_id,
      local_siemens_area_id,
      vertical_id,
      national_trade_register_id,
      default_company_role,
      calculated_service_potential,
      service_potential_category,
    }

    const response: ResponseObject<any> = await service.updateJobCompanyNames(
      props.jobId,
      props.clusterId,
      id,
      companyNames
    )

    if (response.success) {
      props.onUpdate(companyNames)
    } else {
      if (response.data && response.data.length) {
        for (let i = 0; i <= response.data.length - 1; i++) {
          formState.setFieldError(
            response.data[i].field_name,
            response.data[i].error
          )
        }
      } else {
        updateErrorMessage(response.message || translate('ErrorOccurred'))
        updateSystemStatus(response.system ?? null)
      }
    }

    updateSubmitting(false)
  }

  const fetchRoles = useCallback(async (role?: string) => {
    const service = new DefaultCompanyRoles()
    const res = await service.getRoles({ search: role, size: 0 })
    if (!res.success) {
      console.error(res.message)
      return { items: [], count: 0 }
    }
    // sort all roles by sort_ibase_number, lowest or undefined first, return the first 5 for display
    const sorted = (res.data ?? []).sort((a, b) =>
      (a.sort_ibase_number ?? 0) <= (b.sort_ibase_number ?? 0)
        ? -1
        : (a.sort_ibase_number ?? 0) > (b.sort_ibase_number ?? 0)
        ? 1
        : 0
    )
    const head = sorted.slice(0, 5)
    return { items: head, count: res?.pagination?.object_count ?? 0 }
  }, [])

  const handleSelectRole = useCallback(
    (item: any) => {
      formState.setField('default_company_role', item)
    },
    [formState]
  )

  return (
    <BackdropSpinner show={submitting}>
      <form data-semantic-id="company-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            {errorMessage ? (
              <BootstrapAlert
                type="danger"
                message={errorMessage}
                status={systemStatus}
              />
            ) : null}
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-md">
            <Input
              label={translate('Name')}
              {...text({ name: 'name', validateOnBlur: true })}
              error={formState.errors.name}
              required
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={translate('LocalName')}
              {...text({ name: 'name_nat', validateOnBlur: true })}
              error={formState.errors.name_nat}
              required
            />
          </div>
          <div className="col-md  pl-md-3">
            <Input
              label={translate('VatId')}
              {...text({ name: 'vat_id', validateOnBlur: true })}
              error={formState.errors.vat_id}
            />
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-md">
            <Input
              label={translate('LocalSiemensAreaId')}
              {...text({ name: 'local_siemens_area_id', validateOnBlur: true })}
              error={formState.errors.local_siemens_area_id}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={translate('VerticalId')}
              {...text({ name: 'vertical_id', validateOnBlur: true })}
              error={formState.errors.vertical_id}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={translate('NationalTradeRegisterId')}
              {...text({
                name: 'national_trade_register_id',
                validateOnBlur: true,
              })}
              error={formState.errors.national_trade_register_id}
            />
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-md">
            <Autocomplete
              data-semantic-id="company-form-default-role"
              id="company-form-default-role"
              label={translate('DefaultCompanyRole')}
              onFetchData={fetchRoles}
              onSelect={handleSelectRole}
              defaultValue={
                {
                  ...({
                    ...text({
                      name: 'default_company_role',
                    }),
                  }?.value as any),
                }?.name
              }
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              disabled={true}
              label={translate('CalculatedServicePotential')}
              {...text({
                name: 'calculated_service_potential',
                validateOnBlur: true,
              })}
              error={formState.errors.calculated_service_potential}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              disabled={true}
              label={translate('ServicePotentialCategory')}
              {...text({
                name: 'service_potential_category',
                validateOnBlur: true,
              })}
              error={formState.errors.service_potential_category}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            data-semantic-id="cancel-button"
            className="btn btn-gray-custom mr-2"
            type="button"
            onClick={props.onCancel}>
            {translate('Cancel')}
          </button>
          <button
            data-semantic-id="submit-button"
            type="submit"
            className="btn btn-secondary">
            {translate('Save')}
          </button>
        </div>
      </form>
    </BackdropSpinner>
  )
}
