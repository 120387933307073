import React from 'react'

type DateFormat = 'date' | 'time' | 'datetime'

export const formatDate = (
  date: Date | string | number | undefined,
  fmt: DateFormat
) => {
  if (!date) {
    return '-'
  }
  const d = new Date(date)
  const HH = `${d.getHours()}`.padStart(2, '0')
  const mm = `${d.getMinutes()}`.padStart(2, '0')
  const time = `${HH}:${mm}`
  if (fmt === 'time') {
    return time
  }
  if (fmt === 'date') {
    return d.toLocaleDateString()
  }
  return `${d.toLocaleDateString()} ${time}`
}

interface Props {
  date: Date | string | number | undefined
  format?: DateFormat
}

export const FormattedDate = (props: Props) => (
  <span className="d-inline-block nowrap">
    {formatDate(props.date, props.format ?? 'datetime')}
  </span>
)
