import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '../Icon/Icon'
import styles from './SortHandle.module.scss'

export enum Sorting {
  Ascending,
  Descending,
}

interface Props {
  'data-semantic-id'?: string
  sort: Sorting
  onSelectSorting: (sort: Sorting) => void
}

export const SortHandle: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { sort, onSelectSorting } = props

  const title =
    sort === Sorting.Ascending ? t('SortAscending') : t('SortDescending')

  const toggle = useCallback(() => {
    onSelectSorting(
      sort === Sorting.Ascending ? Sorting.Descending : Sorting.Ascending
    )
  }, [sort, onSelectSorting])

  return (
    <button
      data-semantic-id={props['data-semantic-id']}
      className={`btn d-flex flex-column p-0 m-0 ${styles.handle}`}
      aria-label={title}
      title={title}
      onClick={toggle}>
      <Icon
        name="chevron-up"
        className={`${styles.icon} ${
          sort !== Sorting.Ascending ? styles.muted : ''
        }`}
      />
      <Icon
        name="chevron-down"
        className={`${styles.icon} ${
          sort !== Sorting.Descending ? styles.muted : ''
        }`}
      />
    </button>
  )
}
