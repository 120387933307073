import ApiService from '../ApiService'

import { CompanyMatch } from '../../interface/CompanyMatch'

class CompanyMatchService extends ApiService {
  getCompanyMatch(jobId: number, page?: number) {
    const pageParameter =
      page || (page && page !== 1) ? `?page=${encodeURIComponent(page)}` : ''
    return this.get<CompanyMatch[]>(
      '/jobs/' + jobId + '/company_match' + pageParameter
    )
  }
}

export default CompanyMatchService
