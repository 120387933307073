import { useCallback, useEffect, useRef } from 'react'

/// It should rarely be necessary to check whether the current
/// component is mounted but in some cases the React component
/// doesn't coordinate an async process itself so we might need
/// this information in callback logic outside of a component.
/// See https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
export default function useMountedState() {
  const mountedRef = useRef(false)
  const isMounted = useCallback(() => mountedRef.current, [])

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  return isMounted
}
