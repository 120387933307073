import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { FormattedDate } from '../FormattedDate/FormattedDate'
import Icon from '../Icon/Icon'
import { UserContext } from '../../../contexts/UserContext'
import { Job } from '../../../interface/Job'
import { Link } from 'react-router-dom'

interface Props {
  job: {
    created_at: Date | string
    created_by: string
    assignee?: string
    company_name?: string
    external_id?: string
    name?: string
    status: string
    city?: string
    street?: string
    country?: string
    import_job?: Job
  }
  jobType: string
  assignable?: boolean
  isAssigning?: boolean
  onAssign?: (assignee: string) => void
}

const JobInfo: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { userName } = useContext(UserContext)

  const { job, jobType, assignable, isAssigning, onAssign } = props

  const handleOnClick = useCallback(() => {
    if (onAssign) {
      onAssign(userName)
    }
  }, [onAssign, userName])

  const details = [
    job.company_name ?? job.name ?? '',
    job.country ?? '',
    job.city ?? '',
    job.street ?? '',
  ].filter(item => item.length > 0)

  const assigned = job?.assignee !== undefined && job.assignee?.length > 0

  return (
    <div data-semantic-id="job-info" className="alert alert-inquisitive">
      <div className="d-flex align-items-center mr-2 mb-2">
        <Icon name="info-circle" className="pl-0 pr-1 align-middle" />
        <div className="h4 mb-0 alert-heading">
          {t('job_state')}:{' '}
          <i>
            {jobType === 'CLUSTER_JOB'
              ? t(`ClusterJobStatus_${job.status}`)
              : jobType === 'IMPORT_JOB'
              ? t(`ImportJobStatus_${job.status}`)
              : job.status}
          </i>
        </div>
        <div className={'d-flex ml-auto'}>
          {assigned && (
            <div className={'h4 mb-0 ml-auto'}>
              {t('JobAssignee')}: {job.assignee}
            </div>
          )}
          {assignable && (
            <button
              data-semantic-id="assign-to-job"
              className="btn btn-sm btn-gray-custom ml-2 p-0 mr-0"
              onClick={handleOnClick}
              disabled={isAssigning}>
              {t('assignUserToJob')}
            </button>
          )}
        </div>
      </div>
      <div className="alert-body">
        <span className="font-weight-lighter">
          <strong> {details.join(', ')}</strong>{' '}
          <i className="mb-0">
            {t('created_by')}: {job.created_by} - {t('created_at')}:
            <span>&nbsp;</span>
            <FormattedDate date={job.created_at} />
            {job.import_job?.id && (
              <>
                <span>&nbsp; - &nbsp;</span>
                <Link
                  data-semantic-id={'job-info-linked-importjob'}
                  to={`/importjobs/${job.import_job?.id}`}
                  style={{ color: 'white', fontWeight: 'lighter' }}>
                  {t('IMPORT_JOB')}: {job.import_job?.id}{' '}
                </Link>
              </>
            )}
          </i>
        </span>
        {job.external_id ? (
          <span className="col">{job.external_id}</span>
        ) : null}
      </div>
    </div>
  )
}

export default JobInfo
