import React from 'react'

import { useTranslation } from 'react-i18next'
import { Company } from '../../../interface/Company'
import IdRenderer from '../IdRenderer/IdRenderer'
import { TooltipHandle } from '../TooltipHandle/TooltipHandle'
import CompanySource from '../CompanySource/CompanySource'

interface Props {
  company: Company
  showId?: boolean
  idPrefix?: string
  showSource?: boolean
}

const CompanyName: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { company, showId, idPrefix } = props
  const { name, name_nat, id } = company

  const tooltip =
    name && name_nat
      ? `${t('LocalName')}: ${name_nat}, ${t('InternationalName')}: ${name}`
      : name_nat
      ? `${t('LocalName')}: ${name_nat}`
      : name
      ? `${t('InternationalName')}: ${name}`
      : ''
  const companyName = name ? name : name_nat ? name_nat : ''

  if (!companyName) return null
  return (
    <>
      {props.showSource && (
        <CompanySource
          company={props.company}
          className={'d-inline-flex mr-2 company-source'}
        />
      )}
      <TooltipHandle
        name={`compname-${id}`}
        label={companyName}
        className="p-0 m-0 text-left text-reset border-0"
        trigger="hover"
        align="left"
        isButton={false}>
        {tooltip}
      </TooltipHandle>
      {showId && (
        <>
          &nbsp;
          <IdRenderer id={id} prefix={idPrefix} />
        </>
      )}
    </>
  )
}

CompanyName.defaultProps = {
  showId: true,
  idPrefix: '',
}
export default CompanyName
