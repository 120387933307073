import React from 'react'
import './Spinner.css'

interface SpinnerProps {
  status?: any
  size?: number
  className?: string
}

const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps) => {
  const size = props.size || 200
  const borderSize = size * 0.05
  return (
    <div
      className={`spinner-wrap ${props.status || ''} ${props.className || ''}`}>
      <div
        className="spinner"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          ['--spinner-border-size' as any]: `${borderSize}px`,
        }}>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
export default Spinner
