import {
  Address,
  AddressError,
  AddressSuggestion,
} from '../../interface/Address'
import { ResponseObject } from '../../interface/ResponseObject'
import ApiService from '../ApiService'

export type PutCompanyAddressResponse = Address & {
  errors?: AddressError[]
  suggestions?: AddressSuggestion[]
} & AddressError[]

export type AddressDto = Address & {
  skip_validation?: boolean
}

class CompanyAddressService extends ApiService {
  putCompanyAddress(
    jobId: number,
    jobCompanyId: string,
    address: AddressDto
  ): Promise<ResponseObject<PutCompanyAddressResponse>> {
    return this.put(
      '/jobs/' + jobId + '/company_match/' + jobCompanyId + '/address',
      address
    )
  }
}

export default CompanyAddressService
