import { useCallback, useState } from 'react'

import { ResponseObject } from '../interface/ResponseObject'

const DEFAULT_PAGESIZE = 10

export default function usePagination<T>() {
  const [error, setError] = useState<ResponseObject<T[]> | null>(null)
  const [items, setItems] = useState<T[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE)
  const [objectCount, setObjectCount] = useState(0)

  const onSelectPage = useCallback((selectedPage: number) => {
    setPage(selectedPage)
  }, [])
  const fetchItems = useCallback(
    async (fetchItems: () => Promise<ResponseObject<T[]>>) => {
      try {
        setIsLoading(true)
        const res = await fetchItems()
        if (!res.success) {
          setError(res)
          return
        }
        if (!res.data) {
          setItems([])
          setError(res)
          return
        }
        setItems(res.data)
        setPageSize(res.pagination?.page_size ?? DEFAULT_PAGESIZE)
        setObjectCount(res.pagination?.object_count ?? 0)
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  // only the pagination could be calculated in be, without sending the data
  const fetchPagination = useCallback(
    async (fetchItems: () => Promise<ResponseObject<T[]>>) => {
      try {
        const res = await fetchItems()
        setPageSize(res.pagination?.page_size ?? DEFAULT_PAGESIZE)
        setObjectCount(res.pagination?.object_count ?? 0)
      } catch (e) {
        console.error(e)
      }
    },
    []
  )

  const resetPage = useCallback(() => {
    setPage(0)
  }, [])

  return {
    error,
    fetchItems,
    isLoading,
    items,
    objectCount,
    onSelectPage,
    page,
    pageSize,
    pagination: {
      page,
      pageSize,
      objectCount,
      onSelectPage,
    },
    resetPage,
    setItems,
    fetchPagination,
  }
}
