import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropdown } from '../../../hooks/useDropdown'
import Icon from '../Icon/Icon'
import { Capability, UserContext } from '../../../contexts/UserContext'
import { MatchingConfig } from '../../../interface/MatchingConfig'
import { Autocomplete } from '../Autocomplete/Autocomplete'
import { TooltipHandle } from '../TooltipHandle/TooltipHandle'
import MatchingConfigs from '../../../services/MatchingConfigs/MatchingConfigs'

async function getMatchingConfigs(params: { name: string }) {
  const service = new MatchingConfigs()
  return await service.getConfigs(params)
}

interface Props {
  className?: string
  onRestart: (matchingConfigId?: number) => Promise<void>
}

const RestartMatchingMenu: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { can } = useContext(UserContext)

  const [ref, isOpen, setIsOpen] = useDropdown<HTMLDivElement>()

  const [matchingConfig, setMatchingConfig] = useState<MatchingConfig>({
    created_at: '',
    current: false,
    modified_at: '',
    value: '',
    id: undefined,
    name: '',
  })

  const { className, onRestart } = props
  const cssClasses = []

  if (className && className.length) {
    cssClasses.push(className)
  }

  const handleSplitToggle = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault()
      setIsOpen(before => !before)
    },
    [setIsOpen]
  )

  const fetchMatchingConfigs = useCallback(async (term: string) => {
    const res = await getMatchingConfigs({ name: term })
    if (!res.success) {
      console.error(res.message)
      return { items: [], count: 0 }
    }
    return { items: res.data ?? [], count: res?.pagination?.object_count ?? 0 }
  }, [])

  const selectMatchingConfig = useCallback(
    (item: any) => {
      setMatchingConfig(item)
    },
    [setMatchingConfig]
  )

  const handleOnRestart = useCallback(() => {
    onRestart(matchingConfig.id)
  }, [matchingConfig, onRestart])

  return (
    <div
      data-semantic-id="import-job-restart-matching-menu"
      ref={ref}
      className={`btn-group dropdown ${cssClasses.join(' ')}`}>
      <button
        data-semantic-id="import-job-restart-matching"
        onClick={handleOnRestart}
        className="btn btn-secondary mr-0">
        <Icon name="bootstrap-reboot">{t('RestartMatching')}</Icon>
      </button>
      {can(Capability.ReadMatchingConfig) && (
        <button
          data-semantic-id="import-job-change-matching-config"
          type="button"
          className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={handleSplitToggle}>
          <span className="sr-only">Arrow</span>
        </button>
      )}
      <TooltipHandle
        data-semantic-id="restartjobinfo"
        className="btn btn-gray-custom"
        name={'restartjobinfo'}
        label={<Icon name="info-circle" />}>
        {t('ReMatchingInfo')}
      </TooltipHandle>
      <div
        className={`dropdown-menu dropdown-menu-right p-0 m-0 ${
          isOpen ? 'show' : ''
        }`}>
        <div className={`dropdown-item p-0 m-0`} style={{ minWidth: '247px' }}>
          <Autocomplete
            data-semantic-id="import-job-search-matchingconfig"
            id="matchingconfig"
            inputClassName={'p-0'}
            formGroupClassName={'m-0'}
            label={t('MatchingConfigTitle')}
            slotRight={
              <div
                data-semantic-id="controlinfo"
                data-semantic-context={'matchingconfig-tooltip'}
                className="d-flex">
                {/*<TooltipHandle*/}
                {/*  name={'matchingconfig-tooltip'}*/}
                {/*  label={<Icon name="info-circle" />}>*/}
                {/*  {t('SearchMatchingConfigTooltip')}*/}
                {/*</TooltipHandle>*/}
              </div>
            }
            onFetchData={fetchMatchingConfigs}
            onSelect={selectMatchingConfig}
            defaultValue={matchingConfig.name}
          />
        </div>
      </div>
    </div>
  )
}
export default RestartMatchingMenu
