import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FormattedDate } from '../UI/FormattedDate/FormattedDate'
import Checkbox from '../UI/Checkbox/Checkbox'
import { Breadcrumb } from '../UI/Breadcrumb/Breadcrumb'
import { SortHandle, Sorting } from '../UI/SortHandle/SortHandle'
import { MatchingConfig } from '../../interface/MatchingConfig'
import MatchingConfigs from '../../services/MatchingConfigs/MatchingConfigs'
import BootstrapAlert from '../UI/BootstrapAlert/BootstrapAlert'
import BackdropSpinner from '../UI/BackdropSpinner/BackdropSpinner'
import { Pagination } from '../UI/Pagination/Pagination'
import { Capability, UserContext } from '../../contexts/UserContext'
import usePagination from '../../hooks/usePagination'

const fetchMatchingConfigs = async (
  page: number,
  sort: Sorting,
  size: number
) => {
  return await new MatchingConfigs().getConfigs(
    {
      sort: sort === Sorting.Ascending ? 'created_at:ASC' : 'created_at:DESC',
      size: size,
    },
    page
  )
}

export default function GspMatchingConfigOverview() {
  const { t } = useTranslation()
  const { can } = useContext(UserContext)

  const [sort, setSort] = useState<Sorting>(Sorting.Descending)

  const { error, fetchItems, isLoading, items, page, pagination } =
    usePagination<MatchingConfig>()

  const handleSelectSort = useCallback((sort: Sorting) => {
    setSort(sort)
  }, [])

  useEffect(() => {
    fetchItems(() => fetchMatchingConfigs(page, sort, 8))
  }, [fetchItems, page, sort])

  return (
    <div data-semantic-id="matchingconfig-overview">
      <Breadcrumb>
        <li className="breadcrumb-item active" aria-current="page">
          {t('MatchingConfigTitle')}
        </li>
      </Breadcrumb>
      <div className="d-flex align-items-center justify-content-between">
        <h1>{t('MatchingConfigTitle')}</h1>
        <div className="d-flex justify-content-end">
          {can(Capability.WriteMatchingConfig) && (
            <Link
              to="/matchingconfigs/new"
              data-semantic-id="matchingconfigs-new"
              className="btn btn-secondary">
              {t('CreateMatchingConfig')}
            </Link>
          )}
        </div>
      </div>
      <p>{t('MatchingConfigOverviewDescription')}</p>
      {error && <BootstrapAlert type="danger">{error.message}</BootstrapAlert>}
      <div className="row mb-4">
        <div className="col">
          <div
            data-semantic-id="paginated-data"
            className="card card-custom-table">
            <div className="card-body">
              <BackdropSpinner show={isLoading}>
                <div>
                  <table className="table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">{t('TableHeaderId')}</th>
                        <th scope="col">{t('TableHeaderTitle')}</th>
                        <th scope="col">
                          <span className="d-flex">
                            <span className="mr-2">{t('JobCreatedAt')}</span>
                            <SortHandle
                              data-semantic-id="matchingconfigs-sort-created-at"
                              sort={sort}
                              onSelectSorting={handleSelectSort}
                            />
                          </span>
                        </th>
                        <th scope="col">{t('TableHeaderModifiedAt')}</th>
                        <th scope="col">{t('TableHeaderCurrent')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items?.length === 0 && (
                        <tr>
                          <td colSpan={5}>&nbsp;</td>
                        </tr>
                      )}
                      {items?.map((item, i) => (
                        <tr key={i} data-main-row data-id={item.id}>
                          <th scope="row">
                            <Link to={`/matchingconfigs/${item.id}`}>
                              {item.id}
                            </Link>
                          </th>
                          <td>
                            <span className="overflow-ellipsis text-nowrap">
                              {item.name}
                            </span>
                          </td>
                          <td>
                            <FormattedDate date={item.created_at} />
                          </td>
                          <td>
                            <FormattedDate date={item.modified_at} />
                          </td>
                          <td>
                            <Checkbox
                              key={`${item.current}`}
                              inputKey={`current-${item.id}`}
                              disabled
                              checked={item.current}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </BackdropSpinner>
            </div>
          </div>
        </div>
      </div>
      <Pagination {...pagination} />
    </div>
  )
}
