import React, { useCallback, useState, Children } from 'react'
import Icon from '../Icon/Icon'

interface Props {
  children: React.ReactNode
}

const CopyableField: React.FC<Props> = (props: Props) => {
  const { children } = props

  const spanRef = React.createRef<HTMLSpanElement>()
  const [showIcon, setShowIcon] = useState<boolean>(false)
  const [iconClicked, setIconClicked] = useState<boolean>(false)

  const handleMouseEnter = useCallback(() => {
    if (!iconClicked) {
      setShowIcon(true)
    }
  }, [iconClicked])

  const handleMouseLeave = useCallback(() => {
    if (!iconClicked) setShowIcon(false)
  }, [iconClicked])

  const copyToClipboard = async (ev: React.SyntheticEvent) => {
    ev.stopPropagation()
    if (spanRef?.current?.textContent) {
      try {
        navigator.clipboard.writeText(spanRef.current.textContent)
        setShowIcon(false)
        setIconClicked(true)
        /* workaround, in firefox onMouseEnter event is triggered again after copy click */
        setTimeout(() => {
          setIconClicked(false)
        }, 100)
      } catch (error) {
        console.error('Failed to copy text: ', error)
      }
    }
  }

  const all = Children.toArray(children)
  const singleChild = all.length === 1
  const allExcept = all.slice(0, -1)
  const last = all[all?.length - 1]

  return (
    <span
      className={`d-flex overflow-ellipsis text-nowrap`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {/*If multiple child elements are passed, only the last one is currently copied*/}
      {singleChild ? (
        <span
          ref={spanRef}
          className="overflow-ellipsis text-nowrap"
          style={{ minWidth: '0' }}>
          {children}
        </span>
      ) : (
        <>
          <span
            className="overflow-ellipsis text-nowrap"
            style={{ minWidth: '0' }}>
            {allExcept}
          </span>
          <span ref={spanRef}>{last}</span>
        </>
      )}

      {showIcon && (
        <button
          data-test-id="copyable-field"
          className={'btn btn-sm m-0 ml-1 p-0 border-0'}
          onClick={copyToClipboard}>
          <Icon name={'clipboard'} data-testid={'clipboard'} />
        </button>
      )}
    </span>
  )
}

export default CopyableField
