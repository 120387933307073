import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useFormState } from 'react-use-form-state'
import { useTranslation } from 'react-i18next'

import {
  Address,
  AddressError,
  AddressSuggestion,
} from '../../../interface/Address'
import Input from '../Input/Input'
import { ResponseObject } from '../../../interface/ResponseObject'
import BootstrapAlert from '../BootstrapAlert/BootstrapAlert'
import BackdropSpinner from '../BackdropSpinner/BackdropSpinner'
import {
  AddressDto,
  PutCompanyAddressResponse,
} from '../../../services/CompanyAddress/CompanyAddressService'
import { useDropdown } from '../../../hooks/useDropdown'

const extractSuggestions = (
  field: keyof AddressSuggestion,
  suggestions: AddressSuggestion[]
) => suggestions.filter(it => field in it && it[field]).map(it => it[field])

interface Props {
  jobId: number
  companyId: string
  address?: Address | null
  address_errors?: AddressError[] | null
  onUpdate: (values: any) => void
  onCancel: (values: any) => void
  onSubmit: (values: any) => Promise<ResponseObject<PutCompanyAddressResponse>>
  canSkipValidation?: boolean
  jobIsPermittedAndAssigned: boolean
}
export default function CompanyAddressForm(props: Props): JSX.Element {
  const {
    address,
    address_errors,
    onCancel,
    onSubmit,
    onUpdate,
    canSkipValidation = true,
    jobIsPermittedAndAssigned,
  } = props

  const [formState, { text, textarea }] = useFormState<AddressDto>(
    address ? { ...address } : {}
  )
  const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([])
  const [errorMessage, updateErrorMessage] = useState<string | null>(null)
  const [errorSystemStatus, updateErrorSystemStatus] = useState<string | null>(
    null
  )
  const [submitting, setSubmitting] = useState(false)
  const [errorsInitialized, setErrorsInitialized] = useState(false)
  const { t } = useTranslation()

  const [ref, isOpen, setIsOpen] = useDropdown<HTMLDivElement>()
  const forceSave = formState.values.skip_validation

  const isMountedRef = useRef<boolean>()

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (!errorsInitialized && address_errors && address_errors.length) {
      address_errors.forEach((addressError: AddressError) => {
        formState.setFieldError(addressError.field_name, addressError.error)
      })
      setErrorsInitialized(true)
    }
  }, [errorsInitialized, address_errors, formState])

  async function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    setSubmitting(true)
    ev.preventDefault()
    const response: ResponseObject<PutCompanyAddressResponse> = await onSubmit(
      formState.values
    )

    if (response.success) {
      onUpdate(formState.values)
    } else {
      if (response.data && response.data.suggestions) {
        setSuggestions(response.data.suggestions)
      } else {
        setSuggestions([])
      }
      if (response.data && response.data?.errors?.length) {
        for (const error of response.data.errors) {
          formState.setFieldError(error.field_name, error.error)
        }
      } else if (response.data && response.data.length) {
        for (let i = 0; i <= response.data.length - 1; i++) {
          formState.setFieldError(
            response.data[i].field_name,
            response.data[i].error
          )
        }
      } else {
        updateErrorMessage(response.message || t('ErrorOccurred'))
        updateErrorSystemStatus(response.system ?? null)
      }
    }

    if (isMountedRef.current) setSubmitting(false)
  }
  const handleSplitToggle = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault()
      setIsOpen(before => !before)
    },
    [setIsOpen]
  )
  const handleToggleSave = useCallback(() => {
    const newValue = !formState.values.skip_validation
    formState.setField('skip_validation', newValue)
  }, [formState])

  const handleOnChange = (field: keyof Address) => {
    formState.setFieldError(field, '')
  }

  return (
    <BackdropSpinner show={submitting}>
      <form data-semantic-id="company-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            {errorMessage ? (
              <BootstrapAlert
                type="danger"
                message={errorMessage}
                status={errorSystemStatus}
              />
            ) : null}
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-md">
            <Input
              label={t('Street')}
              {...text({
                name: 'street',
                validateOnBlur: true,
                onChange: () => handleOnChange('street'),
              })}
              error={formState.errors.street}
              suggestions={extractSuggestions('street', suggestions)}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={t('StreetNational')}
              {...text({
                name: 'street_nat',
                validateOnBlur: true,
                onChange: () => handleOnChange('street_nat'),
              })}
              error={formState.errors.street_nat}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={t('Zip')}
              {...text({
                name: 'zip',
                validateOnBlur: true,
                onChange: () => handleOnChange('zip'),
              })}
              error={formState.errors.zip}
              suggestions={extractSuggestions('zip', suggestions)}
            />
          </div>
          <div className="col-md pl-md-3"></div>
        </div>

        <div className="row no-gutters">
          <div className="col-md">
            <Input
              label={t('City')}
              {...text({
                name: 'city',
                validateOnBlur: true,
                onChange: () => handleOnChange('city'),
              })}
              error={formState.errors.city}
              suggestions={extractSuggestions('city', suggestions)}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={t('CityNational')}
              {...text({
                name: 'city_nat',
                validateOnBlur: true,
                onChange: () => handleOnChange('city_nat'),
              })}
              error={formState.errors.city_nat}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={t('State')}
              {...text({
                name: 'state',
                validateOnBlur: true,
                onChange: () => handleOnChange('state'),
              })}
              error={formState.errors.state}
              suggestions={extractSuggestions('state', suggestions)}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={t('Country')}
              {...text({
                name: 'country',
                validateOnBlur: true,
                onChange: () => handleOnChange('country'),
              })}
              error={formState.errors.country}
              suggestions={extractSuggestions('country', suggestions)}
            />
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-md">
            <Input
              label={t('GpsLongitude')}
              {...text({
                name: 'gps_longitude',
                validateOnBlur: true,
                onChange: () => handleOnChange('gps_longitude'),
              })}
              error={formState.errors.gps_longitude}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={t('GpsLatitude')}
              {...text({
                name: 'gps_latitude',
                validateOnBlur: true,
                onChange: () => handleOnChange('gps_latitude'),
              })}
              error={formState.errors.gps_latitude}
            />
          </div>
          <div className="col-md pl-md-3">
            <Input
              label={t('GpsAltitude')}
              {...text({
                name: 'gps_altitude',
                validateOnBlur: true,
                onChange: () => handleOnChange('gps_altitude'),
              })}
              error={formState.errors.gps_altitude}
            />
          </div>
          <div className="col-md pl-md-3"></div>
        </div>

        <Input
          label={t('AdditionalInformation')}
          {...textarea({
            name: 'additional_information',
            validateOnBlur: true,
            onChange: () => handleOnChange('additional_information'),
          })}
          type="textarea"
          error={formState.errors.additional_information}
        />

        <div className="d-flex justify-content-end">
          <button
            data-semantic-id="company-form-cancel"
            className="btn btn-gray-custom mr-2"
            type="button"
            onClick={onCancel}>
            {t('Cancel')}
          </button>
          {/*Could be Write Import or Write Cluster Job, depends on context*/}
          {jobIsPermittedAndAssigned &&
            (canSkipValidation ? (
              <div ref={ref} className="btn-group dropup">
                <button type="submit" className="btn btn-secondary">
                  {forceSave ? t('SaveWithoutValidation') : t('Save')}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={isOpen ? 'true' : 'false'}
                  onClick={handleSplitToggle}>
                  <span className="sr-only">{t('ChangeSaveMode')}</span>
                </button>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    isOpen ? 'show' : ''
                  }`}>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={handleToggleSave}>
                    {forceSave ? t('Save') : t('SaveWithoutValidation')}
                  </button>
                </div>
              </div>
            ) : (
              <button type="submit" className="btn btn-secondary">
                {t('Save')}
              </button>
            ))}
        </div>
      </form>
    </BackdropSpinner>
  )
}
