import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../Icon/Icon'

const ACTIVE_PAGE_RANGE = 4

function range(from: number, to: number): number[] {
  const result = []
  for (let i = from; i < to; i += 1) {
    result.push(i)
  }
  return result
}

interface Props {
  page: number
  pageSize: number
  objectCount: number
  onSelectPage: (page: number) => void
}

export const Pagination: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { page, pageSize, objectCount, onSelectPage } = props

  const isFirstPage = page === 0
  const isLastPage = page * pageSize + pageSize >= objectCount

  const handleSelectPage = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault()
      const pageAttr = (ev.currentTarget as any).getAttribute('data-page')
      const page = parseFloat(pageAttr) || 0
      onSelectPage(page)
    },
    [onSelectPage]
  )

  const preventDefault = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault()
    },
    []
  )

  const pageItems = range(0, Math.ceil(objectCount / pageSize)).flatMap(
    (i, _, all) => {
      const pageItem = {
        active: i === page,
        index: i,
        label: `${i + 1}`,
        isHole: false,
      }
      if (
        all.length < 20 ||
        i < ACTIVE_PAGE_RANGE ||
        i >= all.length - ACTIVE_PAGE_RANGE
      ) {
        return [pageItem]
      }
      if (i > page - ACTIVE_PAGE_RANGE && i < page + ACTIVE_PAGE_RANGE) {
        return [pageItem]
      }
      if (i === page - ACTIVE_PAGE_RANGE || i === page + ACTIVE_PAGE_RANGE) {
        pageItem.isHole = true
        pageItem.label = '...'
        return [pageItem]
      }
      return []
    }
  )

  return (
    <div data-semantic-id="pagination" className="row">
      <div className="col" data-semantic-id="pagination-meta">
        {page * pageSize + 1}-
        {Math.min(objectCount, page * pageSize + pageSize)} {t('Of')}{' '}
        {objectCount}
      </div>
      <div className="col">
        <nav aria-label={t('Pagination')}>
          <ul className="pagination pagination-sm justify-content-center">
            {isFirstPage ? (
              <li key="prev-disabled" className="page-item disabled">
                <a
                  data-semantic-id="pagination-prev"
                  className="page-link"
                  onClick={preventDefault}
                  href="#"
                  tabIndex={-1}
                  aria-disabled="true">
                  <Icon name="chevron-left" />
                </a>
              </li>
            ) : (
              <li key="prev-enabled" className="page-item">
                <a
                  data-semantic-id="pagination-prev"
                  className="page-link"
                  data-page={page - 1}
                  onClick={handleSelectPage}
                  href="#">
                  <Icon name="chevron-left" />
                </a>
              </li>
            )}
            {pageItems.map(p =>
              p.isHole ? (
                <li key={p.index} className="page-item disabled ml-2">
                  <a
                    href="#"
                    className="page-link"
                    tabIndex={-1}
                    aria-disabled="true">
                    {p.label}
                  </a>
                </li>
              ) : p.active ? (
                <li
                  key={p.index}
                  className="page-item active ml-2"
                  aria-current="page">
                  <span className="page-link">{p.label}</span>
                </li>
              ) : (
                <li key={p.index} className="page-item ml-2">
                  <a
                    className="page-link"
                    data-page={p.index}
                    onClick={handleSelectPage}
                    href="#">
                    {p.label}
                  </a>
                </li>
              )
            )}
            {isLastPage ? (
              <li key="next-disabled" className="page-item disabled ml-2">
                <a
                  data-semantic-id="pagination-next"
                  className="page-link"
                  onClick={preventDefault}
                  href="#"
                  tabIndex={-1}
                  aria-disabled="true">
                  <Icon name="chevron-right" />
                </a>
              </li>
            ) : (
              <li key="next-enabled" className="page-item ml-2">
                <a
                  data-semantic-id="pagination-next"
                  className="page-link"
                  data-page={page + 1}
                  onClick={handleSelectPage}
                  href="#">
                  <Icon name="chevron-right" />
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <div className="col"></div>
    </div>
  )
}
