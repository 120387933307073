import React from 'react'
import { useTranslation } from 'react-i18next'
import { Address } from '../../../interface/Address'
import Icon from '../Icon/Icon'
import { TooltipHandle } from '../TooltipHandle/TooltipHandle'

interface Props {
  inputKey: string
  address: Address
}

export default function AddressInfo(props: Props): JSX.Element {
  const { t } = useTranslation()
  const { inputKey, address } = props
  const key = `ai-tt-${inputKey}`
  const hasInfo =
    address.gps_altitude ||
    address.gps_latitude ||
    address.gps_longitude ||
    address.additional_information

  if (!hasInfo) {
    return (
      <button
        disabled
        className="btn btn-sm m-1 p-0 border-0"
        style={{ opacity: 0.3 }}
        title={t('NoAdditionalInfoAvailable')}>
        <Icon name="info-circle" />
      </button>
    )
  }

  return (
    <TooltipHandle
      name={key}
      label={<Icon name="info-circle" />}
      className="btn btn-sm m-1 p-0 border-0">
      <div>
        <b>{t('GpsLongitude')}:</b> {address.gps_longitude || '-'}
      </div>
      <div>
        <b>{t('GpsLatitude')}:</b> {address.gps_latitude || '-'}
      </div>
      <div>
        <b>{t('GpsAltitude')}:</b> {address.gps_altitude || '-'}
      </div>
      <div>
        <b>{t('AdditionalInformation')}:</b>{' '}
        {address.additional_information || '-'}
      </div>
    </TooltipHandle>
  )
}
