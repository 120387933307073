import React from 'react'
import { useTranslation } from 'react-i18next'
import { TooltipHandle } from '../TooltipHandle/TooltipHandle'

interface Props {
  id: string | number
  status: string | null
  size?: number
  yShift?: number
  className?: string
}

const unicodes: Record<string, string> = {
  A: '\u24B6',
  B: '\u24B7',
  C: '\u24B8',
  D: '\u24B9',
  E: '\u24BA',
  F: '\u24BB',
  G: '\u24BC',
  H: '\u24BD',
  I: '\u24BE',
  J: '\u24BF',
  K: '\u24C0',
  L: '\u24C1',
  M: '\u24C2',
  N: '\u24C3',
  O: '\u24C4',
  P: '\u24C5',
  Q: '\u24C6',
  R: '\u24C7',
  S: '\u24C8',
  T: '\u24C9',
  U: '\u24CA',
  V: '\u24CB',
  W: '\u24CC',
  X: '\u24CD',
  Y: '\u24CE',
  Z: '\u24CF',
  '?': '\u2298',
}

const getUnicodeFromStatus = (name: string | null): string => {
  const statusInitial = name ? name[0].toLocaleUpperCase() : '?'
  return unicodes[statusInitial] ?? unicodes['?']
}

const StatusRenderer: React.FC<Props> = (props: Props) => {
  const { status } = props
  const { t } = useTranslation()

  const unicode = getUnicodeFromStatus(status)
  const translatedTooltip = t(
    `StatusRendererTooltip_${status ? status.toLocaleUpperCase() : '?'}`
  )
  let tooltip = status
  if (!translatedTooltip.includes('StatusRendererTooltip')) {
    tooltip = translatedTooltip
  }

  const cssClasses = ['btn', 'p-0', 'm-0', 'border-0']
  if (props.className && props.className.length) {
    cssClasses.push(props.className)
  }

  if (!props.id) return null
  return (
    <TooltipHandle
      data-semantic-id="status-renderer"
      data-semantic-context={`${props.id}`}
      name={`${props.id}`}
      className={cssClasses.join(' ')}
      label={
        <div
          style={
            props.size
              ? {
                  position: 'relative',
                  fontSize: `${props.size}px`,
                  top: `${props.yShift ?? 0}em`,
                }
              : {
                  position: 'relative',
                  top: `${props.yShift ?? 0}em`,
                }
          }>
          {unicode}
        </div>
      }>
      {tooltip}
    </TooltipHandle>
  )
}

export default StatusRenderer
