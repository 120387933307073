import React, { useState } from 'react'
import styles from './Header.module.scss'
import Logo from '../Logo/Logo'
import config from '../../../config'
import Icon from '../Icon/Icon'
import { LayoutProps } from '../Layout/Layout'
import HelpMenu from '../HelpMenu/HelpMenu'
import ConfigMenu from '../ConfigMenu/ConfigMenu'
import UserMenu from '../UserMenu/UserMenu'

const Header: React.FC<LayoutProps> = () => {
  const [navbarOpen, toggleNavbar] = useState<boolean>(false)

  return (
    <nav
      className={`navbar navbar-expand-sm navbar-light fixed-top ${styles.navbar}`}>
      <div className={'container-fluid'}>
        <div className="navbar-header pt-1 d-flex">
          <Logo />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar"
          aria-controls="navbar"
          aria-expanded={navbarOpen}
          aria-label="Toggle navigation"
          onClick={() => toggleNavbar(!navbarOpen)}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`}
          id="navbar">
          <div className="navbar-nav ml-auto">
            <ConfigMenu />
            <HelpMenu />
            <UserMenu />
            {config.AUTH_LOGOUT_URL && (
              <a href={config.AUTH_LOGOUT_URL} className="nav-item nav-link">
                <Icon name="box-arrow-right" />
              </a>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}
export default Header
