import { useCallback, useRef, useState } from 'react'

// Adapted from https://davidwalsh.name/javascript-debounce-function
// Note that we could pull lodash for this but it's really not worth the
// download size and we only need it here right now
function debounce<T, U = any>(
  wait: number,
  immediate: boolean,
  fn: (value: T) => void
): (value: T) => void {
  let timeout: any
  return function (this: U, ...args) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this
    const later = () => {
      timeout = null
      if (!immediate) {
        fn.apply(context, args)
      }
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      fn.apply(context, args)
    }
  }
}

export default function useDebouncedState<T>(
  wait: number,
  init: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValueSync] = useState(init)
  const fnMemo = useRef(
    debounce<T>(wait, false, value => {
      setValueSync(value)
    })
  )
  const setValue = useCallback(
    (value: any) => {
      fnMemo.current(value)
    },
    [fnMemo]
  )
  return [value, setValue]
}
