import React, { ReactNode } from 'react'

import styles from './NoDetailsFound.module.scss'

import Icon from '../Icon/Icon'

interface Props {
  children?: ReactNode
}

export const NoDetailsFound: React.FC<Props> = (props: Props) => (
  <>
    <div className="position-relative d-flex align-items-center justify-content-center">
      <Icon name="grid-3x3-gap-fill" className={styles.iconBack} />
      <Icon name="search" className={`position-absolute ${styles.iconFront}`} />
    </div>
    {props.children && <div className={styles.label}>{props.children}</div>}
  </>
)
