import React from 'react'

import { Company } from '../../../interface/Company'
import { MasterDataCompany } from '../../../interface/MasterDataCompany'
import AddressInline from '../AddressInline/AddressInline'
import Icon from '../Icon/Icon'
import { TooltipHandle } from '../TooltipHandle/TooltipHandle'
import CompanyName from '../CompanyName/CompanyName'

interface Props {
  idPrefix?: string
  company: Company
  assignedCompany?: MasterDataCompany | null
  showAddress?: boolean
  showId?: boolean
  showExternalNumbers?: boolean
  showTooltip?: boolean
  overflow?: boolean
}

export const CompanyLocation: React.FC<Props> = (props: Props) => {
  if (!props.company) return null

  const {
    idPrefix,
    showAddress,
    showExternalNumbers,
    showId,
    showTooltip,
    overflow,
  } = props
  const { address, address_errors } = props.company

  const name = `comploc-${Math.random()}`.replace(/\D/g, '')
  const inlineAddress = address && (
    <AddressInline
      address={address}
      idPrefix={idPrefix}
      errors={address_errors}
      showId={showId}
      showExternalNumbers={showExternalNumbers}
    />
  )

  return (
    <span className="d-flex">
      {showAddress && address && (
        <span
          className={`tag ${overflow ? 'overflow-ellipsis text-nowrap' : ''}`}>
          {showTooltip ? (
            <TooltipHandle
              className=" "
              name={name}
              label={inlineAddress}
              isButton={false}
              align="left"
              trigger="hover">
              {inlineAddress}
            </TooltipHandle>
          ) : (
            inlineAddress
          )}
        </span>
      )}
      {props.assignedCompany && (
        <span
          className={`tag ${overflow ? 'overflow-ellipsis text-nowrap' : ''}`}>
          <Icon name="chevron-right" className="px-2" />
          <CompanyName company={props.assignedCompany} />
        </span>
      )}
    </span>
  )
}
