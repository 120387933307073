import { ClusterCompany } from '../../../interface/ClusterCompany'
import { useTranslation } from 'react-i18next'
import { IconPill } from '../IconPill/IconPill'
import React from 'react'

const CommentLabelType = ['Company', 'Address'] as const
type CommentType = (typeof CommentLabelType)[number]

export const determineCommentLabel = (
  company: { status: string | null },
  isAssigned: boolean,
  type: CommentType
) => {
  if (company.status?.toUpperCase() === 'UNQUALIFIED') {
    return `${type}WillBeQualified`
  } else {
    if (isAssigned) {
      return `${type}WillBeMerged`
    } else {
      return `${type}WillBeReferenced`
    }
  }
}

interface Props {
  company: ClusterCompany
  isAssigned: boolean
  showMergeTarget: boolean
  mergeTargetId?: number
  type: CommentType
  className?: string
  'data-semantic-id'?: string
}

export const MergeComment = (props: Props) => {
  const {
    company,
    isAssigned,
    showMergeTarget = false,
    mergeTargetId,
    type,
    className,
  } = props
  const cssClasses = []
  if (className && className.length) {
    cssClasses.push(className)
  }

  const { t } = useTranslation()

  const key = determineCommentLabel(company, isAssigned, type)
  if (!key) {
    return null
  }

  const displayText =
    showMergeTarget && mergeTargetId
      ? `${t(`${key}_Target`, { mergeTarget: mergeTargetId })}`
      : `${t(key)}`

  return (
    <IconPill data-semantic-id={props['data-semantic-id']}>
      {displayText}
    </IconPill>
  )
}
