import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import useDebouncedState from '../../../hooks/useDebouncedState'
import { useDropdown } from '../../../hooks/useDropdown'

import styles from './Autocomplete.module.scss'

interface Props {
  id: string
  label?: ReactNode
  'data-semantic-id'?: string
  inputClassName?: string
  formGroupClassName?: string
  onFetchData: (term: string) => Promise<{ items: Item[]; count: number }>
  onSelect?: (item: Item) => void
  defaultValue?: string
  slotRight?: ReactNode
  disabled?: boolean
}

interface Item {
  name: string
}

export const Autocomplete: React.FC<Props> = (props: Props) => {
  const {
    onFetchData,
    onSelect,
    defaultValue,
    disabled,
    inputClassName,
    formGroupClassName,
  } = props
  const { t } = useTranslation()

  const [isFocused, setIsFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [selectedValue, setSelectedValue] = useState(inputValue)
  const [searchTerm, setSearchTerm] = useDebouncedState(200, inputValue)
  const [items, setItems] = useState<Item[]>([])
  const [itemIndex, setItemIndex] = useState(-1)
  const [itemCount, setItemCount] = useState(0)

  const shrinkLabel = isFocused || inputValue || props.defaultValue

  const [ref, expanded, setExpanded] = useDropdown<HTMLDivElement>()
  const dropdownId = useRef('')

  const inputFieldClass: string[] = []
  if (inputClassName && inputClassName.length) {
    inputFieldClass.push(inputClassName)
  }

  const formGroupClass: string[] = []
  if (formGroupClassName && formGroupClassName.length) {
    formGroupClass.push(formGroupClassName)
  }

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue)
    }
  }, [defaultValue])

  const selectItemByIndex = useCallback(
    (index: number) => {
      if (!onSelect || !isFinite(index) || index < 0) {
        return
      }
      const item = items[index]
      onSelect(item)
      setExpanded(false)
      setInputValue(item.name)
      setSelectedValue(item.name)
    },
    [items, setExpanded, onSelect]
  )

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])
  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue((ev.target as any).value)
    },
    []
  )
  const handleFocus = useCallback(() => {
    setExpanded(true)
    setIsFocused(true)
  }, [setExpanded])
  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      switch (ev.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          setItemIndex(i => (i + 1) % items.length)
          ev.preventDefault()
          break
        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
          setItemIndex(i =>
            i === 0 ? items.length - 1 : (i - 1) % items.length
          )
          ev.preventDefault()
          break
        case 'Enter':
          selectItemByIndex(itemIndex)
          ev.preventDefault()
          break
        case 'Esc': // IE/Edge specific value
        case 'Escape':
          setItemIndex(-1)
          setExpanded(false)
          setInputValue(selectedValue)
          break
        case 'Tab':
          // Make sure to close the dropdown when tabbing away
          setExpanded(false)
          setInputValue(selectedValue)
          break
      }
    },
    [itemIndex, items, setExpanded, selectItemByIndex, selectedValue]
  )
  const handleInput = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      const value = (ev.target as any).value
      setSearchTerm(value)
      setInputValue(value)
    },
    [setSearchTerm]
  )
  const handleItemClick = useCallback(
    (ev: React.MouseEvent<HTMLLIElement>) => {
      const index = parseFloat(
        ev.currentTarget?.getAttribute('data-option-index') ?? '-1'
      )
      selectItemByIndex(index)
    },
    [selectItemByIndex]
  )

  useEffect(() => {
    if (!isFocused) {
      return
    }
    const get = async () => {
      setIsLoading(true)
      try {
        const data = await onFetchData(searchTerm)
        setItems(data?.items ?? [])
        setItemCount(data.count)
        setItemIndex(-1)
        if (isFocused) {
          // Re-open dropdown if we're still inside the input
          setExpanded(true)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    }
    get()
  }, [searchTerm, onFetchData, isFocused, setExpanded])

  useEffect(() => {
    if (dropdownId.current) {
      return
    }
    dropdownId.current = `dd-${`${Math.random()}`.replace(/[^\d]/g, '')}`
  }, [dropdownId])

  return (
    <>
      <div
        ref={ref}
        className={`form-group dropdown ${formGroupClass.join(' ')} ${
          styles.chevron
        } ${styles.chevron} ${expanded ? 'show' : ''}`}>
        {props.label && (
          <label
            htmlFor={props.id}
            className={`${styles.label} ${shrinkLabel ? styles.focused : ''}`}>
            {props.label}
          </label>
        )}
        <input
          id={props.id}
          value={inputValue}
          aria-expanded={expanded ? 'true' : 'false'}
          disabled={disabled}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onInput={handleInput}
          data-semantic-id={props['data-semantic-id']}
          aria-owns={dropdownId.current}
          className={`form-control pb-0 ${inputFieldClass.join(' ')}`}
          autoCapitalize="none"
          type="text"
          autoComplete="off"
          aria-autocomplete="list"
          role="combobox"
        />
        {props.slotRight && (
          <div className={styles.slotRight}>{props.slotRight}</div>
        )}
        {expanded && (
          <ul
            id={dropdownId.current}
            role="listbox"
            className={`dropdown-menu w-100 show ${styles.dropdown}`}>
            {items.map((item, i) => (
              <li
                aria-selected={itemIndex === i ? 'true' : 'false'}
                data-option-index={i}
                id={`autocomplete_${i}`}
                key={`${i}-${item.name}`}
                onClick={handleItemClick}
                className="dropdown-item overflow-auto"
                role="option"
                tabIndex={-1}>
                {item.name}
              </li>
            ))}
            <li className="dropdown-divider"></li>
            <li
              aria-live="polite"
              role="status"
              className="dropdown-item disabled">
              {isLoading ? (
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated w-100"
                    role="progressbar"
                    aria-valuenow={1}
                    aria-valuemin={0}
                    aria-valuemax={1}></div>
                </div>
              ) : (
                <>
                  {itemCount} {t('SearchResultsAvailable')}
                </>
              )}
            </li>
          </ul>
        )}
      </div>
    </>
  )
}
