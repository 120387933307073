import React, { ReactNode, Suspense, useContext } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GspCluster } from '../../GspCluster/GspCluster'
import GspDedup from '../../GspDedup/GspDedup'
import Spinner from '../Spinner/Spinner'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import useRouting from '../../../hooks/useRouting'
import GspClusterJobOverview from '../../GspCluster/GspClusterJobOverview'
import GspImportJobOverview from '../../GspDedup/GspImportJobOverview'
import { Capability, UserContext } from '../../../contexts/UserContext'
import BootstrapAlert from '../BootstrapAlert/BootstrapAlert'
import GspDashboard from '../../GspDashboard/GspDashboard'
import GspMatchingConfigOverview from '../../GspMatchingConfig/GspMatchingConfigOverview'

import styles from './Layout.module.scss'

const GspMatchingConfig = React.lazy(
  () => import('../../GspMatchingConfig/GspMatchingConfig')
)

const GspAppConfig = React.lazy(() => import('../../GspAppConfig/GspAppConfig'))

const NewClusterJob = () => {
  const { query } = useRouting()
  const match = useRouteMatch()
  const key = `${match.url}`
  return (
    <GspCluster
      key={key}
      company_name={query.company_name}
      city={query.city}
      street={query.street}
      country={query.country}
      // To support the regex search as default for a new form we implicitly interpret all cases !== "false" as true
      fields_as_regex={query.fields_as_regex !== 'false'}
      matching_configuration_id={query.matching_configuration_id}
      start={query.start_clustering === 'true'}
    />
  )
}

const ClusterJobById = () => {
  const { jobid } = useParams<{ jobid: string }>()
  const match = useRouteMatch()
  const key = `${match.url}`
  return <GspCluster key={key} jobId={parseFloat(jobid)} />
}

const ImportJobById = () => {
  const { jobid } = useParams<{ jobid: string }>()
  const match = useRouteMatch()
  const key = `${match.url}`
  return <GspDedup key={key} jobId={jobid ? jobid : 0} />
}

const MatchingConfigById = (props: { edit?: boolean; create?: boolean }) => {
  const { configid } = useParams<{ configid: string }>()
  const match = useRouteMatch()
  const key = `${match.url}`
  return (
    <Suspense fallback={<Spinner />}>
      <GspMatchingConfig
        key={key}
        configId={configid}
        edit={props.edit}
        create={props.create}
      />
    </Suspense>
  )
}

const AccessDenied = () => {
  const { t } = useTranslation()
  return (
    <div data-semantic-id="access-denied">
      <BootstrapAlert type="danger" message={t('AccessDenied')} />
    </div>
  )
}

export interface LayoutProps {
  children?: ReactNode
}

const PageLayout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { can } = useContext(UserContext)
  return (
    <div
      className={`d-flex flex-column justify-content-start ${styles.pageLayout}`}>
      <Header />
      <div className={'container-fluid px-5 py-4'}>
        {can(Capability.AccessApp) ? props.children : <AccessDenied />}
      </div>
      <Footer />
    </div>
  )
}

const Layout: React.FC = () => {
  const { can } = useContext(UserContext)
  return (
    <Switch>
      <Route path="/appconfig/edit">
        <PageLayout>
          {can(Capability.WriteAppConfig) ? (
            <Suspense fallback={<Spinner />}>
              <GspAppConfig edit />
            </Suspense>
          ) : (
            <AccessDenied />
          )}
        </PageLayout>
      </Route>
      <Route path="/appconfig">
        <PageLayout>
          {can(Capability.ReadAppConfig) ? (
            <Suspense fallback={<Spinner />}>
              <GspAppConfig />
            </Suspense>
          ) : (
            <AccessDenied />
          )}
        </PageLayout>
      </Route>
      <Route path="/clusterjobs/new">
        <PageLayout>
          {can(Capability.WriteClusterjobs) ? (
            <NewClusterJob />
          ) : (
            <AccessDenied />
          )}
        </PageLayout>
      </Route>
      <Route path="/clusterjobs/:jobid">
        <PageLayout>
          <ClusterJobById />
        </PageLayout>
      </Route>
      <Route path="/clusterjobs">
        <PageLayout>
          <GspClusterJobOverview />
        </PageLayout>
      </Route>
      <Route path="/importjobs/:jobid">
        <PageLayout>
          <ImportJobById />
        </PageLayout>
      </Route>
      <Route path="/importjobs">
        <PageLayout>
          <GspImportJobOverview />
        </PageLayout>
      </Route>
      <Route path="/matchingconfigs/new" exact>
        <PageLayout>
          {can(Capability.WriteMatchingConfig) ? (
            <MatchingConfigById create edit />
          ) : (
            <AccessDenied />
          )}
        </PageLayout>
      </Route>
      <Route path="/matchingconfigs/current" exact>
        <PageLayout>
          {can(Capability.ReadMatchingConfig) ? (
            <MatchingConfigById />
          ) : (
            <AccessDenied />
          )}
        </PageLayout>
      </Route>
      <Route path="/matchingconfigs/:configid/edit">
        <PageLayout>
          {can(Capability.WriteMatchingConfig) ? (
            <MatchingConfigById edit />
          ) : (
            <AccessDenied />
          )}
        </PageLayout>
      </Route>
      <Route path="/matchingconfigs/:configid">
        <PageLayout>
          {can(Capability.ReadMatchingConfig) ? (
            <MatchingConfigById />
          ) : (
            <AccessDenied />
          )}
        </PageLayout>
      </Route>
      <Route path="/matchingconfigs">
        <PageLayout>
          {can(Capability.ReadMatchingConfig) ? (
            <GspMatchingConfigOverview />
          ) : (
            <AccessDenied />
          )}
        </PageLayout>
      </Route>
      <Route path="/">
        <PageLayout>
          <GspDashboard />
        </PageLayout>
      </Route>
      <Route>{null}</Route>
    </Switch>
  )
}

export default Layout
