import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'
import styles from './ClusterJobCluster.module.scss'
import UseTransition from '../../../UI/UseTransition/UseTransition'
import ClusterJobCompany from '../ClusterJobCompany/ClusterJobCompany'
import { Cluster } from '../../../../interface/Cluster'
import { Address } from '../../../../interface/Address'
import ClusterContext from '../../ClusterContext'
import ClusterState from '../../ClusterState/ClusterState'
import ClusterJobs, {
  AddCompanyToClusterStatusTypes,
  AddCompanyToClusterType,
} from '../../../../services/ClusterJobs/ClusterJobs'
import useApi from '../../../../hooks/useApi'
import { ClusterUpdate } from '../../../../interface/ClusterUpdate'
import BootstrapAlert from '../../../UI/BootstrapAlert/BootstrapAlert'
import {
  ClusterCompany,
  ClusterCompanyNames,
} from '../../../../interface/ClusterCompany'
import Icon from '../../../UI/Icon/Icon'
import CompanyName from '../../../UI/CompanyName/CompanyName'
import { CompanyLocation } from '../../../UI/CompanyLocation/CompanyLocation'
import IdRenderer from '../../../UI/IdRenderer/IdRenderer'
import LeadingClusterJobCompany from '../ClusterJobCompany/LeadingClusterJobCompany'
import { TooltipHandle } from '../../../UI/TooltipHandle/TooltipHandle'
import ClusterJobStickyHeader from '../ClusterJobStickyHeader/ClusterJobStickyHeader'
import ClusterJobButtons from '../ClusterJobButtons/ClusterJobButtons'
import StatusRenderer from '../../../UI/StatusRenderer/StatusRenderer'
import Input from '../../../UI/Input/Input'
import CompanySource from '../../../UI/CompanySource/CompanySource'
import CopyableField from '../../../UI/CopyableField/CopyableField'
import CMDSearch from '../../../UI/CMDSearch/CMDSearch'
import { Modal } from '../../../UI/Modal/Modal'
import useModal from '../../../../hooks/useModal'
import { ResponseObject } from '../../../../interface/ResponseObject'

const updateCluster = async (
  jobId: number,
  clusterId: number,
  clusterUpdate?: ClusterUpdate
) => {
  return await new ClusterJobs().updateCluster(jobId, clusterId, clusterUpdate)
}

const finalizeSingleCluster = async (jobId: number, clusterId: number) => {
  return await new ClusterJobs().finalizeCluster(jobId, clusterId)
}

const unsaveSingleCluster = async (jobId: number, clusterId: number) => {
  return await new ClusterJobs().unsaveCluster(jobId, clusterId)
}

const addCompanyToCluster = async (
  jobId: number,
  clusterId: number,
  companyId: number,
  allowMove?: boolean
): Promise<ResponseObject<Cluster | AddCompanyToClusterType>> => {
  return await new ClusterJobs().addCompanyToCluster(
    jobId,
    clusterId,
    companyId,
    allowMove
  )
}

interface Props {
  jobId: number
  cluster: Cluster
  editableStatus: boolean
  jobIsPermittedAndAssigned: boolean
  onUpdateCluster: (
    cluster: Cluster,
    companyId?: number,
    addressId?: number,
    merge?: boolean
  ) => void
}

const ClusterJobCluster: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const {
    jobId,
    cluster,
    editableStatus,
    jobIsPermittedAndAssigned,
    onUpdateCluster,
  } = props
  const { leading_company } = cluster
  const [contentVisible, setContentVisible] = useState(false)
  const [showCompanyNameForm, setShowCompanyNameForm] = useState(false)
  const [clusterState, setClusterState] = useState<ClusterState | null>(null)
  const [referencedCompaniesExpanded, setReferencedCompaniesExpanded] =
    useState(false)
  const [mergeOptionsExpanded, setMergeOptionsExpanded] = useState(true)
  const [companyId, setCompanyId] = useState<string>('')
  const [error, setError] = useState<ResponseObject<Cluster> | null>()

  const { isOpen, toggle, setIsOpen } = useModal()

  const {
    call: saveCluster,
    error: saveError,
    isLoading: isSaving,
    response: saveResponse,
  } = useApi(
    t,
    'ErrorOccurred',
    useCallback(
      () =>
        updateCluster(
          jobId,
          cluster.id,
          ClusterState.buildClusterUpdate(clusterState, leading_company)
        ),
      [jobId, cluster.id, clusterState, leading_company]
    )
  )

  const {
    call: changeLeadingCompany,
    error: leadingCompanyError,
    isLoading: isChangingLeadingCompany,
    response: leadingCompanyResponse,
  } = useApi<Cluster, ClusterUpdate>(
    t,
    'ErrorOccurred',
    useCallback(
      data => updateCluster(jobId, cluster.id, data),
      [jobId, cluster]
    )
  )

  const {
    call: finalizeCluster,
    error: finalizeError,
    isLoading: isFinalizing,
    response: finalizeClusterResponse,
  } = useApi(
    t,
    'FinalizeClusterJobFailed',
    useCallback(
      () => finalizeSingleCluster(jobId, cluster.id),
      [jobId, cluster]
    )
  )

  const {
    call: unsaveCluster,
    error: unsaveError,
    isLoading: isUnsaving,
    response: unsavedClusterResponse,
  } = useApi(
    t,
    'ErrorOccurred',
    useCallback(
      () => unsaveSingleCluster(jobId, cluster.id),
      [jobId, cluster.id]
    )
  )

  const {
    call: addCompany,
    isLoading: addingCompany,
    response: addedCompanyResponse,
  } = useApi<
    Cluster | AddCompanyToClusterType,
    { companyId: number; allowMove?: boolean }
  >(
    t,
    'ErrorOccurred',
    useCallback(
      (data: { companyId: number; allowMove?: boolean }) =>
        addCompanyToCluster(jobId, cluster.id, data.companyId, data?.allowMove),
      [jobId, cluster.id]
    ),
    true
  )

  const toggleContentVisible = useCallback((ev: React.SyntheticEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    setContentVisible(prev => !prev)
  }, [])

  const toggleReferencedCompanies = useCallback((ev: React.SyntheticEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    setReferencedCompaniesExpanded(prev => !prev)
  }, [])

  const toggleMergeOptions = useCallback((ev: React.SyntheticEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    setMergeOptionsExpanded(prev => !prev)
  }, [])

  const handleLeadingCompanyNameUpdate = (
    companyNames: ClusterCompanyNames
  ) => {
    const newCluster = {
      ...cluster,
      leading_company: { ...cluster.leading_company, ...companyNames },
    }
    if (onUpdateCluster) onUpdateCluster(newCluster)
  }

  const handleCompanyUpdate = (company: ClusterCompany) => {
    const companies = cluster.companies ? [...cluster.companies] : []
    const index = companies.findIndex(curComp => curComp.id === company.id)
    companies[index] = { ...company }

    const newCluster = {
      ...cluster,
      companies: [...companies],
    }
    if (onUpdateCluster) onUpdateCluster(newCluster)
  }

  const handleSave = useCallback(() => {
    saveCluster()
  }, [saveCluster])

  const handleUnsave = useCallback(() => {
    unsaveCluster()
  }, [unsaveCluster])

  const handleFinalize = useCallback(() => {
    finalizeCluster()
  }, [finalizeCluster])

  const handleAssignmentChanged = useCallback(
    (company: ClusterCompany, address?: Address) => {
      if (address) {
        setClusterState(prev =>
          ClusterState.toggleAddress(company, address, prev)
        )
      } else {
        setClusterState(prev => ClusterState.toggleCompany(company, prev))
      }
    },
    []
  )

  const handleSelectCompanies = useCallback(
    (selectAll: boolean) => {
      setClusterState(prev =>
        ClusterState.toggleCompanies(cluster, prev, selectAll)
      )
    },
    [cluster]
  )

  /**
   * modified useApi call method is used, otherwise the old state is used for the request.
   * The state is still updated so that it remains correct for further modifications.
   */
  const handleChangeLeadingCompany = useCallback(
    (company: ClusterCompany) => {
      const clusterUpdate = ClusterState.buildClusterUpdate(clusterState)
      changeLeadingCompany({
        ...clusterUpdate,
        leading_company: company.id,
        address_change_mapping: {},
      } as ClusterUpdate)
      setClusterState(prev => ClusterState.setLeadingCompany(company, prev))
    },
    [clusterState, changeLeadingCompany]
  )

  const handleChangeAddressMapping = useCallback(
    (mergeIds: string[], targetId: string) => {
      setClusterState(prev =>
        ClusterState.setAddressChangeMapping(mergeIds, targetId, prev)
      )
    },
    []
  )

  const handleAddCompanyId = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      if (ev.currentTarget) {
        if (!isNaN(Number(ev.currentTarget.value))) {
          setCompanyId(ev.currentTarget.value)
        }
      }
    },
    []
  )

  const handleAddCompany = useCallback(
    (allowMove?: boolean) => {
      if (companyId.length)
        addCompany({ companyId: +companyId, allowMove: allowMove })
    },
    [addCompany, companyId]
  )

  const handleCloseModal = useCallback(
    (resetId = true) => {
      if (resetId) setCompanyId('')
      setError(null)
      toggle()
    },
    [toggle]
  )

  useEffect(() => {
    if (cluster.unassigned_companies && cluster.unassigned_companies.length) {
      setClusterState(prev =>
        ClusterState.setUnassignedCompanies(
          cluster.unassigned_companies ?? [],
          prev
        )
      )
    }
    let companies = cluster.companies ?? []
    if (cluster.unassigned_companies && cluster.unassigned_companies.length) {
      companies = [...companies]
    }
    setClusterState(prev =>
      ClusterState.setUnassignedAddresses(companies, prev)
    )
    setClusterState(prev =>
      ClusterState.setLeadingCompany(cluster.leading_company, prev)
    )
    setClusterState(prev =>
      ClusterState.setInitialAddressChangeMapping(
        cluster.leading_company,
        [...(cluster.companies ?? []), ...(cluster.unassigned_companies ?? [])],
        cluster.address_change_mapping,
        prev
      )
    )
  }, [cluster, jobId])

  useEffect(() => {
    if (saveResponse?.success && saveResponse.data) {
      setContentVisible(true)
      /**
       * A manual save request (via the toggle) should also change the toggle value in the same direction.
       * Therefore, the checked parameter is manually changed.
       */
      onUpdateCluster({ ...saveResponse.data, checked: true })
    }
  }, [saveResponse, onUpdateCluster])

  useEffect(() => {
    if (unsavedClusterResponse?.success && unsavedClusterResponse.data) {
      setContentVisible(true)
      /**
       * A manual unsave request (via the toggle) should also change the toggle value in the same direction.
       * Therefore, the checked parameter is manually changed.
       */
      onUpdateCluster({ ...unsavedClusterResponse.data, checked: false })
    }
  }, [unsavedClusterResponse, onUpdateCluster])

  const isCluster = (
    obj: Cluster | AddCompanyToClusterType
  ): obj is Cluster => {
    return (
      obj && typeof obj === 'object' && 'id' in obj && 'leading_company' in obj
    )
  }

  const displayAddCompanyConflictMessage = (
    data?: Cluster | AddCompanyToClusterType
  ) => {
    let message = 'ErrorOccurred'
    if (!data) return message

    if (!isCluster(data)) {
      switch (data) {
        case AddCompanyToClusterStatusTypes.STATUS_COMPANY_PART_OF_FINALIZED_CLUSTER:
          message = 'MoveCompanyFromFinalizedToCluster'
          break
        case AddCompanyToClusterStatusTypes.STATUS_COMPANY_IS_LEADING_COMPANY_OF_ANOTHER_CLUSTER:
          message = 'MergeClusters'
          break
        case AddCompanyToClusterStatusTypes.STATUS_COMPANY_PART_OF_ANOTHER_CLUSTER:
          message = 'MoveCompanyFromAnotherToCluster'
          break
      }
    }
    return message
  }

  useEffect(() => {
    if (finalizeClusterResponse?.success && finalizeClusterResponse.data) {
      setContentVisible(false)
      onUpdateCluster(finalizeClusterResponse.data)
    }
  }, [finalizeClusterResponse, onUpdateCluster])

  useEffect(() => {
    if (leadingCompanyResponse?.success && leadingCompanyResponse.data) {
      setContentVisible(true)
      /**
       * Change of leading company should not update the checked toggle as the request in the backend cannot be
       * differentiated from a manual save request (via the toggle). Therefore the toggle display is changed here
       * manually, therefore the checked parameter is manually changed
       */
      onUpdateCluster({ ...leadingCompanyResponse.data, checked: false })
    }
  }, [leadingCompanyResponse, onUpdateCluster])

  const prevUpdateRef =
    useRef<ResponseObject<Cluster | AddCompanyToClusterType>>()
  // handle add company success case and update clusters
  useEffect(() => {
    if (
      addedCompanyResponse &&
      addedCompanyResponse.data &&
      isCluster(addedCompanyResponse.data) &&
      prevUpdateRef.current !== addedCompanyResponse
    ) {
      setContentVisible(true)

      // both clusters where merged in be
      let toMerge = false
      if (
        prevUpdateRef.current?.data &&
        !isCluster(prevUpdateRef.current.data) &&
        prevUpdateRef.current?.data ===
          AddCompanyToClusterStatusTypes.STATUS_COMPANY_IS_LEADING_COMPANY_OF_ANOTHER_CLUSTER
      ) {
        toMerge = true
      }
      onUpdateCluster(addedCompanyResponse.data, +companyId, undefined, toMerge)
      setCompanyId('')
      setIsOpen(false)
      prevUpdateRef.current = addedCompanyResponse
    }
  }, [addedCompanyResponse, companyId, onUpdateCluster, setIsOpen])

  // handle add company conflict case and open conflict modal
  useEffect(() => {
    if (
      addedCompanyResponse !== null &&
      addedCompanyResponse.data &&
      !isCluster(addedCompanyResponse.data) &&
      prevUpdateRef.current !== addedCompanyResponse
    ) {
      setIsOpen(true)
      prevUpdateRef.current = addedCompanyResponse
    }
  }, [addedCompanyResponse, companyId, onUpdateCluster, setIsOpen])

  useEffect(() => {
    setError(
      saveError ?? unsaveError ?? finalizeError ?? leadingCompanyError ?? null
    )
  }, [finalizeError, leadingCompanyError, saveError, unsaveError])

  const handleShowCompanyNameForm = useCallback(() => {
    setShowCompanyNameForm(prev => !prev)
  }, [setShowCompanyNameForm])

  const updateShowCompanyNameForm = useCallback(
    (status: boolean) => {
      setShowCompanyNameForm(status)
    },
    [setShowCompanyNameForm]
  )

  const handleLeadingCompanyCMDUpdate = useCallback(
    (companyId: string) => {
      changeLeadingCompany({
        leading_company: companyId,
      } as ClusterUpdate)
    },
    [changeLeadingCompany]
  )

  const companies = [
    ...(cluster.companies ?? []),
    ...(cluster.unassigned_companies ?? []),
  ]

  const associatedCompanies = leading_company.associated_companies
  const inputKey = `cjch-${leading_company.id}`
  const companyCount = companies.length
  const isExpanded = showCompanyNameForm || contentVisible
  const allCompaniesUnassigned =
    clusterState?._unassignedCompanyIds.size ===
    (cluster?.companies?.length ?? 0) +
      (cluster?.unassigned_companies?.length ?? 0)

  const allCompaniesAssigned = clusterState?._unassignedCompanyIds.size === 0
  const hasError = error?.message

  /**
   * To indicate that there are no companies (to edit), the checked toggle is set to true automatically,
   * but only if the cluster is saved, otherwise there is an asynchronicity between the states
   */
  const isChecked =
    cluster?.checked === true || (companies.length === 0 && cluster.saved)

  return (
    <ClusterContext.Provider value={clusterState}>
      <>
        <tr
          data-semantic-id="cluster-job-cluster"
          data-main-row
          data-id={cluster.id}
          role="button"
          className={isExpanded ? 'd-none' : ''}
          onClick={toggleContentVisible}>
          <td>
            <span className="badge badge-pill badge-primary badge-wide-custom">
              {companyCount}
            </span>
          </td>
          <td>
            <span className="overflow-ellipsis text-nowrap">
              <CopyableField>
                <CompanyName company={leading_company} showId={false} />
              </CopyableField>
            </span>
          </td>
          <td>
            <CopyableField>
              <IdRenderer id={leading_company.id} />
            </CopyableField>
          </td>
          <td>
            <StatusRenderer
              id={leading_company.id}
              status={leading_company.status}
            />
          </td>
          <td>
            {!contentVisible && (
              <CopyableField>
                <CompanyLocation
                  company={leading_company}
                  showAddress
                  showId={false}
                  showExternalNumbers
                />
              </CopyableField>
            )}
          </td>
          <td>
            {!contentVisible && leading_company.address && (
              <CopyableField>
                <IdRenderer id={leading_company.address.id} />
              </CopyableField>
            )}
          </td>
          <td>
            {leading_company.address && (
              <StatusRenderer
                id={leading_company.id}
                status={leading_company.address.status}
              />
            )}
          </td>
          <td
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
            }}
            data-semantic-id="cluster-job-buttons"
            data-editable={editableStatus ? 'true' : undefined}
            className={`text-right text-nowrap ${
              isExpanded ? '' : 'opacity-50'
            }`}>
            <ClusterJobButtons
              isExpanded={isExpanded}
              companyCount={companyCount}
              leadingCompany={leading_company}
              contentVisible={contentVisible}
              editable={editableStatus}
              isSaving={isSaving}
              isUnsaving={isUnsaving}
              isFinalizing={isFinalizing}
              isChecked={isChecked}
              jobIsPermittedAndAssigned={jobIsPermittedAndAssigned}
              showCompanyNameForm={showCompanyNameForm}
              cluster={cluster}
              handleSave={handleSave}
              handleUnsave={handleUnsave}
              handleFinalize={handleFinalize}
              handleShowCompanyNameForm={handleShowCompanyNameForm}
              inputKey={inputKey}
              toggleContentVisible={toggleContentVisible}
            />
          </td>
        </tr>
        <tr
          data-semantic-id="cluster-job-cluster-details"
          data-expanded={isExpanded ? 'true' : undefined}
          className={isExpanded ? styles.expandedBody : ''}>
          <td colSpan={8} className="p-0 bg-white">
            {/* The following component contains similar content as row #1 "cluster-job-cluster" of this component
            (ClusterJobCluster). If row #1 is clicked, it will be hidden and the entire content from row #2
            "cluster-job-cluster-details" will be displayed.  The following component is additionally displayed as a
            sticky element at the top of row #2 and shows reduced information from (the then hidden) row #1.
            */}
            <ClusterJobStickyHeader
              inputKey={inputKey}
              companyCount={companyCount}
              leadingCompany={leading_company}
              cluster={cluster}
              jobId={jobId}
              contentVisible={contentVisible}
              isExpanded={isExpanded}
              editable={editableStatus}
              isSaving={isSaving}
              isUnsaving={isUnsaving}
              isFinalizing={isFinalizing}
              isChecked={isChecked}
              jobIsPermittedAndAssigned={jobIsPermittedAndAssigned}
              showCompanyNameForm={showCompanyNameForm}
              handleSave={handleSave}
              handleUnsave={handleUnsave}
              handleFinalize={handleFinalize}
              handleShowCompanyNameForm={handleShowCompanyNameForm}
              handleAssignmentChanged={handleAssignmentChanged}
              handleLeadingCompanyNameUpdate={handleLeadingCompanyNameUpdate}
              updateShowCompanyNameForm={updateShowCompanyNameForm}
              toggleContentVisible={toggleContentVisible}
            />

            {isExpanded &&
              !cluster.finalized &&
              cluster.leading_company_changeable && (
                <div className={'text-right'}>
                  <CMDSearch
                    onUpdate={handleLeadingCompanyCMDUpdate}
                    clusterId={cluster.id}
                    jobId={jobId}
                  />
                </div>
              )}
            <TransitionGroup>
              {isExpanded && (
                <UseTransition
                  name="JobCompanyForm"
                  type="dropdown"
                  in={true}
                  timeout={300}>
                  <div className="bg-white">
                    <LeadingClusterJobCompany
                      className="pb-4"
                      jobId={jobId}
                      clusterId={cluster.id}
                      company={cluster.leading_company}
                      editable={
                        editableStatus &&
                        !cluster.readonly &&
                        !cluster.finalized &&
                        !cluster.leading_company.readonly
                      }
                      handleShowCompanyNameForm={handleShowCompanyNameForm}
                      jobIsPermittedAndAssigned={jobIsPermittedAndAssigned}
                      showCompanyNameForm={showCompanyNameForm}
                      onAssignmentChanged={handleAssignmentChanged}
                      onUpdate={handleLeadingCompanyNameUpdate}
                      unUpdateCluster={onUpdateCluster}
                    />
                  </div>
                </UseTransition>
              )}
              {contentVisible && (
                <UseTransition
                  name="MasterDataCompanies"
                  type="dropdown"
                  in={true}
                  timeout={300}>
                  <div
                    data-semantic-id="cluster-companies"
                    className="companies">
                    {hasError && (
                      <div data-semantic-id="cluster-companies-error">
                        <BootstrapAlert
                          type="danger"
                          message={error?.message}
                          status={error?.system}
                        />
                      </div>
                    )}
                    {associatedCompanies?.length > 0 && (
                      <div
                        data-semantic-id="cluster-company-associated-companies"
                        className={`py-4 ${styles.bgMuted}`}>
                        <div className="px-5 mb-0">
                          <button
                            data-semantic-id="cluster-company-toggle-referenced-companies"
                            className="btn btn-sm "
                            onClick={toggleReferencedCompanies}>
                            <div className={'d-flex'}>
                              <h5 className={'my-0 mr-1'}>
                                {t('ReferencedCompanies')}
                              </h5>
                              {referencedCompaniesExpanded ? (
                                <Icon
                                  name="chevron-up"
                                  className={'my-0 pt-1'}
                                />
                              ) : (
                                <Icon
                                  name="chevron-down"
                                  className={'my-0 pt-1'}
                                />
                              )}
                            </div>
                          </button>
                        </div>
                        {referencedCompaniesExpanded && (
                          <div
                            data-semantic-id="cluster-company-referenced-companies"
                            className="px-5 ml-2">
                            <table className="table table-striped m-0">
                              <thead>
                                <tr>
                                  <th className="text-nowrap px-0" scope="col">
                                    {t('Source')}
                                  </th>
                                  <th className="text-nowrap pl-0" scope="col">
                                    {t('Company')}
                                  </th>
                                  <th className="text-nowrap" scope="col">
                                    {t('CompanyId')}
                                  </th>
                                  <th className="text-nowrap" scope="col">
                                    {t('Location')}
                                  </th>
                                  <th className="text-nowrap pr-0" scope="col">
                                    {t('LocationId')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {associatedCompanies.map((associated, i) => (
                                  <tr key={`assoc-${associated.id}-${i}`}>
                                    <td className="px-0">
                                      <CompanySource company={associated} />
                                    </td>
                                    <td className="pl-0">
                                      <CompanyName
                                        company={associated}
                                        showId={false}
                                      />
                                      {associated.merged_into_main && (
                                        <TooltipHandle
                                          name={`assoc-${leading_company.id}-${associated.id}`}
                                          className="btn btn-sm btn-link py-0 px-1"
                                          trigger="hover"
                                          label={
                                            <Icon name="box-arrow-in-up" />
                                          }>
                                          {t('CompanyWasMergedIntoMain')}
                                        </TooltipHandle>
                                      )}
                                      {associated.additional_addresses &&
                                        associated.additional_addresses
                                          ?.length > 0 && (
                                          <TooltipHandle
                                            name={`assoc-${leading_company.id}-${associated.id}-gte1`}
                                            className="btn btn-sm btn-link py-0 px-1"
                                            trigger="hover"
                                            label={<Icon name="info-circle" />}>
                                            {t(
                                              'MoreThanOneLocationForThisCompany'
                                            )}
                                          </TooltipHandle>
                                        )}
                                    </td>
                                    <td>
                                      <IdRenderer
                                        id={associated.id}
                                        status={associated.status}
                                      />
                                    </td>
                                    <td>
                                      <CompanyLocation
                                        company={associated}
                                        showAddress
                                        showId={false}
                                      />
                                    </td>
                                    <td className="pr-0">
                                      {associated.address && (
                                        <IdRenderer
                                          id={associated.address.id}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="py-4">
                      <div className={`d-flex align-items-center px-5 mb-1`}>
                        <button
                          data-semantic-id="cluster-company-toggle-merge-options"
                          className="btn btn-sm"
                          onClick={toggleMergeOptions}>
                          <div className={'d-flex'}>
                            <h5 className="my-0 mr-1">
                              {t('PossibleMergeOptions')}
                            </h5>
                            {mergeOptionsExpanded ? (
                              <Icon name="chevron-up" className={'my-0 pt-1'} />
                            ) : (
                              <Icon
                                name="chevron-down"
                                className={'my-0 pt-1'}
                              />
                            )}
                          </div>
                        </button>
                        {jobIsPermittedAndAssigned && mergeOptionsExpanded && (
                          <>
                            <button
                              data-semantic-id="select-all-companies"
                              className={`btn btn-sm pb-0 px-0 ${
                                allCompaniesAssigned ||
                                cluster.finalized ||
                                !editableStatus
                                  ? 'disabled opacity-25'
                                  : ''
                              }`}
                              onClick={() => handleSelectCompanies(true)}
                              disabled={
                                allCompaniesAssigned ||
                                cluster.finalized ||
                                !editableStatus
                              }>
                              <Icon
                                name="check-all"
                                title={t('SelectAllCompanies')}
                              />
                            </button>
                            <button
                              data-semantic-id="deselect-all-companies"
                              className={`btn btn-sm pb-0 px-0${
                                allCompaniesUnassigned ||
                                cluster.finalized ||
                                !editableStatus
                                  ? 'disabled opacity-25'
                                  : ''
                              }`}
                              onClick={() => handleSelectCompanies(false)}
                              disabled={
                                allCompaniesUnassigned ||
                                cluster.finalized ||
                                !editableStatus
                              }>
                              <Icon
                                name="dash-circle"
                                title={t('DeselectAllCompanies')}
                              />
                            </button>
                          </>
                        )}
                      </div>
                      {mergeOptionsExpanded && (
                        <div data-semantic-id="cluster-company-merge-options">
                          <ol className="list-unstyled assigned mb-0">
                            {companies.map(company => (
                              <li key={company.id}>
                                <ClusterJobCompany
                                  jobId={jobId}
                                  clusterId={cluster.id}
                                  company={company}
                                  leadingCompany={cluster.leading_company}
                                  hideAddress={true}
                                  editable={
                                    editableStatus &&
                                    !cluster.readonly &&
                                    !cluster.finalized &&
                                    !company.readonly
                                  }
                                  mergeable={
                                    editableStatus && !cluster.finalized
                                  }
                                  jobIsPermittedAndAssigned={
                                    jobIsPermittedAndAssigned
                                  }
                                  selectable={!cluster.finalized}
                                  leadingCompanyChangeable={
                                    cluster.leading_company_changeable
                                  }
                                  onAssignmentChanged={handleAssignmentChanged}
                                  onLeadingCompanyChanged={
                                    handleChangeLeadingCompany
                                  }
                                  showCompanyNameForm={showCompanyNameForm}
                                  onUpdate={handleCompanyUpdate}
                                  onSetAddressChangeMapping={
                                    handleChangeAddressMapping
                                  }
                                  isSaving={isChangingLeadingCompany}
                                  className={'pl-5'}
                                  showMergeTargetButton
                                />
                              </li>
                            ))}
                          </ol>
                          {jobIsPermittedAndAssigned && (
                            <div
                              className={
                                'row no-gutters text-nowrap pl-5 pt-3 w-25'
                              }>
                              <div className="col-9 pl-2">
                                <Input
                                  data-semantic-id="cluster-company-insert-company-id"
                                  id="addclustercompany"
                                  label={t('CompanyId')}
                                  cssClass={'m-0'}
                                  slotRight={
                                    !error ? (
                                      <TooltipHandle
                                        data-semantic-id="cluster-company-insert-company-id-tooltip"
                                        data-semantic-context={`${cluster.id}`}
                                        name={`${cluster.id}`}
                                        label={<Icon name={'info-circle'} />}
                                        className={''}>
                                        {t(`AddCompanyToCluster`)}
                                      </TooltipHandle>
                                    ) : (
                                      ''
                                    )
                                  }
                                  onChange={handleAddCompanyId}
                                  value={companyId}
                                  disabled={
                                    addingCompany ||
                                    !editableStatus ||
                                    cluster.finalized
                                  }
                                  error={error?.message}
                                />
                              </div>
                              <div className="col-3 mt-1">
                                <button
                                  data-semantic-id="cluster-company-add-company"
                                  disabled={
                                    addingCompany ||
                                    !companyId ||
                                    !editableStatus ||
                                    cluster.finalized
                                  }
                                  type="button"
                                  onClick={() => handleAddCompany(false)}
                                  className="btn btn-sm btn-secondary ml-3">
                                  {t('AddCompany')}
                                </button>
                              </div>
                              <Modal
                                data-semantic-id="cluster-company-add-company-modal"
                                isOpen={isOpen}
                                toggle={handleCloseModal}>
                                <div
                                  className="col pl-2 pt-1"
                                  data-semantic-id="cluster-company-add-company-message">
                                  {t(
                                    `${displayAddCompanyConflictMessage(
                                      addedCompanyResponse?.data
                                    )}`
                                  )}
                                  <div className="d-flex justify-content-end mt-2">
                                    <button
                                      data-semantic-id="cluster-company-add-company-permit"
                                      disabled={addingCompany}
                                      type="button"
                                      onClick={handleCloseModal}
                                      className="btn btn-sm btn-secondary mr-2">
                                      {t('No')}
                                    </button>
                                    <button
                                      data-semantic-id="cluster-company-add-company-allow"
                                      disabled={
                                        addingCompany ||
                                        !editableStatus ||
                                        cluster.finalized
                                      }
                                      type="button"
                                      onClick={() => {
                                        handleAddCompany(true)
                                        handleCloseModal(false)
                                      }}
                                      className="btn btn-sm btn-primary">
                                      {t('Yes')}
                                    </button>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </UseTransition>
              )}
            </TransitionGroup>
          </td>
        </tr>
      </>
    </ClusterContext.Provider>
  )
}

export default ClusterJobCluster
