import React, { Component, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'
import styles from './MasterDataCompanyRow.module.scss'
import { Address } from '../../../../../interface/Address'
import { MasterDataCompany } from '../../../../../interface/MasterDataCompany'
import AddressInline from '../../../../UI/AddressInline/AddressInline'
import Icon from '../../../../UI/Icon/Icon'
import RadioButton from '../../../../UI/RadioButton/RadioButton'
import UseTransition from '../../../../UI/UseTransition/UseTransition'
import CompanyName from '../../../../UI/CompanyName/CompanyName'
import IdRenderer from '../../../../UI/IdRenderer/IdRenderer'
import StatusRenderer from '../../../../UI/StatusRenderer/StatusRenderer'
import CompanySource from '../../../../UI/CompanySource/CompanySource'
import { TooltipHandle } from '../../../../UI/TooltipHandle/TooltipHandle'

interface Props {
  company?: MasterDataCompany
  JobCompanyId?: string
  defaultAddress?: Address | null
  selected: boolean
  onSelect: (id: string) => void
  index: number
  selectable: boolean
  jobIsPermittedAndAssigned: boolean
  children?: ReactNode
}

interface State {
  expanded: boolean
}

export class MasterDataCompanyRow extends Component<Props, State> {
  static defaultProps: { selectable: boolean }

  state: State = {
    expanded: false,
  }

  toggleAdditionalAdresses = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.company && event.currentTarget.checked) {
      this.props.onSelect(this.props.company.id)
    }
  }

  handleSelectCompany = () => {
    const { company, onSelect } = this.props
    if (company) {
      onSelect(company.id)
    }
  }

  render() {
    if (!this.props.company) return null
    const { jobIsPermittedAndAssigned } = this.props
    const company: MasterDataCompany = this.props.company
    const defaultAddress: Address = this.props.defaultAddress ??
      company.address ?? {
        id: 0,
        street: '',
        street_nat: '',
        city: '',
        city_nat: '',
        state: '',
        country: '',
        zip: '',
        external_numbers: [],
        gps_longitude: '',
        gps_latitude: '',
        gps_altitude: '',
        additional_information: '',
        status: '',
      }
    const isExpandable =
      company.additional_addresses && company.additional_addresses.length > 0
    const rowClass = [styles.row]
    const similarityClass = []
    let rowContext = ''

    if (company.match_percentage >= 90) {
      rowClass.push(styles.success)
      similarityClass.push('text-success')
      rowContext = 'success'
    } else if (company.match_percentage >= 80) {
      rowClass.push(styles.warning)
      similarityClass.push('text-warning')
      rowContext = 'warning'
    } else if (company.match_percentage > 0) {
      rowClass.push(styles.danger)
      similarityClass.push('text-danger')
      rowContext = 'danger'
    } else {
      rowClass.push(styles.unset)
    }

    const addressSelectable = company.address && company.address.id > 0
    const cmdCompany = company.database === 'CMD' && !addressSelectable

    return this.props.selectable || addressSelectable ? (
      <>
        <tr
          className={rowClass.join(' ')}
          data-semantic-id={'master-data-company-row'}
          data-semantic-context={rowContext}
          data-expanded={this.state.expanded ? 'true' : undefined}
          role={isExpandable ? 'button' : undefined}
          onClick={isExpandable ? this.toggleAdditionalAdresses : undefined}>
          {addressSelectable ? (
            <>
              <td
                className="pl-3"
                onClick={(
                  event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
                ) => {
                  event.stopPropagation()
                }}>
                {jobIsPermittedAndAssigned && (
                  <div data-semantic-id={'master-data-company-select-company'}>
                    <RadioButton
                      name={`company_${this.props.JobCompanyId}`}
                      selector={`${this.props.JobCompanyId}_${this.props.company.id}`}
                      checked={this.props.selected}
                      doOnChange={this.handleSelect}
                      disabled={!this.props.selectable}
                    />
                  </div>
                )}
              </td>
              <td
                className={`match-percentage pl-1 ${similarityClass.join(
                  ' '
                )}`}>
                {company.match_percentage
                  ? `${company.match_percentage.toFixed(1)}%`
                  : null}
              </td>
            </>
          ) : (
            <td colSpan={2}></td>
          )}
          <td>
            <CompanySource company={company} />
          </td>
          <td>
            <CompanyName company={company} showId={false} />
          </td>
          <td className="text-nowrap">
            <IdRenderer id={company.id} />
          </td>
          <td className="text-nowrap ">
            {company.qualified_company_id && (
              <>
                <TooltipHandle
                  data-semantic-id="qualified-company"
                  className="btn btn-link p-0 m-0 border-0"
                  name={'qualified-company'}
                  label={
                    <Icon
                      name={'arrow-right'}
                      className={'btn p-0 pr-2 m-0 border-0 align-baseline'}
                    />
                  }>
                  {t('QualifiedCompany')}
                </TooltipHandle>
                {company.qualified_company_id}
              </>
            )}
          </td>
          <td className="text-nowrap">
            <StatusRenderer id={company.id} status={company.status} />
          </td>

          {cmdCompany ? (
            <td>
              <div className="mb-1">
                {jobIsPermittedAndAssigned && (
                  <button
                    data-semantic-id="add-address-to-company"
                    className="btn btn-link btn-sm m-0 p-0"
                    onClick={this.handleSelectCompany}>
                    <Icon name="plus">
                      {t('CreateCMDCompanyInGSP', {
                        source: company.source,
                      })}
                    </Icon>
                  </button>
                )}
                <div>
                  <span>{`${t('CMDAddress')}: `}</span>
                  <AddressInline
                    address={company.address ?? defaultAddress}
                    showId={false}
                  />
                </div>
              </div>
            </td>
          ) : addressSelectable ? (
            <td>
              <span className="mb-1">
                <AddressInline
                  address={company.address ?? defaultAddress}
                  showId={false}
                />
              </span>
            </td>
          ) : (
            <td>
              {jobIsPermittedAndAssigned && (
                <button
                  data-semantic-id="add-address-to-company"
                  className="btn btn-link btn-sm m-0 p-0"
                  onClick={this.handleSelectCompany}>
                  <Icon name="plus">{t('AddNewAddressToCompany')}</Icon>
                </button>
              )}
            </td>
          )}
          <td className="text-nowrap">
            {company.address && <IdRenderer id={company.address.id} />}
          </td>
          <td className="text-nowrap">
            {company.address && addressSelectable && (
              <StatusRenderer id={company.id} status={company.address.status} />
            )}
          </td>
          <td style={{ overflow: 'hidden' }}>
            {isExpandable &&
              (this.state.expanded ? (
                <Icon
                  name="chevron-up"
                  title={t('ShowAllAdresses')}
                  onClick={this.toggleAdditionalAdresses}
                />
              ) : (
                <Icon
                  name="chevron-down"
                  title={t('ShowAllAdresses')}
                  onClick={this.toggleAdditionalAdresses}
                />
              ))}
          </td>
        </tr>
        <tr>
          {/* Adapt nr of cols accordingly to MasterDataCompanies */}
          <td colSpan={7} />
          <td colSpan={4}>
            <UseTransition
              name="SubAdresses"
              type="dropdown"
              in={this.state.expanded}
              timeout={300}>
              <div className="pl-0 pt-1">
                {(company.additional_addresses ?? []).map(
                  (address: Address, i) => (
                    <div
                      key={i}
                      className={`pb-4 ${
                        address.primary ? 'font-weight-bold' : ''
                      }`}>
                      <AddressInline
                        address={address}
                        showExternalNumbers
                        fallbackIcon={
                          <Icon name="info" className="opacity-0 pr-2" />
                        }
                        showStatusIcon
                      />
                    </div>
                  )
                )}
              </div>
            </UseTransition>
          </td>
        </tr>
      </>
    ) : null
  }
}

export default withTranslation()(MasterDataCompanyRow)
