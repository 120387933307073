import React from 'react'
import Icon from '../../../UI/Icon/Icon'
import Spinner from '../../../UI/Spinner/Spinner'
import ReadOnlySwitch from '../../../UI/ReadOnlySwitch/ReadOnlySwitch'
import { TooltipHandle } from '../../../UI/TooltipHandle/TooltipHandle'
import CompanyInfo from '../../../UI/CompanyInfo/CompanyInfo'
import CompanyError from '../../../UI/CompanyError/CompanyError'
import { ClusterCompany } from '../../../../interface/ClusterCompany'
import { Cluster } from '../../../../interface/Cluster'
import { useTranslation } from 'react-i18next'
import useModal from '../../../../hooks/useModal'
import { Modal } from '../../../UI/Modal/Modal'

interface Props {
  inputKey: string
  companyCount: number
  leadingCompany: ClusterCompany
  cluster: Cluster
  isExpanded: boolean
  contentVisible: boolean
  editable: boolean
  isSaving: boolean
  isUnsaving: boolean
  isFinalizing: boolean
  isChecked: boolean
  jobIsPermittedAndAssigned: boolean
  showCompanyNameForm: boolean
  handleSave: () => void
  handleUnsave: () => void
  handleFinalize: () => void
  handleShowCompanyNameForm: () => void
  toggleContentVisible: (ev: React.SyntheticEvent) => void
}

const ClusterJobButtons: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const {
    isExpanded,
    leadingCompany,
    contentVisible,
    editable,
    isSaving,
    isUnsaving,
    isFinalizing,
    isChecked,
    jobIsPermittedAndAssigned,
    showCompanyNameForm,
    cluster,
    handleSave,
    handleUnsave,
    handleFinalize,
    handleShowCompanyNameForm,
    toggleContentVisible,
    inputKey,
  } = props

  const { isOpen: finalizeModalOpen, toggle: toggleFinalizeModal } = useModal()

  return (
    <>
      {editable && jobIsPermittedAndAssigned && (
        <>
          <button
            data-semantic-id="cluster-save"
            className="btn btn-sm mr-0 p-0 border-0"
            onClick={isChecked ? handleUnsave : handleSave}
            disabled={
              !isExpanded || isSaving || isUnsaving || cluster.finalized
            }>
            <div className="d-flex">
              {isSaving ? (
                <Spinner size={20} className="mr-2" />
              ) : (
                <span className="mr-1 text-muted">{t('Checked')}</span>
              )}
              <ReadOnlySwitch
                data-semantic-id="cluster-checked"
                checked={isChecked}
                disabled={
                  !isExpanded || isSaving || isUnsaving || cluster.finalized
                }
                inputKey={`leading-checked-${leadingCompany.id}`}
              />
            </div>
          </button>
          <small
            className={`ml-0 mr-1 p-0 border-0 align-content-center ${
              cluster.saved ? 'text-success' : 'text-warning'
            }`}>
            <Icon
              name="circle-fill"
              title={cluster.saved ? t('ClusterSaved') : t('ClusterUnsaved')}
            />
          </small>
          {cluster.finalized ? (
            <TooltipHandle
              data-semantic-id={'finalize-single-cluster-done'}
              name={'finalize-single-cluster-done'}
              label={<Icon name={'lock'} />}
              className={'btn btn-sm m-1 p-0 border-0'}>
              {t('FinalizedCluster')}
            </TooltipHandle>
          ) : cluster.saved ? (
            <button
              data-semantic-id="finalize-single-cluster-modal-toggle"
              className={`btn btn-sm m-1 p-0 border-0 ${
                isFinalizing || !cluster.saved ? 'disabled opacity-25' : ''
              }`}
              onClick={toggleFinalizeModal}
              disabled={isFinalizing || !cluster.saved}
              type={'button'}>
              <Icon name={'unlock'} title={t('FinalizeCluster')} />
            </button>
          ) : (
            <TooltipHandle
              data-semantic-id={'finalize-single-cluster-unsaved'}
              name={'finalize-single-cluster-unsaved'}
              label={
                <Icon
                  name={'unlock'}
                  title={t('FinalizeCluster')}
                  className={`${
                    isFinalizing || !cluster.saved ? 'disabled opacity-25' : ''
                  }`}
                />
              }
              className={'btn btn-sm m-1 p-0 border-0'}>
              {t('UnsavedCluster')}
            </TooltipHandle>
          )}
          <Modal
            data-semantic-id="finalize-single-cluster-modal"
            isOpen={finalizeModalOpen}
            toggle={toggleFinalizeModal}>
            <button
              className={'btn btn-secondary m-1 p-1'}
              onClick={toggleFinalizeModal}>
              {t('Cancel')}
            </button>
            <button
              data-semantic-id="finalize-single-cluster-go"
              className="btn btn-primary m-1 p-1"
              onClick={handleFinalize}
              disabled={isFinalizing}
              type={'button'}>
              {t('FinalizeCluster')}
            </button>
          </Modal>
        </>
      )}
      <CompanyInfo
        inputKey={inputKey}
        company={leadingCompany}
        className={'m-1 p-0 border-0'}
      />
      <CompanyError
        inputKey={inputKey}
        company={leadingCompany}
        className={'m-1 p-0 border-0'}
      />
      {editable && jobIsPermittedAndAssigned && (
        <button
          data-semantic-id="cluster-job-edit"
          className={`btn btn-sm m-1 p-0 border-0 ${
            showCompanyNameForm ? 'active' : ''
          } ${isExpanded ? 'btn-link-primary-custom' : ''} ${
            cluster.finalized ||
            cluster.readonly ||
            cluster.leading_company.readonly
              ? 'disabled opacity-25'
              : ''
          }`}
          disabled={
            cluster.finalized ||
            cluster.readonly ||
            cluster.leading_company.readonly
          }
          onClick={handleShowCompanyNameForm}
          title={t('Edit')}>
          <Icon name="pencil" />
        </button>
      )}
      <button
        data-semantic-id="cluster-job-toggle"
        className={`btn btn-sm m-1 p-0 border-0 ${
          isExpanded ? 'btn-link-primary-custom' : ''
        }`}
        onClick={toggleContentVisible}>
        {contentVisible ? (
          <Icon name="chevron-up" />
        ) : (
          <Icon name="chevron-down" />
        )}
      </button>
    </>
  )
}

export default ClusterJobButtons
