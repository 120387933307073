import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ClusterJob } from '../../interface/ClusterJob'
import BootstrapAlert from '../UI/BootstrapAlert/BootstrapAlert'
import Spinner from '../UI/Spinner/Spinner'
import GspClusterJob from './GspClusterJob/GspClusterJob'
import ClusterJobs from '../../services/ClusterJobs/ClusterJobs'
import useUpdatableId from '../../hooks/useUpdatableId'
import JobInfo from '../UI/JobInfo/JobInfo'
import { Breadcrumb } from '../UI/Breadcrumb/Breadcrumb'
import Icon from '../UI/Icon/Icon'
import { Autocomplete } from '../UI/Autocomplete/Autocomplete'
import MatchingConfigs from '../../services/MatchingConfigs/MatchingConfigs'
import Input from '../UI/Input/Input'
import { TooltipHandle } from '../UI/TooltipHandle/TooltipHandle'
import { Capability, UserContext } from '../../contexts/UserContext'
import Checkbox from '../UI/Checkbox/Checkbox'
import Countries from '../../services/Countries/Countries'
import { isValidRegex } from '../../utils/StringUtils'
import { MatchingConfig } from '../../interface/MatchingConfig'
import { ResponseObject } from '../../interface/ResponseObject'

interface Props {
  jobId?: number
  city?: string | null
  company_name?: string | null
  street?: string | null
  country?: string | null
  start?: boolean
  matching_configuration_id?: number
  fields_as_regex?: boolean
}

async function getClusterJob(jobId: number) {
  const service = new ClusterJobs()
  return await service.getClusterJob(jobId)
}

async function createClusterJob(
  company_name: string,
  city: string,
  street: string,
  countryCode: string,
  fields_as_regex: boolean,
  matching_config_id?: string
) {
  const service = new ClusterJobs()
  return await service.createClusterJob(
    company_name,
    city,
    street,
    countryCode,
    fields_as_regex,
    matching_config_id
  )
}

async function getMatchingConfigs(params: { name: string }) {
  const service = new MatchingConfigs()
  return await service.getConfigs(params)
}

async function getMatchingConfig(id: string) {
  const service = new MatchingConfigs()
  return await service.getConfig(id)
}

async function getCountries(params?: {
  search?: string
  abbreviation?: string
}) {
  const service = new Countries()
  return await service.getCountries(params)
}

interface TooltipProps {
  name: string
  children?: ReactNode
}

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => (
  <div
    data-semantic-id="controlinfo"
    data-semantic-context={props.name}
    className="d-flex">
    <TooltipHandle name={props.name} label={<Icon name="info-circle" />}>
      {props.children}
    </TooltipHandle>
  </div>
)

export const GspCluster: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { can } = useContext(UserContext)
  const canReadMatchingConfig = can(Capability.ReadMatchingConfig)

  const { jobId: jobIdFromProps, start } = props
  const [searchText, setSearchText] = useState(props.company_name ?? '')
  const [searchTextError, setSearchTextError] = useState('')
  const [searchCity, setSearchCity] = useState(props.city ?? '')
  const [searchCityError, setSearchCityError] = useState('')
  const [searchStreet, setSearchStreet] = useState(props.street ?? '')
  const [searchStreetError, setSearchStreetError] = useState('')
  const [searchCountry, setSearchCountry] = useState({
    abbreviation: '',
    name: '',
  })
  const [matchingConfig, setMatchingConfig] = useState<MatchingConfig>({
    created_at: '',
    current: false,
    modified_at: '',
    value: '',
    id: undefined,
    name: '',
  })
  const [regexSearchSupported, setRegexSearchSupported] = useState<boolean>(
    props.fields_as_regex ?? true
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ResponseObject<any> | null>(null)
  const [job, setJob] = useState<ClusterJob | null>(null)
  const [defaultConfigSelected, setDefaultConfigSelected] = useState(
    canReadMatchingConfig
  )

  const jobId = useUpdatableId(jobIdFromProps, job?.id)

  const setValueAndError = useCallback(
    (
      value: string,
      isRegexSearch: boolean,
      setValue: React.Dispatch<React.SetStateAction<string>>,
      setError: React.Dispatch<React.SetStateAction<string>>
    ) => {
      let error = ''
      if (isRegexSearch && !isValidRegex(value)) {
        error = t('SearchRegexError')
      }
      setValue(value)
      setError(error)
    },
    [t]
  )

  const firstMount = useRef(false)
  const regexSettingsDependency = useMemo(
    () => [regexSearchSupported],
    [regexSearchSupported]
  )
  useEffect(() => {
    if (firstMount.current) {
      setValueAndError(
        searchText,
        regexSearchSupported,
        setSearchText,
        setSearchTextError
      )
      setValueAndError(
        searchCity,
        regexSearchSupported,
        setSearchCity,
        setSearchCityError
      )
      setValueAndError(
        searchStreet,
        regexSearchSupported,
        setSearchStreet,
        setSearchStreetError
      )
    } else {
      firstMount.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regexSettingsDependency])

  useEffect(() => {
    if (jobId && !job) {
      const fetchJob = async () => {
        setIsLoading(true)
        setError(null)
        try {
          const fetched = await getClusterJob(jobId)
          if (fetched.success && fetched.data) {
            setJob(fetched.data)
          } else if (
            !fetched.success &&
            fetched.status &&
            fetched.status >= 400 &&
            fetched.status < 600
          ) {
            throw new Error(
              fetched.message || t('ExistingClusterJobCouldNotBeLoaded')
            )
          } else {
            throw new Error(t('ExistingClusterJobCouldNotBeLoaded'))
          }
        } catch (e) {
          console.error(e)
          setError(e as any)
        } finally {
          setIsLoading(false)
        }
      }
      fetchJob()
    }
  }, [jobId, job, t])

  useEffect(() => {
    if (start === true) {
      initiateSearch()
    }
    // Note that we only want a one-off check for the "start" prop to
    // initiate the search when it's true; so it's OK to ignore the
    // exhaustive-deps warning *here* as an exception
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start])

  useEffect(() => {
    const getSubmittedCountry = async (abbreviation: string) => {
      const res = await getCountries({ abbreviation: abbreviation })
      if (res.success && res.data?.length) {
        setSearchCountry(res.data[0])
      }
    }
    if (props.country) {
      getSubmittedCountry(props.country)
    }
  }, [props.country])

  useEffect(() => {
    const getSubmittedConfig = async (id: number) => {
      const res = await getMatchingConfig(id.toString())
      if (res.success && res.data) {
        setMatchingConfig(res.data)
      }
    }
    if (props.matching_configuration_id) {
      getSubmittedConfig(props.matching_configuration_id)
    }
  }, [props.matching_configuration_id])

  const fetchMatchingConfigs = useCallback(async (term: string) => {
    const res = await getMatchingConfigs({ name: term })
    if (!res.success) {
      console.error(res.message)
      return { items: [], count: 0 }
    }
    return { items: res.data ?? [], count: res?.pagination?.object_count ?? 0 }
  }, [])

  const fetchCountries = useCallback(async (country: string) => {
    const res = await getCountries({ search: country })
    if (!res.success) {
      console.error(res.message)
      return { items: [], count: 0 }
    }
    const modified = (res.data ?? []).map(i => {
      return {
        ...i,
        name: `${i.abbreviation} - ${i.name}`,
      }
    })
    return { items: modified, count: res?.pagination?.object_count ?? 0 }
  }, [])

  const initiateSearch = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const fetched = await createClusterJob(
        searchText,
        searchCity,
        searchStreet,
        searchCountry.abbreviation,
        regexSearchSupported,
        defaultConfigSelected ? undefined : `${matchingConfig.id}`
      )
      if (fetched.success && fetched.data) {
        setJob(fetched.data)
      } else if (
        !fetched.success &&
        fetched.status &&
        fetched.status >= 400 &&
        fetched.status < 600
      ) {
        setError(fetched)
      } else {
        fetched.message = t('ClusterJobCouldNotBeCreated')
        setError(fetched)
      }
    } catch (e) {
      setError(e as any)
    } finally {
      setIsLoading(false)
    }
  }, [
    searchText,
    regexSearchSupported,
    defaultConfigSelected,
    matchingConfig.id,
    searchCity,
    searchStreet,
    searchCountry.abbreviation,
    t,
  ])

  const handleSearch = useCallback(
    (ev: React.SyntheticEvent) => {
      ev.preventDefault()
      initiateSearch()
    },
    [initiateSearch]
  )

  const handleSearchInput = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      if (ev.currentTarget) {
        setValueAndError(
          ev.currentTarget.value,
          regexSearchSupported,
          setSearchText,
          setSearchTextError
        )
      }
    },
    [regexSearchSupported, setValueAndError]
  )

  const handleCityInput = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      if (ev.currentTarget) {
        setValueAndError(
          ev.currentTarget.value,
          regexSearchSupported,
          setSearchCity,
          setSearchCityError
        )
      }
    },
    [regexSearchSupported, setValueAndError]
  )

  const handleStreetInput = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      if (ev.currentTarget) {
        setValueAndError(
          ev.currentTarget.value,
          regexSearchSupported,
          setSearchStreet,
          setSearchStreetError
        )
      }
    },
    [regexSearchSupported, setValueAndError]
  )

  const handleSelectMatchingConfig = useCallback((item: any) => {
    setMatchingConfig(item)
  }, [])

  const handleDefaultConfigSelectedChange = useCallback((checked: boolean) => {
    setDefaultConfigSelected(checked)
  }, [])

  const handleSetRegex = useCallback(() => {
    setRegexSearchSupported(!regexSearchSupported)
  }, [regexSearchSupported])

  const handleSelectCountry = useCallback((item: any) => {
    setSearchCountry(item)
  }, [])

  const hasData = Boolean(job)
  const hasError = Boolean(error)
  const hasSearch = searchText && searchText.length
  const searchIsValid =
    searchStreetError.length === 0 &&
    searchTextError.length === 0 &&
    searchCityError.length === 0
  const submitEnabled =
    hasSearch &&
    searchIsValid &&
    (defaultConfigSelected || matchingConfig.id) &&
    !isLoading
  const showData = hasData && !hasError && !isLoading && jobId && job

  return (
    <div>
      <Breadcrumb>
        <li className="breadcrumb-item">
          <Link to="/clusterjobs">{t('ClusterJobsCardHeader')}</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {jobId ? jobId : t('CreateNewClustering')}
        </li>
      </Breadcrumb>
      {!isLoading && !showData && (
        <form
          data-semantic-id="cluster-new-form"
          onSubmit={handleSearch}
          style={{ paddingLeft: '15%', paddingRight: '15%' }}>
          <div className="row no-gutters">
            <div className="col">
              <Input
                data-semantic-id="cluster-search"
                id="searchcluster"
                label={t('CompanyName')}
                slotRight={
                  searchTextError.length === 0 ? (
                    <Tooltip name="searchcluster-tooltip">
                      {t('SearchHint')}
                    </Tooltip>
                  ) : (
                    ''
                  )
                }
                onChange={handleSearchInput}
                required
                defaultValue={searchText}
                error={searchTextError}
              />
            </div>
            <div className="col ml-2">
              <Input
                data-semantic-id="cluster-search-city"
                id="searchcity"
                label={t('City')}
                slotRight={
                  searchCityError.length === 0 ? (
                    <Tooltip name="searchcity-tooltip">
                      {t('SearchCityTooltip')}
                    </Tooltip>
                  ) : (
                    ''
                  )
                }
                onChange={handleCityInput}
                defaultValue={searchCity}
                error={searchCityError}
              />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col">
              <Input
                data-semantic-id="cluster-search-street"
                id="searchstreet"
                label={t('Street')}
                slotRight={
                  searchStreetError.length === 0 ? (
                    <Tooltip name="searchcity-tooltip">
                      {t('SearchStreetTooltip')}
                    </Tooltip>
                  ) : (
                    ''
                  )
                }
                onChange={handleStreetInput}
                defaultValue={searchStreet}
                error={searchStreetError}
              />
            </div>
            <div className="col ml-2">
              <Autocomplete
                data-semantic-id="cluster-search-country"
                id="searchcountry"
                label={t('Country')}
                slotRight={
                  <Tooltip name="searchcountry-tooltip">
                    {t('SearchCountryTooltip')}
                  </Tooltip>
                }
                onFetchData={fetchCountries}
                onSelect={handleSelectCountry}
                defaultValue={searchCountry.name}
              />
            </div>
          </div>
          {canReadMatchingConfig && (
            <div className="row">
              <div className="col">
                <Autocomplete
                  data-semantic-id="cluster-search-matchingconfig"
                  disabled={defaultConfigSelected}
                  id="matchingconfig"
                  label={t('MatchingConfigTitle')}
                  slotRight={
                    <Tooltip name="matchingconfig-tooltip">
                      {t('SearchMatchingConfigTooltip')}
                    </Tooltip>
                  }
                  onFetchData={fetchMatchingConfigs}
                  onSelect={handleSelectMatchingConfig}
                  defaultValue={matchingConfig.name}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col d-flex align-items-center justify-content-end">
              <Checkbox
                data-semantic-id="cluster-search-use-regex"
                inputKey="cluster-search-use-regex"
                isSwitch
                checked={regexSearchSupported}
                onChange={handleSetRegex}>
                {t('UseRegexSearch')}
              </Checkbox>
              <TooltipHandle
                data-semantic-id="cmd-search-toggle-tooltip"
                className="btn"
                name={'cmd-search-toggle-tooltip'}
                label={<Icon name="info-circle" />}
                xShiftTooltip={-40}>
                {t('UseRegexSearchTooltip')}
              </TooltipHandle>
              {canReadMatchingConfig && (
                <Checkbox
                  data-semantic-id="cluster-search-use-default-config"
                  inputKey="cluster-search-use-default-config"
                  labelClasses={['ml-5']}
                  isSwitch
                  checked={defaultConfigSelected}
                  onChange={handleDefaultConfigSelectedChange}>
                  {t('UseDefaultMatchingConfig')}
                </Checkbox>
              )}
              <button
                data-semantic-id="cluster-search-go"
                disabled={!submitEnabled}
                type="submit"
                className="btn btn-secondary ml-4">
                <Icon name="search">{t('SearchGo')}</Icon>
              </button>
            </div>
          </div>
        </form>
      )}
      {!showData && job && <JobInfo job={job} jobType={'CLUSTER_JOB'} />}
      {isLoading && <Spinner />}
      {hasError && (
        <BootstrapAlert
          type="danger"
          message={error?.message}
          status={error?.system}
        />
      )}
      {jobId && showData && <GspClusterJob jobId={jobId} />}
    </div>
  )
}

export default GspCluster
