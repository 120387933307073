import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropdown } from '../../../hooks/useDropdown'
import Icon from '../Icon/Icon'
import config from '../../../config'
import styles from './HelpMenu.module.scss'

interface Props {
  className?: string
}

const HelpMenu: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [dropdownRef, dropdownOpen, setDropdownOpen] =
    useDropdown<HTMLDivElement>()

  const { className } = props
  const cssClasses = []

  if (className && className.length) {
    cssClasses.push(className)
  }

  const handleToggleDropdown = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      ev.preventDefault()
      setDropdownOpen(before => !before)
    },
    [setDropdownOpen]
  )

  return (
    <div
      ref={dropdownRef}
      className={`nav-item dropdown ${
        dropdownOpen ? 'show' : ''
      } ${cssClasses.join(' ')}`}
      data-semantic-id="help-menu">
      <a
        className="nav-link dropdown-toggle text-nowrap pl-0"
        href="#"
        id="helpNavbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        onClick={handleToggleDropdown}
        aria-expanded={dropdownOpen ? 'true' : 'false'}>
        <Icon name="question-circle">{t('HelpMenuTitle')}</Icon>
      </a>
      <div
        className={`dropdown-menu dropdown-menu-right ${
          dropdownOpen ? 'show' : ''
        } ${styles.dropdownMenu}`}
        aria-labelledby="helpNavbarDropdown">
        <div className={'dropdown-item px-0'}>
          <a
            href={config.HELP_URL}
            rel="noreferrer"
            target="_blank"
            className="nav-item nav-link pl-2">
            <Icon name="telephone">
              <span className={'pl-1'}>{t('WikiPageLink')}</span>
              <div className={'pl-4 text-wrap'}>{t('WikiPageDescription')}</div>
            </Icon>
          </a>
        </div>
        {config.TECHNICAL_HELP_URL && (
          <div className={'dropdown-item px-0'}>
            <a
              href={config.TECHNICAL_HELP_URL}
              rel="noreferrer"
              target="_blank"
              className="nav-item nav-link pl-2">
              <Icon name="envelope">
                <span className={'pl-1'}>{t('TechnicalHelpLink')}</span>
                <div className={'pl-4 text-wrap'}>
                  {t('TechnicalHelpDescription')}
                </div>
              </Icon>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default HelpMenu
