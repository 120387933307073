import { useCallback } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

interface QueryParameter {
  company_name?: string
  city?: string
  street?: string
  country?: string
  start_clustering?: string
  status?: string[]
  matching_configuration_id?: number
  fields_as_regex?: string
  created_by?: string
  assignee?: string
}

const emptyQuery: QueryParameter = {
  company_name: '',
  city: '',
  street: '',
  country: '',
  start_clustering: '',
  status: [],
  matching_configuration_id: 0,
}

function useQuery(): QueryParameter {
  const { search } = useLocation()
  const query: Record<string, any> = {} satisfies QueryParameter
  const searchParams = new URLSearchParams(search)
  for (const [key, value] of searchParams) {
    const isArray = emptyQuery[key as keyof QueryParameter] instanceof Array
    query[key] = isArray
      ? [...(query[key]?.filter((val: any) => val) ?? []), value]
      : value
  }
  return query
}

export default function useRouting() {
  const { push, replace } = useHistory()
  const { path, url } = useRouteMatch()
  const query = useQuery()
  const route = path?.split('/')

  const reload = useCallback(() => {
    window.location.reload()
  }, [])

  const pushState = useCallback(
    (data: any, title: string, path?: string | null) => {
      push(path ?? '/', data)
    },
    [push]
  )

  const replaceState = useCallback(
    (data: any, title: string, url?: string | null) => {
      replace(url ?? '/', data)
    },
    [replace]
  )

  const redirectTo = useCallback((url: string) => {
    window.location.href = url
  }, [])

  return {
    pushState,
    query,
    reload,
    replaceState,
    redirectTo,
    route,
    url,
  }
}
