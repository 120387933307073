import { useEffect } from 'react'
import useRouting from './useRouting'

export default function useUpdatableId<T, V>(
  idFromProps: T | undefined,
  newId: V | undefined
) {
  const { pushState } = useRouting()
  useEffect(() => {
    if (idFromProps || !newId) {
      return
    }
    // When we have the thingy loaded we want that reflected in the url
    const moduleName: string = window.location.pathname.split('/')[1]

    pushState(null, '', `/${moduleName}/${newId}`)
  }, [idFromProps, newId, pushState])

  return idFromProps ?? newId
}
