import React, { useCallback, useContext, useEffect, useState } from 'react'
import ClusterContext from '../components/GspCluster/ClusterContext'
import ClusterState from '../components/GspCluster/ClusterState/ClusterState'
import { Address } from '../interface/Address'
import {
  ClusterCompany,
  ClusterCompanyNames,
} from '../interface/ClusterCompany'

export interface Props {
  company: ClusterCompany
  showCompanyNameForm: boolean
  onAssignmentChanged: (company: ClusterCompany, address?: Address) => void
  onUpdate: (company: ClusterCompany) => void
  onEditingStatusChanged?: (status: boolean) => void
  onLeadingCompanyChanged?: (company: ClusterCompany) => void
  onSetAddressChangeMapping?: (mergeIds: string[], targetId: string) => void
}

export const useClusterJobCompanyState = (props: Props) => {
  const {
    company,
    onAssignmentChanged,
    onLeadingCompanyChanged,
    onUpdate,
    onEditingStatusChanged,
    showCompanyNameForm,
    onSetAddressChangeMapping,
  } = props
  const state = useContext(ClusterContext)
  const companyAssigned = ClusterState.isCompanyAssigned(company, state)
  const addressChangeMapping = state?._addressChangeMapping ?? {}
  const [isEditing, setIsEditing] = useState(false)
  const handleCompanySelection = useCallback(() => {
    onAssignmentChanged(company)
  }, [company, onAssignmentChanged])

  const handleAddressSelection = useCallback(
    (address: Address) => {
      onAssignmentChanged(company, address)
    },
    [company, onAssignmentChanged]
  )

  const handleAddressUpdate = (address: Address) => {
    const newCompany = { ...company }
    if (newCompany.address?.id === address.id) {
      newCompany.address = { ...address }
    } else if (newCompany.additional_addresses) {
      newCompany.additional_addresses = newCompany.additional_addresses.map(
        old => {
          return old?.id === address.id ? address : old
        }
      )
    }
    if (onUpdate) onUpdate(newCompany)
  }

  const toggleEditing = useCallback((ev: React.SyntheticEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    setIsEditing(prev => !prev)
  }, [])

  const handleCompanyNameUpdate = (companyNames: ClusterCompanyNames) => {
    const newCompany: ClusterCompany = {
      ...company,
      ...companyNames,
    }
    if (onUpdate) onUpdate(newCompany)
    setIsEditing(false)
  }

  const handleChangeLeadingCompany = useCallback(() => {
    if (onLeadingCompanyChanged) onLeadingCompanyChanged(company)
  }, [company, onLeadingCompanyChanged])

  const handleSetAddressChangeMapping = useCallback(
    (mergeIds: string[], targetId: string) => {
      if (onSetAddressChangeMapping)
        onSetAddressChangeMapping(mergeIds, targetId)
    },
    [onSetAddressChangeMapping]
  )

  useEffect(() => {
    if (!companyAssigned) {
      setIsEditing(false)
    }
  }, [companyAssigned, setIsEditing])

  useEffect(() => {
    if (showCompanyNameForm !== undefined) {
      setIsEditing(showCompanyNameForm)
    }
  }, [showCompanyNameForm, setIsEditing])

  useEffect(() => {
    if (onEditingStatusChanged) {
      onEditingStatusChanged(isEditing)
      return () => onEditingStatusChanged(false)
    }
  }, [isEditing, onEditingStatusChanged])

  return {
    companyAssigned,
    isEditing,
    handleAddressSelection,
    handleAddressUpdate,
    handleCompanyNameUpdate,
    handleCompanySelection,
    handleChangeLeadingCompany,
    handleSetAddressChangeMapping,
    showCompanyNameForm,
    toggleEditing,
    addressChangeMapping,
  }
}
