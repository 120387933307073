import React, { ReactNode } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import type bootstrapIcons from 'bootstrap-icons/font/bootstrap-icons.json'

import styles from './Icon.module.scss'

type Size = 'md' | 'lg'

interface IconProps {
  name: Icon
  title?: string
  style?: any
  className?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  size?: Size
  children?: ReactNode
  'data-testid'?: string
}
type Icon = keyof typeof bootstrapIcons

const Icon = (props: IconProps) => {
  const iconName: Icon = props.name ? props.name : 'info'
  const cssClasses = ['bi', `bi-${iconName}`]

  if (props.size === 'lg') {
    cssClasses.push(styles.lg)
  }

  if (props.className && props.className.length) {
    cssClasses.push(props.className)
  }

  return (
    <>
      <i
        className={cssClasses.join(' ')}
        data-testid={props['data-testid']}
        onClick={props.onClick}
        aria-label={props.onClick ? props.title : undefined}
        role={props.onClick ? 'button' : undefined}
        style={props.style}
        title={props.title}
      />
      {props.children && (
        <span className={styles.iconLabel}>{props.children}</span>
      )}
    </>
  )
}

export default Icon
