import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropdown } from '../../../hooks/useDropdown'
import Icon from '../Icon/Icon'
import { UserContext } from '../../../contexts/UserContext'
import i18next from 'i18next'
import Checkbox from '../Checkbox/Checkbox'

const supportedTranslations = [
  { key: 'DE', options: ['de', 'de-DE'] },
  { key: 'EN', options: ['en', 'en-US', 'en-EN'] },
] as const
export type Translations = (typeof supportedTranslations)[number]

interface Props {
  className?: string
}

const UserMenu: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { userName } = useContext(UserContext)

  const [dropdownRef, dropdownOpen, setDropdownOpen] =
    useDropdown<HTMLDivElement>()

  const { className } = props
  const cssClasses = []

  if (className && className.length) {
    cssClasses.push(className)
  }

  const handleToggleDropdown = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      ev.preventDefault()
      setDropdownOpen(before => !before)
    },
    [setDropdownOpen]
  )

  const handleSelect = useCallback((selectedTranslation: string) => {
    if (!selectedTranslation) {
      return
    }
    i18next.changeLanguage(selectedTranslation)
  }, [])

  return (
    <div className={cssClasses.join(' ')} data-semantic-id="user-dropdown-menu">
      <div
        ref={dropdownRef}
        className={`nav-item dropdown ${dropdownOpen ? 'show' : ''}`}>
        <a
          className="nav-link dropdown-toggle text-nowrap pl-0"
          href="#"
          id="userNavbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          onClick={handleToggleDropdown}
          aria-expanded={dropdownOpen ? 'true' : 'false'}>
          <Icon name="person" className="">
            <span data-semantic-id="header-username">
              {userName || t('FallbackUserName')}
            </span>
          </Icon>
        </a>
        <div
          className={`dropdown-menu dropdown-menu-right ${
            dropdownOpen ? 'show' : ''
          }`}
          aria-labelledby="userNavbarDropdown">
          {supportedTranslations.map(lng => (
            <div key={`opt:${lng.key}`} className={'dropdown-item px-0'}>
              <Checkbox
                data-semantic-id="translation-select"
                inputKey={lng.key}
                key={lng.key}
                checked={[...lng.options].includes(i18next.language as any)}
                inputClasses={['position-relative', 'ml-3']}
                labelClasses={['pr-0', 'mr-0']}
                onChange={() => handleSelect(lng.options[0])}>
                {t(`Translation_${lng.key}`)}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default UserMenu
