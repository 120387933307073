import React, { ReactNode, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Input.module.scss'

interface InputProps {
  'data-semantic-id'?: string
  id: string
  cssClass?: string
  error?: any
  disabled?: boolean
  label?: string | JSX.Element | null
  name?: string
  minLength?: number
  maxLength?: number
  type?: string
  required?: boolean
  readonly?: boolean
  value?: string
  defaultValue?: string
  onChange: (
    ev: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<any>
  ) => void
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void
  slotRight?: ReactNode
  suggestions?: string[]
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const { onBlur, onChange, onKeyDown } = props
  const { t } = useTranslation()
  const ref = useRef<HTMLInputElement>(null)
  const [hasFocus, setHasFocus] = useState(false)

  const shrinkLabel =
    hasFocus || props.value != undefined || props.defaultValue || props.error
  const cssClasses = ['form-group', 'position-relative']
  const inputCssClass = ['form-control', 'pb-0']
  if (props.cssClass) {
    cssClasses.push(props.cssClass)
  }
  if (props.disabled) {
    cssClasses.push('disabled')
  }
  if (props.error) {
    cssClasses.push('text-danger')
    inputCssClass.push('is-invalid')
  }

  const handleChange = useCallback(
    (ev: React.ChangeEvent<any> | React.KeyboardEvent<any>) => {
      onChange(ev)
    },
    [onChange]
  )
  const handleFocus = useCallback(() => {
    setHasFocus(true)
  }, [])
  const handleBlur = useCallback(
    (ev: React.FocusEvent<any>) => {
      setHasFocus(false)
      if (!onBlur) {
        return
      }
      onBlur(ev)
    },
    [onBlur]
  )
  const handlePickSuggestion = useCallback(
    (ev: React.MouseEvent) => {
      if (!ref.current) {
        return
      }
      const suggestion = ev.currentTarget.getAttribute('data-suggestion')
      if (!suggestion) {
        return
      }
      // Hacky way to manually trigger a change event on a React node
      ref.current.value = suggestion
      const changeEvent = new Event('change', { bubbles: true })
      ref.current.dispatchEvent(changeEvent)
      onChange(changeEvent as any)
    },
    [ref, onChange]
  )
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<any>) => {
      if (onKeyDown) {
        onKeyDown(event)
      }
    },
    [onKeyDown]
  )
  const type = props.type ?? 'text'

  return (
    <div className={cssClasses.join(' ')}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`${styles.label} ${shrinkLabel ? styles.focused : ''}`}>
          {props.label}
        </label>
      )}
      {type === 'textarea' ? (
        <textarea
          data-semantic-id={props['data-semantic-id']}
          id={props.id}
          name={props.name}
          value={props.value}
          className={[...inputCssClass, styles.textarea].join(' ')}
          required={props.required}
          maxLength={props.maxLength}
          minLength={props.minLength}
          disabled={props.disabled}
          readOnly={props.readonly}
          defaultValue={props.defaultValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          rows={1}
        />
      ) : (
        <input
          ref={ref}
          data-semantic-id={props['data-semantic-id']}
          id={props.id}
          type={type}
          name={props.name}
          value={props.value}
          className={inputCssClass.join(' ')}
          required={props.required}
          maxLength={props.maxLength}
          minLength={props.minLength}
          disabled={props.disabled}
          readOnly={props.readonly}
          defaultValue={props.defaultValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      )}
      {props.slotRight && (
        <div className={styles.slotRight}>{props.slotRight}</div>
      )}
      {props.suggestions && props.suggestions.length > 0 && (
        <div
          className={`dropdown-menu d-block position-static float-none w-100 ${
            styles.dropdown
          } ${props.error ? styles.invalidDropdown : ''}`}>
          <h6 className="dropdown-header pl-3">{t('Suggestions')}</h6>
          {props.suggestions.map(suggestion => (
            <button
              key={suggestion}
              className="btn btn-sm btn-link dropdown-item"
              type="button"
              onClick={handlePickSuggestion}
              data-suggestion={suggestion}>
              {suggestion}
            </button>
          ))}
        </div>
      )}
      {props.error && (
        <div
          className="text-error"
          data-semantic-id={`${props['data-semantic-id']}-error`}>
          {props.error}
        </div>
      )}
    </div>
  )
}
export default Input
