import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useDebouncedState from '../../hooks/useDebouncedState'
import usePagination from '../../hooks/usePagination'
import useRouting from '../../hooks/useRouting'
import Jobs, { createGspLink } from '../../services/Jobs/Jobs'
import BootstrapAlert from '../UI/BootstrapAlert/BootstrapAlert'
import Spinner from '../UI/Spinner/Spinner'
import { FormattedDate } from '../UI/FormattedDate/FormattedDate'
import { Breadcrumb } from '../UI/Breadcrumb/Breadcrumb'
import { Job, JobStatus } from '../../interface/Job'
import { SortHandle, Sorting } from '../UI/SortHandle/SortHandle'
import BackdropSpinner from '../UI/BackdropSpinner/BackdropSpinner'
import { Pagination } from '../UI/Pagination/Pagination'
import Icon from '../UI/Icon/Icon'
import { Option, SelectBox } from '../UI/SelectBox/SelectBox'
import Input from '../UI/Input/Input'
import { TooltipHandle } from '../UI/TooltipHandle/TooltipHandle'
import {
  createFilterParams,
  matchesKnownJobStatus,
  statusHasChanged,
  updateStatus,
} from '../../utils/JobUtils'
import { Capability, UserContext } from '../../contexts/UserContext'

const restartJob = async (id: string) => {
  return await new Jobs().restartJob(Number(id))
}

interface TooltipProps {
  name: string
  children?: ReactNode
}

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => (
  <TooltipHandle
    data-semantic-id="restartinfo"
    data-semantic-context={props.name}
    className="btn btn-gray-custom"
    name={props.name}
    label={<Icon name="info-circle" />}>
    {props.children}
  </TooltipHandle>
)

interface StatusIndicatorProps {
  name: string
  status: JobStatus
}
const colorClassLookup: Record<JobStatus, string> = {
  IMPORTING: 'text-info',
  PROCESSING: 'text-warning',
  READY: 'text-info',
  ASSIGNMENT_DONE: 'text-success',
  QUEUED: 'text-warning',
  ERROR: 'text-danger',
  EXPIRED: 'text-danger',
  EXTENDED_QUEUED: 'text-info',
  EXTENDED_PROCESSING: 'text-info',
  WAITING_EXTENDED_PROCESSING: 'text-info',
  WAITING_FINAL_PROCESSING: 'text-info',
}
const StatusIndicator = (props: StatusIndicatorProps) => {
  const { t } = useTranslation()
  const status = props.status || 'null'
  return (
    <div
      data-semantic-id="statusindicator"
      data-semantic-context={props.name}
      className="d-flex align-items-baseline">
      <TooltipHandle
        data-semantic-id="cardinfo"
        data-semantic-context={props.name}
        className={`btn m-0 p-0 ${colorClassLookup[status] || 'text-danger'}`}
        name={props.name}
        label={<Icon name="info-circle" size="lg" />}>
        {t(`ImportJobStatusTooltip_${status}`)}
      </TooltipHandle>
      <span className="ml-2">{t(`ImportJobStatus_${status}`)}</span>
    </div>
  )
}

const OPTIONS: JobStatus[] = [
  'IMPORTING',
  'QUEUED',
  'PROCESSING',
  'READY',
  'ASSIGNMENT_DONE',
  'ERROR',
  'EXPIRED',
  'EXTENDED_QUEUED',
  'EXTENDED_PROCESSING',
  'WAITING_EXTENDED_PROCESSING',
  'WAITING_FINAL_PROCESSING',
]

type StatusFilter = JobStatus

const BASE_ROUTE = '/importjobs'

export default function GspImportJobOverview() {
  const { t } = useTranslation()
  const { can, userName } = useContext(UserContext)
  const { pushState, query, replaceState } = useRouting()

  const initialStatus: StatusFilter[] = matchesKnownJobStatus(
    query?.status,
    OPTIONS
  )
    ? [...(query?.status as StatusFilter[])]
    : []

  const [statusFilter, setStatusFilter] =
    useState<StatusFilter[]>(initialStatus)
  const [externalIdFilter, setExternalIdFilter] = useDebouncedState(250, '')
  const [sort, setSort] = useState<Sorting>(Sorting.Descending)

  const [deleteError, setDeleteError] = useState<string | null>(null)
  const [deleteSystemError, setDeleteSystemError] = useState<string | null>(
    null
  )
  const [deleting, setDeleting] = useState<boolean>(false)

  const { error, fetchItems, isLoading, items, page, pagination, resetPage } =
    usePagination<Job>()

  const handleStatusFilter = useCallback(
    (option: Option) => {
      const updated = updateStatus(statusFilter, option) as StatusFilter[]
      if (statusHasChanged(statusFilter, updated)) {
        resetPage()
      }
      setStatusFilter(updated)
    },
    [resetPage, statusFilter]
  )

  const handleExternalIdFilter = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      setExternalIdFilter((e.target as any).value)
    },
    [setExternalIdFilter]
  )
  const handleSelectSort = useCallback((sort: Sorting) => {
    setSort(sort)
  }, [])
  const handleRestartJob = useCallback(
    async (e: React.SyntheticEvent<HTMLButtonElement>) => {
      setDeleteError(null)
      setDeleteSystemError(null)
      setDeleting(true)
      const jobId = e.currentTarget.dataset.jobid
      if (jobId) {
        const response = await restartJob(jobId)
        if (response.success && response.data?.id) {
          pushState(response.data, '', `${BASE_ROUTE}/${response.data?.id}`)
        } else {
          setDeleteError(response.message ?? t('ResetImportJobFailed'))
          setDeleteSystemError(response.system ?? null)
        }
      }
      setDeleting(false)
    },
    [pushState, t]
  )

  useEffect(() => {
    fetchItems(() =>
      new Jobs().getJobs(page, {
        status: statusFilter,
        external_id: externalIdFilter,
        sort: sort === Sorting.Ascending ? 'created_at:ASC' : 'created_at:DESC',
        size: 8,
      })
    )
  }, [externalIdFilter, fetchItems, page, sort, statusFilter])

  useEffect(() => {
    const parameters = createFilterParams('status', statusFilter)
    replaceState(null, '', `${BASE_ROUTE}?${parameters}`)
  }, [replaceState, statusFilter])

  return (
    <div data-semantic-id="import-job-overview">
      <Breadcrumb>
        <li className="breadcrumb-item active" aria-current="page">
          {t('ImportJobsCardHeader')}
        </li>
      </Breadcrumb>
      <div className="d-flex align-items-center justify-content-between">
        <h1>{t('ImportJobsCardHeader')}</h1>
      </div>
      <p>{t('ImportJobsPageDescription')}</p>
      <div className="d-flex align-items-end">
        <div className="form-group mr-3">
          <SelectBox
            id="jobstatus"
            label={t('JobStatus')}
            disabled={isLoading}
            options={OPTIONS.map(value => ({
              label: t(`ImportJobStatus_${value}`),
              value,
            }))}
            onChange={handleStatusFilter}
            values={statusFilter}
            isCheckbox
            includeNoSelect={false}
            isMultiSelect
          />
        </div>
        <Input
          id="externalid"
          name="externalid"
          type="text"
          label={t('JobExternalId')}
          slotRight={<Icon name="search" />}
          onChange={handleExternalIdFilter}
          defaultValue={externalIdFilter}
        />
      </div>
      {error && <BootstrapAlert type="danger">{error.message}</BootstrapAlert>}
      {deleteError && (
        <BootstrapAlert
          type="danger"
          message={deleteError}
          status={deleteSystemError}
        />
      )}
      <div className="row mb-4">
        <div className="col">
          <div
            data-semantic-id="paginated-data"
            className="card card-custom-table">
            <div className="card-body">
              <BackdropSpinner show={isLoading}>
                <div>
                  <table
                    data-semantic-id="jobtable"
                    className="table table-striped table-borderless table-layout-fixed">
                    <colgroup>
                      <col style={{ minWidth: '14rem' }} />
                      <col style={{ minWidth: '9rem' }} />
                      <col style={{ minWidth: '9rem' }} />
                      <col className="col-auto" style={{ minWidth: '10rem' }} />
                      <col className="col-auto" style={{ minWidth: '5rem' }} />
                      <col className="col-auto" style={{ minWidth: '5rem' }} />
                      <col style={{ minWidth: '10rem' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">{t('JobStatus')}</th>
                        <th scope="col">{t('JobInternalId')}</th>
                        <th scope="col">{t('JobExternalId')}</th>
                        <th scope="col">
                          <span className="d-flex">
                            <span className="mr-2">{t('JobCreatedAt')}</span>
                            <SortHandle
                              data-semantic-id="importjobs-sort-created-at"
                              sort={sort}
                              onSelectSorting={handleSelectSort}
                            />
                          </span>
                        </th>
                        <th scope="col">{t('JobCreatedBy')}</th>
                        <th scope="col">{t('JobAssignee')}</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {items !== null && items.length === 0 && (
                        <tr data-semantic-id="empty-page">
                          <td colSpan={6}>
                            <BootstrapAlert
                              className="m-2"
                              type="info"
                              message={t('NoJobsFound')}
                            />
                          </td>
                        </tr>
                      )}
                      {items?.map((item, i) => (
                        <tr key={i} data-main-row data-id={item.id}>
                          <td className="py-0 align-middle">
                            <StatusIndicator
                              name={`si-${item.id}`}
                              status={item.status}
                            />
                          </td>
                          <th scope="row">
                            <Link to={`/importjobs/${item.id}`}>{item.id}</Link>
                          </th>
                          <td>
                            {item.external_id}
                            {item.external_id && (
                              <a
                                href={createGspLink(item.external_id)}
                                className="d-inline-block ml-2"
                                rel="noreferrer"
                                target="_blank">
                                <Icon name="box-arrow-up-right" />
                              </a>
                            )}
                          </td>
                          <td>
                            <FormattedDate date={item.created_at} />
                          </td>
                          <td>
                            <span className="overflow-ellipsis text-nowrap">
                              {item.created_by}
                            </span>
                          </td>
                          <td>
                            <span className="overflow-ellipsis text-nowrap">
                              {item.assignee}
                            </span>
                          </td>
                          <td
                            className="text-right py-0 align-middle"
                            style={{ minWidth: '100px' }}>
                            {can(Capability.WriteImportjobs) &&
                              item?.assignee === userName &&
                              item.status !== 'ASSIGNMENT_DONE' &&
                              item.status !== 'EXPIRED' &&
                              (deleting ? (
                                <Spinner size={20} />
                              ) : (
                                <div className="d-flex justify-content-end">
                                  <button
                                    data-semantic-id="restart-job"
                                    data-semantic-context={`job-${item.id}`}
                                    className="btn btn-secondary text-nowrap"
                                    data-jobid={item.id}
                                    data-externalid={item.external_id}
                                    onClick={handleRestartJob}>
                                    <Icon name="bootstrap-reboot">
                                      {t('ResetImportJob')}
                                    </Icon>
                                  </button>
                                  <Tooltip name={`tt-${item.id}`}>
                                    {t('RestartJobTooltip')}
                                  </Tooltip>
                                </div>
                              ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </BackdropSpinner>
            </div>
          </div>
        </div>
      </div>
      <Pagination {...pagination} />
    </div>
  )
}
