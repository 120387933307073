import { DashboardContent } from '../../interface/DashboardContent'
import { SystemStatus } from '../../interface/SystemStatus'
import ApiService from '../ApiService'

export default class DashboardService extends ApiService {
  getSystemStatus() {
    return this.get<SystemStatus>('/status/gsp')
  }
  getDashboardContent() {
    return this.get<DashboardContent>('/dashboard')
  }
}
