import React from 'react'
import { useTranslation } from 'react-i18next'

import { Company } from '../../../interface/Company'
import Icon from '../Icon/Icon'
import { TooltipHandle } from '../TooltipHandle/TooltipHandle'

interface Props {
  inputKey: string
  company: Company
  className?: string
}

export default function CompanyInfo(props: Props): JSX.Element {
  const { t } = useTranslation()
  const { inputKey, company, className } = props
  const key = `ci-tt-${inputKey}`
  const hasInfo =
    company.vat_id ||
    company.local_siemens_area_id ||
    company.vertical_id ||
    company.national_trade_register_id

  const cssClasses = ['btn', 'btn-sm']
  if (className && className.length) {
    cssClasses.push(className)
  }

  if (!hasInfo) {
    return (
      <button
        className={`${cssClasses.join(' ')} opacity-25`}
        title={t('NoAdditionalInfoAvailable')}
        disabled>
        <Icon name="info-circle" />
      </button>
    )
  }

  return (
    <TooltipHandle
      name={key}
      className={`${cssClasses.join(' ')}`}
      label={<Icon name="info-circle" />}>
      <div>
        <b>{t('VatId')}:</b> {company.vat_id || '-'}
      </div>
      <div>
        <b>{t('LocalSiemensAreaId')}:</b> {company.local_siemens_area_id || '-'}
      </div>
      <div>
        <b>{t('VerticalId')}:</b> {company.vertical_id || '-'}
      </div>
      <div>
        <b>{t('NationalTradeRegisterId')}:</b>{' '}
        {company.national_trade_register_id || '-'}
      </div>
    </TooltipHandle>
  )
}
