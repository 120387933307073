import Icon from '../Icon/Icon'
import { TooltipHandle } from '../TooltipHandle/TooltipHandle'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropdown } from '../../../hooks/useDropdown'

interface Props {
  onSearch: (regexSearch: boolean) => void
  'data-semantic-id'?: string
  tooltip?: string
  className?: string
}
export const CMDSearchSplitButton = (props: Props) => {
  const { t } = useTranslation()

  const { onSearch, tooltip, className } = props
  const cssClasses = []

  if (className && className.length) {
    cssClasses.push(className)
  }
  const [ref, isOpen, setIsOpen] = useDropdown<HTMLDivElement>()
  const handleSplitToggle = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault()
      setIsOpen(before => !before)
    },
    [setIsOpen]
  )

  const handleSearch = useCallback(
    async (regexSearch: boolean) => {
      onSearch(regexSearch)
    },
    [onSearch]
  )

  return (
    <div
      data-semantic-id={props['data-semantic-id'] ?? 'cmd-search-menu'}
      className={`btn-group mr-3 ${cssClasses.join(' ')}`}
      ref={ref}>
      <button
        data-semantic-id="cmd-search"
        className="btn btn-secondary"
        onClick={() => handleSearch(false)}>
        <Icon name={'search'}>{`${t('CMDSearch')}`}</Icon>
      </button>
      <div className="d-flex dropdown">
        <button
          data-semantic-id="cmd-search-toggle"
          data-testid="cmd-search-toggle"
          type="button"
          className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={handleSplitToggle}>
          <span className="sr-only" />
        </button>
        {tooltip && (
          <TooltipHandle
            data-semantic-id="cmd-search-toggle-tooltip"
            className="btn btn-gray-custom"
            name={'cmd-search-toggle-tooltip'}
            label={<Icon name="info-circle" />}
            xShiftTooltip={-40}>
            {t(tooltip)}
          </TooltipHandle>
        )}

        <div
          className={`dropdown-menu dropdown-menu-right ${
            isOpen ? 'show' : 'hide'
          }`}
          data-testid="cmd-dropdown-menu">
          <button
            className="dropdown-item"
            data-testid="cmd-dropdown-item"
            data-semantic-id="cmd-search-regex"
            type="button"
            onClick={() => handleSearch(true)}>
            {`${t('CMDSearchRegex')}`}
          </button>
        </div>
      </div>
    </div>
  )
}
