import React, { ReactNode } from 'react'

type Alert = 'primary' | 'danger' | 'success' | 'info' | 'warning'

interface AlertProps {
  type: Alert
  message?: string
  className?: string
  children?: ReactNode
  status?: string | null
}
const BootstrapAlert: React.FC<AlertProps> = (props: AlertProps) => {
  const { type, message, className, status } = props
  return (
    <div className={`alert alert-${type}${className ? ' ' + className : ''}`}>
      {`${message ?? props.children}${status ? ` (${status})` : ''}`}
    </div>
  )
}

export default BootstrapAlert
