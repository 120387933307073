import ApiService from '../ApiService'
import { ResponseObject } from '../../interface/ResponseObject'
import { DefaultCompanyRole } from '../../interface/DefaultCompanyRole'

interface RolesParams {
  search?: string
  size?: number
}

class Roles extends ApiService {
  getRoles(
    params: RolesParams = {},
    page = 0
  ): Promise<ResponseObject<DefaultCompanyRole[]>> {
    const { search, size } = params
    const paramList = [
      page >= 0 ? `page=${encodeURIComponent(page)}` : '',
      search ? `search=${encodeURIComponent(search)}` : null,
      size ? `size=${encodeURIComponent(size)}` : null,
    ].filter(Boolean)
    const paramFragment = paramList.length ? `?${paramList.join('&')}` : ''
    return this.get<DefaultCompanyRole[]>(
      `/default_company_roles${paramFragment}`
    )
  }
}

export default Roles
