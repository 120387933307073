import React, { ReactNode } from 'react'

interface Props {
  inputKey: string
  checked: boolean
  'data-semantic-id'?: string
  disabled?: boolean
  children?: ReactNode
}

const ReadOnlySwitch: React.FC<Props> = (props: Props) => {
  const { checked = false, children, disabled, inputKey } = props

  return (
    <div
      className="custom-control custom-switch"
      style={{
        position: 'relative',
        top: `-0.02em`,
      }}>
      <input
        data-semantic-id={props['data-semantic-id']}
        id={inputKey}
        className="custom-control-input"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        readOnly
      />
      <label htmlFor={inputKey} className="custom-control-label">
        {children}
      </label>
    </div>
  )
}

export default ReadOnlySwitch
