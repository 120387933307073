import { ResponseObject } from '../interface/ResponseObject'
import { useState, useCallback } from 'react'

export default function useApi<T, K>(
  t: (key: string) => string,
  errorMsgKey: string,
  fetchData: (data?: any) => Promise<ResponseObject<T>>,
  setErrorResponse?: boolean
) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ResponseObject<T> | null>(null)
  const [response, setResponse] = useState<ResponseObject<T> | null>(null)

  const call = useCallback(
    async (data?: K) => {
      try {
        setIsLoading(true)
        setError(null)
        const fetched = await fetchData(data)
        if (fetched.success) {
          setError(null)
          setResponse(fetched)
        } else {
          fetched.message = fetched.message ?? t(errorMsgKey)
          setError(fetched)
          setResponse(setErrorResponse ? fetched : null)
        }
      } catch (e) {
        console.error(e)
        setError({
          success: false,
          message: (e as any).message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [errorMsgKey, fetchData, setErrorResponse, t]
  )

  return {
    call,
    error,
    isLoading,
    response,
  }
}
