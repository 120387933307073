import React, { ReactNode } from 'react'

import Icon from '../Icon/Icon'

interface Props {
  children?: ReactNode
  'data-semantic-id'?: string
}

export const IconPill: React.FC<Props> = (props: Props) => (
  <small
    data-semantic-id={props['data-semantic-id']}
    className="d-flex border border-warning align-items-center rounded-pill text-nowrap ml-1">
    <span
      className="bg-warning text-white px-1"
      style={{ borderTopLeftRadius: '50rem', borderBottomLeftRadius: '50rem' }}>
      <Icon name="exclamation-circle" />
    </span>
    <span className="pl-2 pr-3 text-muted">{props?.children}</span>
  </small>
)
