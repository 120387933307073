import ApiService from '../ApiService'
import { ResponseObject } from '../../interface/ResponseObject'

interface Country {
  name: string
  abbreviation: string
}

interface CountriesParams {
  search?: string
  abbreviation?: string
  size?: number
}

class Countries extends ApiService {
  getCountries(
    params: CountriesParams = {},
    page = 0
  ): Promise<ResponseObject<Country[]>> {
    const { search, abbreviation, size } = params
    const paramList = [
      page >= 0 ? `page=${encodeURIComponent(page)}` : '',
      search ? `search=${encodeURIComponent(search)}` : null,
      abbreviation ? `abbreviation=${encodeURIComponent(abbreviation)}` : null,
      size ? `size=${encodeURIComponent(size)}` : null,
    ].filter(Boolean)
    const paramFragment = paramList.length ? `?${paramList.join('&')}` : ''
    return this.get<Country[]>(`/countries${paramFragment}`)
  }
}

export default Countries
