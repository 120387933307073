import React, { useCallback } from 'react'

interface ButtonProps {
  checked: boolean
  cssClass?: string
  disabled?: boolean
  label?: string | JSX.Element
  name: string
  inline?: boolean
  selector: string
  value?: string
  doOnChange: (ev: any) => void
}
const RadioButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    checked,
    cssClass,
    disabled,
    doOnChange,
    label,
    name,
    inline,
    selector,
    value,
  } = props

  const id = `radio${selector}`
  const classes = [
    'custom-control',
    'custom-radio',
    cssClass,
    inline ? 'custom-control-inline' : null,
    disabled ? 'disabled' : null,
  ].filter(Boolean)

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      doOnChange(ev)
    },
    [doOnChange]
  )

  return (
    <div className={classes.join(' ')}>
      <input
        className="custom-control-input"
        type="radio"
        name={name}
        id={id}
        onChange={handleChange}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default RadioButton
