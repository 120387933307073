import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useDebouncedState from '../../hooks/useDebouncedState'
import ClusterJobs from '../../services/ClusterJobs/ClusterJobs'
import BootstrapAlert from '../UI/BootstrapAlert/BootstrapAlert'
import { FormattedDate } from '../UI/FormattedDate/FormattedDate'
import { Breadcrumb } from '../UI/Breadcrumb/Breadcrumb'
import BackdropSpinner from '../UI/BackdropSpinner/BackdropSpinner'
import { SortHandle, Sorting } from '../UI/SortHandle/SortHandle'
import { ClusterJob, ClusterJobStatus } from '../../interface/ClusterJob'
import Icon from '../UI/Icon/Icon'
import usePagination from '../../hooks/usePagination'
import { Pagination } from '../UI/Pagination/Pagination'
import useRouting from '../../hooks/useRouting'
import { SelectBox, Option } from '../UI/SelectBox/SelectBox'
import Input from '../UI/Input/Input'
import { TooltipHandle } from '../UI/TooltipHandle/TooltipHandle'
import {
  createFilterParams,
  createURLSearchParams,
  matchesKnownJobStatus,
  statusHasChanged,
  updateStatus,
} from '../../utils/JobUtils'
import { Capability } from '../../services/Auth/UserInfo'
import { UserContext } from '../../contexts/UserContext'

interface StatusIndicatorProps {
  name: string
  status: ClusterJobStatus
}
const colorClassLookup: Record<ClusterJobStatus, string> = {
  CREATED: 'text-warning',
  QUEUED: 'text-warning',
  PROCESSING: 'text-warning',
  ERROR: 'text-danger',
  DONE: 'text-success',
  RESULT_FETCHED: 'text-success',
  RESULT_FINALIZED: 'text-success',
  EXPIRED: 'text-danger',
  UNKNOWN: 'text-danger',
}
const StatusIndicator = (props: StatusIndicatorProps) => {
  const { t } = useTranslation()
  const status = props.status || 'UNKNOWN'
  return (
    <div
      data-semantic-id="statusindicator"
      data-semantic-context={props.name}
      className="d-flex align-items-baseline">
      <TooltipHandle
        data-semantic-id="cardinfo"
        data-semantic-context={props.name}
        className={`btn m-0 p-0 ${colorClassLookup[status] || 'text-warning'}`}
        name={props.name}
        label={<Icon name="info-circle" size="lg" />}>
        {t(`ClusterJobStatusTooltip_${status}`)}
      </TooltipHandle>
      <span className="ml-2">{t(`ClusterJobStatus_${status}`)}</span>
    </div>
  )
}

const OPTIONS: ClusterJobStatus[] = [
  'CREATED',
  'QUEUED',
  'PROCESSING',
  'ERROR',
  'DONE',
  'RESULT_FETCHED',
  'RESULT_FINALIZED',
  'EXPIRED',
  'UNKNOWN',
]

type StatusFilter = ClusterJobStatus

const BASE_ROUTE = '/clusterjobs'

export default function GspClusterJobOverview() {
  const { t } = useTranslation()
  const { can } = useContext(UserContext)
  const { query, replaceState } = useRouting()
  const initialStatus: StatusFilter[] = matchesKnownJobStatus(
    query?.status,
    OPTIONS
  )
    ? [...(query?.status as StatusFilter[])]
    : []

  const [statusFilter, setStatusFilter] =
    useState<StatusFilter[]>(initialStatus)
  const [companyNameFilter, setCompanyNameFilter] = useDebouncedState(
    250,
    query?.company_name ?? ''
  )
  const [cityFilter, setCityFilter] = useDebouncedState(250, query?.city ?? '')
  const [createdByFilter, setCreatedByFilter] = useDebouncedState(
    250,
    query?.created_by ?? ''
  )
  const [assigneeFilter, setAssigneeFilter] = useDebouncedState(
    250,
    query?.assignee ?? ''
  )
  const [sort, setSort] = useState<Sorting>(Sorting.Descending)

  const { error, fetchItems, isLoading, items, page, pagination, resetPage } =
    usePagination<ClusterJob>()

  const handleStatusFilter = useCallback(
    (option: Option) => {
      const updated = updateStatus(statusFilter, option) as StatusFilter[]
      if (statusHasChanged(statusFilter, updated)) {
        resetPage()
      }
      resetPage()
      setStatusFilter(updated)
    },
    [statusFilter, resetPage]
  )
  const handleCompanyFilter = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      setCompanyNameFilter((e.target as any).value)
    },
    [setCompanyNameFilter]
  )
  const handleCityFilter = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      setCityFilter((e.target as any).value)
    },
    [setCityFilter]
  )
  const handleCreatedByFilter = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      setCreatedByFilter((e.target as any).value)
    },
    [setCreatedByFilter]
  )
  const handleAssigneeFilter = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      setAssigneeFilter((e.target as any).value)
    },
    [setAssigneeFilter]
  )
  const handleSelectSort = useCallback((sort: Sorting) => {
    setSort(sort)
  }, [])

  useEffect(() => {
    fetchItems(() =>
      new ClusterJobs().getClusterJobs(page, {
        status: statusFilter,
        company_name: companyNameFilter,
        city: cityFilter,
        assignee: assigneeFilter,
        createdBy: createdByFilter,
        sort: sort === Sorting.Ascending ? 'created_at:ASC' : 'created_at:DESC',
        size: 8,
      })
    )
  }, [
    statusFilter,
    companyNameFilter,
    cityFilter,
    fetchItems,
    page,
    sort,
    assigneeFilter,
    createdByFilter,
  ])

  useEffect(() => {
    const statusParams = createFilterParams('status', statusFilter)
    const companyNameParams = createFilterParams('company_name', [
      companyNameFilter,
    ])
    const cityParams = createFilterParams('city', [cityFilter])
    const createdByParams = createFilterParams('created_by', [createdByFilter])
    const assigneeParams = createFilterParams('assignee', [assigneeFilter])

    const joinedParams = createURLSearchParams([
      statusParams,
      companyNameParams,
      cityParams,
      createdByParams,
      assigneeParams,
    ])

    replaceState(null, '', `${BASE_ROUTE}${joinedParams}`)
  }, [
    assigneeFilter,
    cityFilter,
    companyNameFilter,
    createdByFilter,
    replaceState,
    statusFilter,
  ])

  return (
    <div data-semantic-id="cluster-job-overview">
      <Breadcrumb>
        <li className="breadcrumb-item active" aria-current="page">
          {t('ClusterJobsCardHeader')}
        </li>
      </Breadcrumb>
      <div className="d-flex align-items-center justify-content-between">
        <h1>{t('ClusterJobsCardHeader')}</h1>
      </div>
      <p>{t('ClusterJobsPageDescription')}</p>
      <div className="d-flex align-items-end">
        <div className="form-group mr-3">
          <SelectBox
            id="jobstatus"
            label={t('JobStatus')}
            disabled={isLoading}
            options={OPTIONS.map(value => ({
              label: t(`ClusterJobStatus_${value}`),
              value,
            }))}
            onChange={handleStatusFilter}
            values={statusFilter}
            isCheckbox
            includeNoSelect={false}
            isMultiSelect
          />
        </div>
        <Input
          id="companyname"
          name="companyname"
          cssClass="mr-3"
          type="text"
          label={t('CompanyName')}
          slotRight={<Icon name="search" />}
          onChange={handleCompanyFilter}
          defaultValue={companyNameFilter}
        />
        <Input
          id="city"
          name="city"
          cssClass="mr-3"
          type="text"
          label={t('City')}
          slotRight={<Icon name="search" />}
          onChange={handleCityFilter}
          defaultValue={cityFilter}
        />
        <Input
          id="jobcreatedby"
          name="jobcreatedby"
          cssClass="mr-3"
          type="text"
          label={t('JobCreatedBy')}
          slotRight={<Icon name="search" />}
          onChange={handleCreatedByFilter}
          defaultValue={createdByFilter}
        />
        <Input
          id="jobassignee"
          name="jobassignee"
          cssClass="mr-3"
          type="text"
          label={t('JobAssignee')}
          slotRight={<Icon name="search" />}
          onChange={handleAssigneeFilter}
          defaultValue={assigneeFilter}
        />
        {can(Capability.WriteClusterjobs) && (
          <div className="ml-auto form-group">
            <div className="d-flex align-items-center justify-content-end">
              <Link
                data-semantic-id="new-clustering"
                to="/clusterjobs/new"
                className="btn btn-secondary text-nowrap">
                <Icon name="grid-3x3-gap-fill">{t('CreateNewClustering')}</Icon>
              </Link>
            </div>
          </div>
        )}
      </div>
      {error && <BootstrapAlert type="danger">{error.message}</BootstrapAlert>}
      <div className="row mb-4">
        <div className="col">
          <div
            data-semantic-id="paginated-data"
            className="card card-custom-table">
            <div className="card-body">
              <BackdropSpinner show={isLoading}>
                <div>
                  <table
                    data-semantic-id="jobtable"
                    className="table table-striped table-borderless table-layout-fixed">
                    <colgroup>
                      <col style={{ width: '12rem' }} />
                      <col style={{ width: '9rem' }} />
                      <col className="col-auto" />
                      <col className="col-auto" />
                      <col style={{ width: '9rem' }} />
                      <col className="col-auto" />
                      <col className="col-auto" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">{t('JobStatus')}</th>
                        <th scope="col">{t('JobInternalId')}</th>
                        <th scope="col">{t('CompanyName')}</th>
                        <th scope="col">{t('City')}</th>
                        <th scope="col">
                          <span className="d-flex">
                            <span className="mr-2">{t('JobCreatedAt')}</span>
                            <SortHandle
                              data-semantic-id="clusterjobs-sort-created-at"
                              sort={sort}
                              onSelectSorting={handleSelectSort}
                            />
                          </span>
                        </th>
                        <th scope="col">{t('JobCreatedBy')}</th>
                        <th scope="col">{t('JobAssignee')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items !== null && items.length === 0 && (
                        <tr data-semantic-id="empty-page">
                          <td colSpan={7}>
                            <BootstrapAlert
                              className="m-2"
                              type="info"
                              message={t('NoJobsFound')}
                            />
                          </td>
                        </tr>
                      )}
                      {items?.map((item, i) => (
                        <tr key={i} data-main-row data-id={item.id}>
                          <td className="py-0 align-middle">
                            <StatusIndicator
                              name={`si-${item.id}`}
                              status={item.status}
                            />
                          </td>
                          <th scope="row">
                            <Link to={`/clusterjobs/${item.id}`}>
                              {item.id}
                            </Link>
                          </th>
                          <td>
                            <span className="overflow-ellipsis text-nowrap">
                              {item.company_name === '.*' ? (
                                <Icon name="globe">{item.company_name} </Icon>
                              ) : (
                                item.company_name
                              )}
                            </span>
                          </td>
                          <td>
                            <span className="overflow-ellipsis text-nowrap">
                              {item.city}
                            </span>
                          </td>
                          <td>
                            <FormattedDate date={item.created_at} />
                          </td>
                          <td>
                            <span className="overflow-ellipsis text-nowrap">
                              {item.created_by}
                            </span>
                          </td>
                          <td>
                            <span className="overflow-ellipsis text-nowrap">
                              {item.assignee}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </BackdropSpinner>
            </div>
          </div>
        </div>
      </div>
      <Pagination {...pagination} />
    </div>
  )
}
