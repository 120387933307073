import { Option } from '../components/UI/SelectBox/SelectBox'

export const isSelected = (
  values: string | string[],
  option: Option
): boolean => {
  if (Array.isArray(values)) {
    return values?.find(value => value === option.value) !== undefined ?? false
  } else {
    return values === option.value
  }
}

export const matchesKnownJobStatus = (
  status: string | string[] | undefined,
  allKnownStates: string[]
): boolean => {
  if (Array.isArray(status)) {
    return (
      status
        .map(val => allKnownStates.some(known => known === val))
        .find(know => !know) !== false
    )
  } else {
    return allKnownStates.some(known => known === status)
  }
}

export const updateStatus = (
  previousValues: string[],
  option: Option
): string[] => {
  let update = [...previousValues]
  const alreadySelected = isSelected(previousValues, option)
  if (alreadySelected) {
    update = update.filter(status => status !== option.value)
  } else {
    update.push(option.value)
  }
  return update
}

export const statusHasChanged = (
  previous: string[],
  updated: string[]
): boolean => {
  return JSON.stringify(previous.sort()) !== JSON.stringify(updated.sort())
}

export const createFilterParams = (key: string, values: string[]): string => {
  const paramList = values
    .filter(Boolean)
    .map(value => `${key}=${encodeURIComponent(value)}`)
  return paramList.length ? `${paramList.join('&')}` : ''
}

export const createURLSearchParams = (parameter: string[]): string => {
  const paramList = parameter.filter(Boolean)
  return paramList.length ? `?${paramList.join('&')}` : ''
}
