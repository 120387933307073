import React from 'react'
import { CSSTransition } from 'react-transition-group'
import './UseTransition.css'

interface Props {
  type: 'dropdown'
  name?: string
  timeout: number
  children: JSX.Element | JSX.Element[] | null
  in: boolean
}

const UseTransition: React.FC<Props> = (props: Props) => {
  return (
    <div className={`animation-wrapper ${props.type}`}>
      <CSSTransition
        unmountOnExit
        in={props.in}
        classNames={props.type}
        timeout={{ enter: props.timeout, exit: props.timeout }}>
        <div>{props.children}</div>
      </CSSTransition>
    </div>
  )
}
export default UseTransition
