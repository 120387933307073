import ApiService from '../ApiService'
import { Version } from '../../interface/Version'

class VersionService extends ApiService {
  async getVersion() {
    return this.get<Version>('/version')
  }
}

export default VersionService
