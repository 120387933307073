import React, { ReactNode, useCallback } from 'react'

interface Props {
  checked?: boolean
  children?: ReactNode
  disabled?: boolean
  inline?: boolean
  inputClasses?: string[]
  inputKey: string
  labelClasses?: string[]
  onChange?: (checked: boolean) => void
  'data-semantic-id'?: string
  isSwitch?: boolean
}

const Checkbox: React.FC<Props> = (props: Props) => {
  const {
    checked = false,
    children,
    disabled,
    inline,
    inputClasses,
    inputKey,
    labelClasses,
    onChange,
    isSwitch,
  } = props
  const checkboxType = isSwitch ? 'custom-switch' : 'custom-checkbox'
  const customWrapperClasses = inline ? 'custom-control-inline' : ''
  const customInputClasses = inputClasses?.filter(Boolean).join(' ') || ''
  const customLabelClasses = labelClasses?.filter(Boolean).join(' ') || ''
  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      ev.stopPropagation()
      onChange?.(ev.target.checked)
    },
    [onChange]
  )

  return (
    <div
      className={`d-flex align-item-center custom-control pl-0 ${checkboxType} ${customWrapperClasses}`}>
      <input
        data-semantic-id={props['data-semantic-id']}
        id={inputKey}
        className={`custom-control-input ${customInputClasses}`}
        type="checkbox"
        disabled={disabled}
        onChange={event => {
          handleChange(event)
        }}
        checked={checked}></input>
      <label
        htmlFor={inputKey}
        data-option={children}
        className={`custom-control-label flex-grow-1 ${customLabelClasses}`}>
        {children}
      </label>
    </div>
  )
}

export default Checkbox
