import AddressInline from '../UI/AddressInline/AddressInline'
import Checkbox from '../UI/Checkbox/Checkbox'
import { Modal } from '../UI/Modal/Modal'
import React, { useCallback } from 'react'
import { Address } from '../../interface/Address'
import { useTranslation } from 'react-i18next'

interface Props {
  addressId?: string | number
  title: string
  isOpen: boolean
  toggle: () => void
  leadingCompanyAddresses: Address[]
  mergeIds: string[]
  addressChangeMapping: Record<string, string>
  handleSetAddressChangeMappings: (mergeIds: string[], targetId: string) => void
}

export const MergeAddressesModal = (props: Props) => {
  const { t } = useTranslation()

  const {
    addressId,
    title,
    isOpen,
    toggle,
    leadingCompanyAddresses,
    mergeIds,
    addressChangeMapping,
    handleSetAddressChangeMappings,
  } = props

  const handleIsSelected = useCallback(
    (value: string, allSelected?: boolean) => {
      if (allSelected) {
        const addressesChecked = mergeIds.map(id => {
          return addressChangeMapping[id] === value
        })
        return addressesChecked.every(item => item)
      }
      if (addressId) return addressChangeMapping[addressId] === value
    },
    [addressChangeMapping, addressId, mergeIds]
  )

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      data-semantic-id="merge-addresses-modal"
      title={title}>
      {leadingCompanyAddresses
        .map(address => ({
          label: <AddressInline address={address} />,
          value: address.id.toString(),
        }))
        .map(opt => (
          <div
            data-semantic-id="merge-addresses-modal-choices"
            key={`opt:${opt.value}`}
            className={'dropdown-item px-0'}>
            <Checkbox
              inputKey={opt.value}
              key={`opt:${opt.value}`}
              inputClasses={['position-relative', 'ml-3']}
              labelClasses={['pr-2']}
              checked={handleIsSelected(opt.value, true)}
              onChange={() =>
                handleSetAddressChangeMappings(mergeIds, opt.value)
              }>
              {opt.label}
            </Checkbox>
          </div>
        ))}
      <button
        className={'btn btn-primary ml-1 mt-1 p-1'}
        data-semantic-id="merge-addresses-modal-close"
        onClick={toggle}>
        {`${t('Close')}`}
      </button>
    </Modal>
  )
}
