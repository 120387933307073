import React from 'react'

type Status = string

interface Props {
  id: string | number
  prefix?: string
  status?: Status | null
}

const IdRenderer: React.FC<Props> = (props: Props) => {
  const { id, prefix, status } = props
  if (!id) return null

  const catchId = new RegExp(/:(.*?):/, 'g')
  const execId = catchId.exec(id.toString())
  const displayId = execId ? execId[1] : id

  const items = [`${prefix}${displayId}`, status].filter(Boolean)

  return <>{items.join(', ')}</>
}

IdRenderer.defaultProps = {
  prefix: '',
}

export default IdRenderer
