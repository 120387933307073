import React, { useCallback, useEffect } from 'react'
import config from '../../../config'
import { LayoutProps } from '../Layout/Layout'
import data from '../../../../package.json'
import styles from './Footer.module.scss'
import { Version } from '../../../interface/Version'
import VersionService from '../../../services/Versions/VersionService'
import useApi2 from '../../../hooks/useApi2'

const handleGetVersion = async () => {
  return await new VersionService().getVersion()
}

const Footer: React.FC<LayoutProps> = () => {
  const [version, setVersion] = React.useState<Version | null>()

  const [call, , response] = useApi2(
    '',
    useCallback(() => handleGetVersion(), [])
  )

  useEffect(() => {
    if (!version) call()
  }, [call, version])

  useEffect(() => {
    if (response?.success && response.data) {
      setVersion({ ...response?.data })
    }
  }, [response])

  return (
    <footer className={`mt-auto mb-0 px-5  ${styles.footer}`}>
      <div className={'container-fluid pl-0 py-1'}>
        <div className={'align-items-center'}>
          <div
            data-testid={'footer-company-name'}
            className={'float-left py-4'}>
            {config.APPLICATION_NAME} &copy; 2024 Siemens
          </div>
          <div className={'float-right text-left'}>
            <div className={'py-1'} data-testid={'footer-version'}>
              <div data-testid={'footer-version-frontend'}>
                {`Frontend: ${
                  !data.version || !data.version?.length
                    ? 'unknown'
                    : data.version
                }`}
              </div>
              <div data-testid={'footer-version-coordinator'}>
                {`Coordinator: ${
                  !version?.coordinator || !version.coordinator.length
                    ? 'unknown'
                    : version?.coordinator
                }`}
              </div>
              <div data-testid={'footer-version-matcher'}>
                {`Matcher: ${
                  !version?.matcher || !version.matcher.length
                    ? 'unknown'
                    : version?.matcher
                }`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
