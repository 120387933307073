import React, { useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDropdown } from '../../../hooks/useDropdown'
import Icon from '../Icon/Icon'
import { Capability, UserContext } from '../../../contexts/UserContext'

interface Props {
  className?: string
}

const ConfigMenu: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { can } = useContext(UserContext)
  const [dropdownRef, dropdownOpen, setDropdownOpen] =
    useDropdown<HTMLDivElement>()

  const { className } = props
  const cssClasses = []

  if (className && className.length) {
    cssClasses.push(className)
  }

  const handleToggleDropdown = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      ev.preventDefault()
      setDropdownOpen(before => !before)
    },
    [setDropdownOpen]
  )

  return (
    <div className={cssClasses.join(' ')}>
      {can(Capability.AccessAdminMenu) && (
        <div
          ref={dropdownRef}
          className={`nav-item dropdown ${dropdownOpen ? 'show' : ''}`}>
          <a
            className="nav-link dropdown-toggle text-nowrap"
            href="#"
            id="configNavbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            onClick={handleToggleDropdown}
            aria-expanded={dropdownOpen ? 'true' : 'false'}>
            <Icon name="gear-fill">{t('ConfigurationsMenuTitle')}</Icon>
          </a>
          <div
            className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}
            aria-labelledby="configNavbarDropdown">
            {can(Capability.ReadAppConfig) && (
              <Link className="dropdown-item" to="/appconfig">
                {t('AppConfigTitle')}
              </Link>
            )}
            {can(Capability.ReadMatchingConfig) && (
              <Link className="dropdown-item" to="/matchingconfigs">
                {t('MatchingConfigTitle')}
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default ConfigMenu
