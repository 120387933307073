import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Spinner from '../UI/Spinner/Spinner'
import BootstrapAlert from '../UI/BootstrapAlert/BootstrapAlert'
import {
  CancellationCommand,
  CancellationPolicy,
  RetryCommand,
} from '../../services/ApiService'
import Jobs from '../../services/Jobs/Jobs'
import JobInfo from '../UI/JobInfo/JobInfo'
import useApi from '../../hooks/useApi'
import { Job, JobStatus } from '../../interface/Job'
import GspDedupJob from './GspDedupJob/GspDedupJob'
import useRouting from '../../hooks/useRouting'
import useMountedState from '../../hooks/useMountedState'
import { Breadcrumb } from '../UI/Breadcrumb/Breadcrumb'
import { Capability } from '../../services/Auth/UserInfo'
import { UserContext } from '../../contexts/UserContext'
import { TooltipHandle } from '../UI/TooltipHandle/TooltipHandle'
import Icon from '../UI/Icon/Icon'

const STATES_THAT_HAVE_JOBS: JobStatus[] = [
  'READY',
  'ASSIGNMENT_DONE',
  'ERROR',
  'EXPIRED',
]

async function getJobWithStatusReady(
  jobId: number | string,
  onProgress: (job: Job | undefined) => void,
  cancellationPolicy: CancellationPolicy
) {
  return await new Jobs().getJob(
    jobId,
    response => {
      if (
        response.success &&
        response.data?.status &&
        STATES_THAT_HAVE_JOBS.includes(response.data?.status)
      ) {
        onProgress(response.data)
        return RetryCommand.Accept
      }
      if (
        !response.success &&
        typeof response.status === 'number' &&
        response.status >= 400
      ) {
        return RetryCommand.Reject
      }
      onProgress(response.data)
      if (
        jobId &&
        response.data &&
        response.data.id.toString() !== jobId.toString()
      ) {
        return RetryCommand.Accept
      }

      return RetryCommand.RetryKeepAlive
    },
    cancellationPolicy
  )
}

const assignToJob = async (jobId: number, assignee: string) => {
  const service: Jobs = new Jobs()
  return await service.addAssigneeToJob(jobId, assignee)
}

const cancelJob = async (jobId: number) => {
  const service: Jobs = new Jobs()
  return await service.cancelJob(jobId)
}

interface Props {
  jobId: number | string
}

const GspDedup: React.FC<Props> = (props: Props) => {
  const { t: translate } = useTranslation()
  const { can, userName } = useContext(UserContext)
  const location = useLocation<Job>()
  const restartedJobFromOverview = location.state

  const { jobId } = props
  const [job, setJob] = useState<Job | undefined>()
  const { pushState } = useRouting()
  const isMounted = useMountedState()

  const callGetJobApi = useCallback(
    () =>
      getJobWithStatusReady(
        jobId,
        (job?: Job) => {
          if (!isMounted()) {
            return
          }
          setJob(job)
        },
        () => {
          if (isMounted()) {
            return CancellationCommand.KeepAlive
          }
          return CancellationCommand.Cancel
        }
      ),
    [jobId, isMounted]
  )

  const getJobApi = useApi(translate, 'ErrorOccurred', callGetJobApi)
  const fetchJob = getJobApi.call

  const {
    call: assign,
    error: assignError,
    isLoading: isAssigning,
    response: assignResponse,
  } = useApi<Job, string>(
    translate,
    'ErrorOccurred',
    useCallback((assignee: string) => assignToJob(+jobId, assignee), [jobId])
  )

  const {
    call: stopJob,
    error: stopError,
    response: stopResponse,
  } = useApi<Job, undefined>(
    translate,
    'ErrorOccurred',
    useCallback(() => cancelJob(+jobId), [jobId])
  )

  const handleAssignJob = useCallback(
    async (assignee: string) => {
      await assign(assignee)
    },
    [assign]
  )

  const handleStopJob = useCallback(async () => {
    await stopJob()
  }, [stopJob])

  const handleUpdateJob = useCallback(async (job: Job) => {
    setJob(job)
  }, [])

  useEffect(() => {
    fetchJob()
  }, [fetchJob, jobId])

  useEffect(() => {
    if (restartedJobFromOverview) {
      setJob(restartedJobFromOverview)
    }
  }, [restartedJobFromOverview])

  useEffect(() => {
    if (job && jobId.toString() !== job.id.toString()) {
      pushState(job, '', `/importjobs/${job.id}`)
    }
  }, [job, jobId, pushState])

  useEffect(() => {
    if (assignResponse && assignResponse.data) setJob(assignResponse.data)
    if (stopResponse && stopResponse.data) setJob(stopResponse.data)
  }, [assignResponse, stopResponse])

  const jobIdError = !jobId
    ? { success: false, message: translate('JobIdIsMissing') }
    : null
  const jobError = jobIdError || getJobApi.error
  const error = jobError || assignError || stopError
  const isAssigned = job?.assignee === userName
  const assigned = job?.assignee !== undefined && job.assignee?.length > 0
  const assignable =
    can(Capability.WriteImportjobs) &&
    (!assigned || job?.assignee !== userName) &&
    job?.status !== 'ASSIGNMENT_DONE' &&
    job?.status !== 'EXPIRED'
  const isPermittedAndAssigned = isAssigned && can(Capability.WriteImportjobs)

  return (
    <div data-semantic-id="import-job-container" data-semantic-jobid={jobId}>
      <Breadcrumb>
        <li className="breadcrumb-item">
          <Link to="/importjobs">{translate('ImportJobsCardHeader')}</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {jobId}
        </li>
      </Breadcrumb>
      <h1 className="mb-4">{translate('ImportJobsCardHeader')}</h1>
      {error ? (
        <BootstrapAlert
          type="danger"
          message={[
            jobError ? `${translate('job_state')}: ` : '',
            `${error.message}`,
          ].join('')}
          status={getJobApi?.error?.system}
        />
      ) : null}
      {job && (
        <JobInfo
          job={job}
          jobType={'IMPORT_JOB'}
          assignable={assignable}
          isAssigning={isAssigning}
          onAssign={handleAssignJob}
        />
      )}
      {job &&
        (job.status === 'QUEUED' || job.status === 'PROCESSING') &&
        isPermittedAndAssigned && (
          <div className="mb-3 d-flex align-items-center justify-content-end">
            <button
              data-semantic-id="import-job-stop"
              onClick={handleStopJob}
              className="btn btn-secondary">
              {translate('StopImportJob')}
            </button>
            <TooltipHandle
              data-semantic-id="stopimportjobinfo"
              className="btn btn-gray-custom"
              name={'stopimportjobinfo'}
              label={<Icon name="info-circle" />}
              xShiftTooltip={-40}>
              {translate('StopImportJobTooltip')}
            </TooltipHandle>
          </div>
        )}
      {(!job || !STATES_THAT_HAVE_JOBS.includes(job.status)) &&
      job !== undefined ? (
        <Spinner />
      ) : null}

      {job && STATES_THAT_HAVE_JOBS.includes(job.status) ? (
        <GspDedupJob key={job.id} job={job} updateJob={handleUpdateJob} />
      ) : null}
    </div>
  )
}

export default GspDedup
