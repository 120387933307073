import ApiService from '../ApiService'
import { CompanyMatch } from '../../interface/CompanyMatch'

class AdditionalSearch extends ApiService {
  postAdditionalSearch(
    jobId: number,
    jobCompanyId: string,
    regexSearch: boolean
  ) {
    const params = [`asterisk=${encodeURIComponent(regexSearch)}`].filter(
      Boolean
    )
    const paramFragment = params.length ? `?${params.join('&')}` : ''
    return this.post<CompanyMatch>(
      '/jobs/' +
        jobId +
        '/company_match/' +
        jobCompanyId +
        `/additionalsearch${paramFragment}`
    )
  }
}

export default AdditionalSearch
