import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Spinner from '../../UI/Spinner/Spinner'
import BootstrapAlert from '../../UI/BootstrapAlert/BootstrapAlert'
import useApi from '../../../hooks/useApi'
import ClusterJobs from '../../../services/ClusterJobs/ClusterJobs'
import {
  CancellationCommand,
  CancellationPolicy,
  RetryCommand,
} from '../../../services/ApiService'
import ClusterJobCluster from './ClusterJobCluster/ClusterJobCluster'
import { Cluster } from '../../../interface/Cluster'
import { ClusterJob, ClusterJobStatus } from '../../../interface/ClusterJob'
import useRouting from '../../../hooks/useRouting'
import JobInfo from '../../UI/JobInfo/JobInfo'
import { NoDetailsFound } from '../../UI/NoDetailsFound/NoDetailsFound'
import usePagination from '../../../hooks/usePagination'
import { Pagination } from '../../UI/Pagination/Pagination'
import useMountedState from '../../../hooks/useMountedState'
import Checkbox from '../../UI/Checkbox/Checkbox'
import Icon from '../../UI/Icon/Icon'
import Input from '../../UI/Input/Input'
import useDebouncedState from '../../../hooks/useDebouncedState'
import { TooltipHandle } from '../../UI/TooltipHandle/TooltipHandle'
import { Capability, UserContext } from '../../../contexts/UserContext'
import { isValidRegex } from '../../../utils/StringUtils'

const STATES_THAT_HAVE_CLUSTERS: ClusterJobStatus[] = [
  'DONE',
  'RESULT_FETCHED',
  'ERROR',
  'RESULT_FINALIZED',
  'EXPIRED',
]

const NON_EDITABLE_STATES: ClusterJobStatus[] = [
  'ERROR',
  'RESULT_FINALIZED',
  'EXPIRED',
]

const getClusterJobWithStatusReady = async (
  jobId: number,
  onProgress: (job: ClusterJob | undefined) => void,
  cancellationPolicy: CancellationPolicy
) => {
  return await new ClusterJobs().getClusterJob(
    jobId,
    response => {
      if (
        response.success &&
        response.data?.status &&
        STATES_THAT_HAVE_CLUSTERS.includes(response.data?.status)
      ) {
        onProgress(response.data)
        return RetryCommand.Accept
      }
      if (
        !response.success &&
        typeof response.status === 'number' &&
        response.status >= 400
      ) {
        return RetryCommand.Reject
      }
      onProgress(response.data)
      return RetryCommand.RetryKeepAlive
    },
    cancellationPolicy
  )
}

const finalizeJob = async (
  jobId: number,
  includeUnassignedCompanies: boolean
) => {
  return await new ClusterJobs().finalizeJob(jobId, includeUnassignedCompanies)
}

const fetchClusters = async (jobId: number, page: number, search: string) => {
  const service: ClusterJobs = new ClusterJobs()
  return await service.getClusters(jobId, page, undefined, search)
}

const assigneToJob = async (jobId: number, assignee: string) => {
  const service: ClusterJobs = new ClusterJobs()
  return await service.addAssigneeToClusterJob(jobId, assignee)
}

const cancelJob = async (jobId: number) => {
  const service: ClusterJobs = new ClusterJobs()
  return await service.cancelJob(jobId)
}

export const joinJobParameters = (job: ClusterJob): string => {
  const {
    company_name,
    street,
    city,
    country,
    matching_configuration_id,
    fields_as_regex,
  } = job
  const paramList = [
    company_name ? `company_name=${encodeURIComponent(company_name)}` : null,
    street ? `street=${encodeURIComponent(street)}` : null,
    city ? `city=${encodeURIComponent(city)}` : null,
    country ? `country=${encodeURIComponent(country)}` : null,
    fields_as_regex !== undefined ? `fields_as_regex=${fields_as_regex}` : null,
    matching_configuration_id
      ? `matching_configuration_id=${encodeURIComponent(
          matching_configuration_id
        )}`
      : null,
  ].filter(Boolean)
  return paramList.length ? `?${paramList.join('&')}` : ''
}

interface Props {
  jobId: number
}

const GspClusterJob: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { can, userName } = useContext(UserContext)
  const { pushState, reload, redirectTo } = useRouting()
  const { jobId } = props
  const [submittingUpdate, setSubmittingUpdate] = useState(false)
  const [finalizeError, setFinalizeError] = useState<string | null>(null)
  const [finalizeSystemError, setFinalizeSystemError] = useState<string | null>(
    null
  )
  const [job, setJob] = useState<ClusterJob | null | undefined>(null)
  const [includeUnassignedCompanies, setIncludeUnassignedCompanies] =
    useState(false)
  const [debouncedClustersSearch, setDebouncedClustersSearch] =
    useDebouncedState(2000, '')
  const [forcedClustersSearch, setForcedClustersSearch] = useState('')
  const [clustersSearch, setClustersSearch] = useState('')
  const [clustersSearchError, setClustersSearchError] = useState('')
  const isMounted = useMountedState()

  const callJobApi = useCallback(
    () =>
      getClusterJobWithStatusReady(
        jobId,
        (job?: ClusterJob) => {
          if (!isMounted()) {
            return
          }
          setJob(job)
        },
        () => {
          if (isMounted()) {
            return CancellationCommand.KeepAlive
          }
          return CancellationCommand.Cancel
        }
      ),
    [jobId, isMounted]
  )

  const jobApi = useApi(t, 'CouldNotFetchClusterJob', callJobApi)
  const { call: fetchJob, error: jobError, isLoading: jobIsLoading } = jobApi
  const currentJob = jobApi.response?.data

  const {
    error: clusterError,
    fetchItems,
    isLoading: clustersLoading,
    items: clusters,
    page,
    pagination,
    setItems: setClusters,
    fetchPagination, // in case clusters have been merged but were not originally both on the same page, the pagination data needs to be updated
  } = usePagination<Cluster>()

  const {
    call: assign,
    error: assignError,
    isLoading: isAssigning,
    response: assignResponse,
  } = useApi<ClusterJob, string>(
    t,
    'ErrorOccurred',
    useCallback((assignee: string) => assigneToJob(jobId, assignee), [jobId])
  )

  const {
    call: stopJob,
    error: stopError,
    isLoading: isStopping,
    response: stopResponse,
  } = useApi<ClusterJob, undefined>(
    t,
    'ErrorOccurred',
    useCallback(() => cancelJob(jobId), [jobId])
  )

  const error = jobError || clusterError || assignError || stopError
  const showSpinner = jobIsLoading || clustersLoading || isStopping
  const showError =
    (jobError && !jobIsLoading) ||
    (clusterError && !clustersLoading) ||
    (assignError && !isAssigning) ||
    (stopError && !isStopping)

  useEffect(() => {
    fetchJob()
  }, [fetchJob, jobId])

  useEffect(() => {
    if (!(Boolean(job) && Boolean(jobId) && job?.id === jobId)) {
      return
    }
    fetchItems(() => fetchClusters(jobId, page, clustersSearch))
  }, [fetchItems, job, jobId, page, clustersSearch])

  useEffect(() => {
    if (debouncedClustersSearch) setClustersSearch(debouncedClustersSearch)
    if (forcedClustersSearch) setClustersSearch(forcedClustersSearch)
  }, [debouncedClustersSearch, forcedClustersSearch])

  useEffect(() => {
    if (currentJob) {
      setJob(currentJob)
    }
  }, [assignResponse, currentJob])

  useEffect(() => {
    if (assignResponse && assignResponse.success && assignResponse.data) {
      setJob(assignResponse.data)
    }
    if (stopResponse && stopResponse.success && stopResponse.data) {
      setJob(stopResponse.data)
    }
  }, [assignResponse, stopResponse])

  const handleFinalizeJob = useCallback(async () => {
    setSubmittingUpdate(true)
    const response = await finalizeJob(jobId, includeUnassignedCompanies)
    if (response.success) {
      if (response.data?.import_job) {
        pushState(null, '', `/importjobs/${response.data.import_job.id}`)
      } else {
        reload()
      }
    } else {
      const serverMsg = response.message ?? null
      setFinalizeError(serverMsg || t('FinalizeClusterJobFailed'))
      setFinalizeSystemError(response.system ?? null)
      setSubmittingUpdate(false)
    }
  }, [includeUnassignedCompanies, jobId, pushState, reload, t])

  const handleCompanyUpdate = useCallback(
    (cluster: Cluster, companyId: number): Cluster | null => {
      if (+cluster.leading_company.id === companyId) return null
      const updatedCluster: Cluster = { ...cluster }
      updatedCluster.companies = updatedCluster.companies?.filter(
        company => +company.id !== companyId
      )
      updatedCluster.unassigned_companies =
        updatedCluster.unassigned_companies?.filter(
          company => +company.id !== companyId
        )

      // if the leading company is not removed, the cluster is not empty
      return updatedCluster
    },
    []
  )

  const handleLeadingCompanyAddressUpdate = useCallback(
    (cluster: Cluster, addressId: number): Cluster => {
      const updatedCluster: Cluster = { ...cluster }

      const additionalAddresses =
        updatedCluster.leading_company?.additional_addresses

      if (additionalAddresses) {
        const matchIndex = additionalAddresses.findIndex(
          address => address.id.toString() === addressId.toString()
        )

        if (matchIndex >= 0) {
          additionalAddresses.splice(matchIndex, 1)
          updatedCluster.leading_company = {
            ...updatedCluster.leading_company,
            additional_addresses: additionalAddresses,
          }
        }
      }

      return updatedCluster
    },
    []
  )

  const handleUpdateCluster = useCallback(
    (
      cluster: Cluster,
      companyId?: number,
      addressId?: number,
      merge?: boolean
    ) => {
      let leadingCompanyMatch = false

      setClusters(prev => {
        if (!prev) {
          return prev
        }
        const updatedClusters: Cluster[] = []
        prev.forEach(item => {
          if (item.id === cluster.id) {
            updatedClusters.push(cluster)
            return
          }
          if (companyId && merge) {
            leadingCompanyMatch =
              item.leading_company.id == cluster.leading_company.id
            if (leadingCompanyMatch) {
              return
            }
          }
          if (companyId && !merge) {
            const updated = handleCompanyUpdate(item, companyId)
            if (updated) updatedClusters.push(updated)
            return
          }
          if (addressId) {
            const updated = handleLeadingCompanyAddressUpdate(item, addressId)
            if (updated) updatedClusters.push(updated)
            return
          }
          updatedClusters.push(item)
        })
        return updatedClusters
      })
      if (companyId && merge && !leadingCompanyMatch) {
        fetchPagination(() => fetchClusters(jobId, page, clustersSearch))
      }
    },
    [
      setClusters,
      handleCompanyUpdate,
      handleLeadingCompanyAddressUpdate,
      fetchPagination,
      jobId,
      page,
      clustersSearch,
    ]
  )

  const setValueAndError = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLInputElement>
        | React.SyntheticEvent<HTMLInputElement>,
      setValue: (input: string) => void
    ) => {
      if (
        isValidRegex((e.target as any).value) ||
        (e.target as any).value.length === 0
      ) {
        setValue((e.target as any).value)
        setClustersSearchError('')
      } else {
        setClustersSearchError(t('SearchRegexError'))
      }
    },
    [t]
  )

  const handleEnter = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setValueAndError(e, setForcedClustersSearch)
        e.preventDefault()
        e.stopPropagation()
      }
    },
    [setValueAndError]
  )

  const handleClustersSearchInput = useCallback(
    async (e: React.SyntheticEvent<HTMLInputElement>) => {
      setValueAndError(e, setDebouncedClustersSearch)
      e.preventDefault()
      e.stopPropagation()
    },
    [setDebouncedClustersSearch, setValueAndError]
  )

  const handleRestartClusterJob = async (job: ClusterJob) => {
    const params = joinJobParameters(job)
    redirectTo('/clusterjobs/new' + params)
  }

  const handleAssignJob = useCallback(
    async (assignee: string) => {
      await assign(assignee)
    },
    [assign]
  )

  const handleStopJob = useCallback(async () => {
    await stopJob()
  }, [stopJob])

  const editableJob =
    (job && !NON_EDITABLE_STATES.includes(job.status)) || false
  const canWriteClusterjobs = can(Capability.WriteClusterjobs)
  const canWriteImportjobs = can(Capability.WriteImportjobs)
  const jobIsPermittedAndAssigned =
    canWriteClusterjobs && job?.assignee === userName
  const assigned = job?.assignee !== undefined && job.assignee?.length > 0
  const assignable =
    canWriteClusterjobs &&
    (!assigned || job?.assignee !== userName) &&
    job?.status !== 'RESULT_FINALIZED' &&
    job?.status !== 'EXPIRED'

  if (showError) {
    return (
      <BootstrapAlert
        type="danger"
        message={error?.message}
        status={error?.system}
      />
    )
  }

  return (
    <div data-semantic-id="cluster-job">
      {job && (
        <JobInfo
          job={job}
          jobType={'CLUSTER_JOB'}
          assignable={assignable}
          isAssigning={isAssigning}
          onAssign={handleAssignJob}
        />
      )}

      <div className="mb-3 d-flex align-items-center justify-content-end">
        {job && !showSpinner && clusters && jobIsPermittedAndAssigned && (
          <>
            <button
              data-semantic-id="cluster-job-restart"
              onClick={() => handleRestartClusterJob(job)}
              className="btn btn-secondary">
              <Icon name="bootstrap-reboot">{t('RestartClusterJob')}</Icon>
            </button>
            <TooltipHandle
              data-semantic-id="restartclusterjobinfo"
              className="btn btn-gray-custom"
              name={'restartclusterjobinfo'}
              label={<Icon name="info-circle" />}
              xShiftTooltip={-40}>
              {t('RestartClusterJobTooltip')}
            </TooltipHandle>
          </>
        )}
        {job &&
          (job.status === 'QUEUED' || job.status === 'PROCESSING') &&
          jobIsPermittedAndAssigned && (
            <>
              <button
                data-semantic-id="cluster-job-stop"
                onClick={handleStopJob}
                className="btn btn-secondary ml-1">
                {t('StopClusterJob')}
              </button>
              <TooltipHandle
                data-semantic-id="stopclusterjobinfo"
                className="btn btn-gray-custom"
                name={'stopclusterjobinfo'}
                label={<Icon name="info-circle" />}
                xShiftTooltip={-40}>
                {t('StopClusterJobTooltip')}
              </TooltipHandle>
            </>
          )}
      </div>

      {/*
      ToDo: implementation of deletion of cluster jobs and authorization check
      service:si4ce:clusterjobs:delete
      */}

      {job && (
        <Input
          data-semantic-id={'cluster-job-search'}
          id="clustersSearch"
          name="clustersSearch"
          disabled={showSpinner}
          type="text"
          label={t('ClusterJobClustersSearch')}
          slotRight={
            clustersSearchError.length === 0 ? <Icon name="search" /> : ''
          }
          onChange={handleClustersSearchInput}
          onKeyDown={handleEnter}
          defaultValue={clustersSearch}
          error={clustersSearchError}
        />
      )}
      {showSpinner && <Spinner />}
      {job && !showSpinner && clusters?.length === 0 && (
        <div data-semantic-id="nodetailsfound" className="text-center">
          <div className="mt-4">
            <NoDetailsFound>{t('NoClusterDetailsFound')}</NoDetailsFound>
          </div>
          <div className="mt-4">
            <Link to="/clusterjobs" className="btn btn-secondary">
              {t('BackToClusterjobs')}
            </Link>
          </div>
        </div>
      )}
      {job && !showSpinner && clusters !== null && clusters.length > 0 && (
        <>
          <div className="row mb-4">
            <div className="col">
              <div
                data-semantic-id="paginated-data"
                className="card card-custom-table">
                <div className="card-body">
                  <div>
                    <table
                      data-semantic-id="jobtable"
                      className="table table-striped-custom table-borderless table-layout-fixed">
                      <colgroup>
                        <col style={{ width: '3rem' }} />
                        <col className="col-auto" />
                        <col style={{ width: '10rem' }} />
                        <col style={{ width: '3rem' }} />
                        <col className="col-auto" />
                        <col style={{ width: '8rem' }} />
                        <col style={{ width: '2rem' }} />
                        <col style={{ width: '16rem' }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">{t('Company')}</th>
                          <th scope="col">{t('CompanyId')}</th>
                          <th scope="col"></th>
                          <th scope="col">{t('Location')}</th>
                          <th scope="col">{t('LocationId')}</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {clusters?.map(cluster => (
                          <ClusterJobCluster
                            key={cluster.id}
                            jobId={jobId}
                            editableStatus={editableJob}
                            jobIsPermittedAndAssigned={
                              jobIsPermittedAndAssigned
                            }
                            cluster={cluster}
                            onUpdateCluster={handleUpdateCluster}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination {...pagination} />
        </>
      )}
      {job &&
        !showSpinner &&
        editableJob &&
        clusters !== null &&
        clusters.length > 0 &&
        jobIsPermittedAndAssigned && (
          <div
            data-semantic-id="finalize-job-options"
            className="d-flex align-items-center justify-content-center">
            {canWriteImportjobs && (
              <Checkbox
                data-semantic-id="finalize-job-include-companies"
                data-semantic-context="clusterjobs"
                labelClasses={['mr-4']}
                inputKey={'finalize-job-include-companies'}
                isSwitch
                checked={includeUnassignedCompanies}
                onChange={() =>
                  setIncludeUnassignedCompanies(!includeUnassignedCompanies)
                }
                inline={false}>
                {t('AddUnassignedCompaniesToJob')}
              </Checkbox>
            )}
            <button
              data-semantic-id="finalize-job"
              data-semantic-context="clusterjobs"
              className="btn btn-secondary flex-fill"
              disabled={submittingUpdate}
              onClick={handleFinalizeJob}>
              {t('finalize_job')}
            </button>
          </div>
        )}
      {job && !showSpinner && finalizeError && (
        <BootstrapAlert
          className="mt-4"
          type="danger"
          message={finalizeError}
          status={finalizeSystemError}
        />
      )}
    </div>
  )
}

export default GspClusterJob
